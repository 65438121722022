import React, {useEffect, useState} from 'react'
import {Grid} from "semantic-ui-react";
import {AuditorProfile} from "../auditor/profile/DataModal";
import DefaultTextBox from "../../framework/components/TextBoxes/DefaultTextBox";
import EmailInputBox from "../../framework/components/TextBoxes/EmailInputBox";
import TelephoneInputBox from "../../framework/components/TextBoxes/TelephoneInputBox";
import MultilineTextBox from "../../framework/components/TextBoxes/MultilineTextBox";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import {SendMessageState} from "../../framework/types/SendMessageState";
import {SendEnquiry} from "./SearchController";
import SuccessToast from "../../framework/components/Toast/SuccessToast";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";

interface Props{
    Auditor?:AuditorProfile
}
const SendMessageToAuditor = ({Auditor}:Props) => {
    const [state, setState] = useState<SendMessageState>()
    const [errorState, setErrorState] = useState<SendMessageState>()
    const [disablebtn, setDisableBtn] = useState(false)

    useEffect(()=>{
        setState({...state,auditorId:Auditor?.personalDetails?.txtAuditorId})
    },[Auditor])
    const validator = () =>{
        let result = true
        if(state === undefined){
            setErrorState({...errorState,fullName:"Please enter full name !", email:"Please enter email !", message:"Please enter message !"})
            return false;
        }
        if(state?.fullName === undefined || state.fullName.length < 2){
            setErrorState({...errorState,fullName:"Please enter valid full name !"})
            result =  false;
        }
        let regx = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
        let pattern = new RegExp(regx)

        if(state?.email === undefined || !pattern.test(state.email)){
            setErrorState({...errorState,email:"Please enter valid email !"})
            result =  false;
        }

        if(state?.message === undefined || state.message.length < 10){
            setErrorState({...errorState,message:"Please enter valid message !"})
            result =  false;
        }

        regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
        pattern = new RegExp(regx)
        if(state?.phone  != undefined && pattern.test(state.phone) ){
            setErrorState({...errorState,phone:"Please enter valid phone !"})
            result =  false;
        }

        return result
    }
    const SendMessage = () => {
        setDisableBtn(true)
        if(!validator()){
            setDisableBtn(false)
            return

        }
        if(state){
            const sendMsg = SendEnquiry(state)
            Promise.resolve(sendMsg).then((value)=>{
                if(value){
                    SuccessToast('"The message has been successfully sent to the auditor !')
                    setDisableBtn(false)
                    setState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                    setErrorState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                }
            })
        }

    }
    return(
        <React.Fragment>
            <Grid className={'send-message-to-auditor-border'} >
                <Grid.Row columns={2} >
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <Grid centered style={{paddingLeft:'5vw'}}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3 style={{display: 'inline-flex',}} className={'color-foreground'}>Auditor contact details</h3>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >Name : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.personalDetails?.txtTitle}. ${Auditor?.personalDetails?.txtFirstName} ${Auditor?.personalDetails?.txtMiddleName} ${Auditor?.personalDetails?.txtLastName}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >Company/Organization : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.businessDetails?.txtCompanyName}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >Position : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.businessDetails?.txtDesignation}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >Suburb : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.businessDetails?.txtSuburb}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >State : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.businessDetails?.txtState}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} centered>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} >Postcode : </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className={'send-message-auditor-details'} ><b>{` ${Auditor?.businessDetails?.txtPostcode}`}</b></span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <h3 style={{display: 'inline-flex',}} className={'color-foreground'}>Send message to auditor</h3>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <DefaultTextBox
                                        id={'txtName'}
                                        fluid
                                        placeholder={'Full Name'}
                                        value={state?.fullName}
                                        onChange={(e: any) => {
                                            setState({...state, fullName: e.target.value});
                                        }}
                                        onerror={errorState?.fullName}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <DefaultTextBox
                                        id={'txtCompany'}
                                        fluid
                                        placeholder={'Company (optional)'}
                                        value={state?.company}
                                        onChange={(e: any) => {
                                            setState({...state, company: e.target.value});
                                        }}
                                        onerror={errorState?.company}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <DefaultTextBox
                                        id={'txtPosition'}
                                        fluid
                                        placeholder={'Position (optional)'}
                                        value={state?.position}
                                        onChange={(e: any) => {
                                            setState({...state, position: e.target.value});
                                        }}
                                        onerror={errorState?.position}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <EmailInputBox
                                        id={'txtEmailAddress'}
                                        fluid
                                        placeholder={'Email Address'}
                                        value={state?.email}
                                        onChange={(e: any) => {
                                            setState({...state, email: e.target.value});
                                        }}
                                        onerror={errorState?.email}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <DefaultTextBox
                                        id={'txtPhone'}
                                        fluid
                                        placeholder={'Phone Number (optional)'}
                                        value={state?.phone}
                                        onChange={(e: any) => {
                                            setState({...state, phone: e.target.value});
                                        }}
                                        onerror={errorState?.phone}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column>
                                    <MultilineTextBox
                                        id={'txtMsg'}
                                        fluid
                                        placeholder={'Message'}
                                        wordCount={false}
                                        value={state?.message}
                                        onChange={(e: any) => {
                                            setState({...state, message: e.target.value});
                                        }}
                                        onerror={errorState?.message}
                                        disabled={disablebtn}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} centered>
                                <Grid.Column className={'send-message-button button-flex' }>
                                    <OutLineButton id={'btnSend'} onClick={SendMessage} color='red' className={"background-transaparent-red"} text={disablebtn ? 'Please wait ...': 'Send Message'} disabled={disablebtn}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}

export default SendMessageToAuditor;