/*
 *################################################################################
 *Module Name                   	: TextBoxes.tsx                 			 #
 *Purpose of the Module         	: To Show TextBoxes element         		 #
 *Description of the Module    	    : This element  act like TextBoxes     		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/03/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React, {useEffect, useState} from 'react'
import { Input,TextArea,Form } from 'semantic-ui-react'

//Import TextBoxProps
import TextBoxProps from "../../types/TextBoxProps";


/**
 * id = Is the identification of the element should be string   eg:- id={'txtSearch'}
 *
 * type = Is the type of the textbox values eg:- text, number, phone, email, date
 *
 * placeholder = If you need to show  text of the textbox should be string    eg:- placeholder={'Search ...'}
 *
 */
const TextBoxes =({id,type,placeholder,onChange,onPaste,onClick,onLostFocus,onKeydown,value,multiLine,wordCount,fluid,style, className,disabled}:TextBoxProps)=>{
    const [words,setWords] = useState(0)

    //eslint-disable-next-line
    const textAreaChange = (e:any)=>{
        onChange ? onChange(e) : null;
        if(wordCount){
            if(e.target.value.length > 0){
                setWords(e.target.value.length)
                //setWords(e.target.value.trim().split(' ').length)
            }else{
                setWords(0)
            }

        }
    }


    useEffect(()=>{
        if(wordCount && value){
            if(value.length > 0){
                setWords(value.length)
                //setWords(e.target.value.trim().split(' ').length)
            }else{
                setWords(0)
            }

        }
    },[value])

    if(multiLine){
        return(
            <Form>
                <TextArea id={id} type={type} style={style} className={className} disabled={disabled} action placeholder={placeholder} value={value} onChange={textAreaChange} onClick={onClick} onBlur={onLostFocus} onKeyDown={onKeydown } />
                {wordCount ? (<div style={{textAlign:"right", width:"100%"}}>{words}</div>) : null}
            </Form>
        );
    }else{
        return(
            <Form>
                <Input id={id} type={type} fluid={fluid} style={style} className={className} disabled={disabled} action placeholder={placeholder} value={value} onChange={onChange} onClick={onClick} onBlur={onLostFocus} onKeyPress={onKeydown } onPaste={onPaste}/>
            </Form>
        );
    }

}

export default TextBoxes