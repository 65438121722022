import React from "react";
import { createMedia } from "@artsy/fresnel";
import {Container, Segment, Grid, Image, Menu} from "semantic-ui-react";
import logo from '../../assets/images/logos/logo.png'
import {useNavigate} from "react-router-dom";

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

interface Props{
    //eslint-disable-next-line
    clickLogin:any;
}
// eslint-disable-line
const config = window.config;

//eslint-disable-next-line
const FootBarMobile=(props:Props)=>{
    return(
        <Segment vertical className='footer' style={{zIndex:'-1000'}}>
            <Container textAlign='center' >
                <Grid>
                    <Grid.Row columns={1} className={'footer-first-bg-mobile'}>
                        <Grid.Column  verticalAlign='middle' center="true" style={{display:'flex', alignItems:'center'}} >
                            <Image size="small" src={logo} alt={'logo'} />
                        </Grid.Column>
                        <Grid.Column style={{display:'flex', justifyContent:'center'}}>
                            <Menu vertical inverted color='blue' borderless  >
                                <Menu.Item as='a' className='footer-links' href='/search'>Search</Menu.Item>
                                <Menu.Item as='a' className='footer-links' href='/aboutus'>About Us</Menu.Item>
                                <Menu.Item as='a' className='footer-links' href='/faqs'>FAQs</Menu.Item>
                                <Menu.Item as='a' className='footer-links' href='/contactus'>Contact Us</Menu.Item>
                                <Menu.Item as='a' className='footer-links' href={config.AdminUrl}>Administrator</Menu.Item>
                                <Menu.Item as='a' className='footer-links' href={config.AdminUrl}>Assessor</Menu.Item>
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} className={'footer-second-bg-mobile'}>
                        <Grid.Column>
                            <span className='copy-right-note'>© {(new Date()).getFullYear()} Transport for NSW | <a className='link-font' href='/sitemap'  rel="noreferrer" target={'_blank'}> Site Map </a> | <a className='link-font' href='https://www.transport.nsw.gov.au/privacy-and-cookies' target={'_blank'} rel="noreferrer">Privacy</a> | <a className='link-font' href='/terms' target={'_blank'} rel="noreferrer">Disclaimer</a></span>
                        </Grid.Column>
                        <Grid.Column>
                            <p className='copy-right-note'>Web Developers and Web Designers - <a href={'https://itworx.com.au/'} style={{color:'white'}} rel="noreferrer" target={'_blank'}>www.itworx.com.au</a></p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
}

//eslint-disable-next-line
const FootBarDesktop=(props:Props)=>{
    return(
        <Segment vertical className='footer'>
            <Container textAlign='center' fluid>
                <Grid>
                    <Grid.Row columns={7} className={'footer-first-bg'}>
                        <Grid.Column width={1} />
                        <Grid.Column width={1} />
                        <Grid.Column width={1} />
                        <Grid.Column  width={2} verticalAlign='middle' center="true">
                            <Image src={logo} alt={'logo'} />
                        </Grid.Column>
                        <Grid.Column width={1} />
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href='/search'>Search</Menu.Item>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href='/aboutus'>About Us</Menu.Item>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href={config.AdminUrl}>Administrator</Menu.Item>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href='/faqs'>FAQs</Menu.Item>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href='/contactus'>Contact Us</Menu.Item>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Menu.Item as='a' className='footer-links' href={config.AdminUrl}>Assessor</Menu.Item>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {' '}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {' '}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} className={'footer-second-bg'}>
                        <Grid.Column width={8}>
                            <span className='copy-right-note'>© {(new Date()).getFullYear()} Transport for NSW | <a className='link-font' href='/sitemap'  rel="noreferrer" target={'_blank'}> Site Map </a> | <a className='link-font' href='https://www.transport.nsw.gov.au/privacy-and-cookies' target={'_blank'} rel="noreferrer">Privacy</a> | <a className='link-font' href='/terms' target={'_blank'} rel="noreferrer">Disclaimer</a></span>
                        </Grid.Column>
                        <Grid.Column>
                            <p className='copy-right-note'>Web Developers and Web Designers - <a href={'https://itworx.com.au/'} style={{color:'white'}} rel="noreferrer" target={'_blank'}>www.itworx.com.au</a></p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
}

const NavBar =()=>{
    const navigate = useNavigate();

    const clickLogin =()=>{
        navigate('/registration');
    }
    return (
        <div style={{zIndex:'-100'}}>
            <Media at="mobile">
                <FootBarMobile
                    clickLogin={clickLogin}
                />
            </Media>

            <Media greaterThan="mobile">
                <FootBarDesktop
                    clickLogin={clickLogin}
                />
            </Media>
        </div>
    );
}


const Footer=()=>{
    return(
        <>
            <style>{mediaStyles}</style>

            <MediaContextProvider>
                <NavBar/>
            </MediaContextProvider>
        </>
    )
}
export default Footer