import React, {useEffect, useState} from 'react';
import {Grid} from "semantic-ui-react";
import NameWithMultiline from "../NameWithText/NameWithMuliLine";
import Position from "../../types/Position";
import NameWithNumber from "../NameWithText/NameWithNumber";
import RelevantExpType from "./RelevantExpType";
import MultilineTextBox from "../../components/TextBoxes/MultilineTextBox";
import NumberTextBox from "../../components/TextBoxes/NumberTextBox";

const RelevantExpBox = (props:RelevantExpType) => {
    const [errorSummary, SetErrorSummary] = useState(props.onError)
    useEffect(()=>{
        if(props.valueYrs != undefined && parseInt(props.valueYrs) > 0 && (props.value == null || props.value.length == 0)){
            SetErrorSummary(props.onError)
        }else{
            SetErrorSummary('')
        }

    },[props.value,props.valueYrs])

    useEffect(()=>{
        SetErrorSummary(props.onError)
    },[props.onError])

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(props.value != undefined && props.value.length > 0){
                element.style.borderColor = '#969696'
            }else{
                //element.style.borderColor = '#e0e0e0'
                element.style.borderColor = '#969696'
            }
        }
    }, [props.value])

    useEffect(() => {
        const element = document.getElementById(`${props.id}_years`) as HTMLInputElement;
        if(element){
            if(props.valueYrs != undefined && props.valueYrs.length > 0){
                element.style.borderColor = '#969696'
            }else{
                //element.style.borderColor = '#e0e0e0'
                element.style.borderColor = '#969696'
            }
        }
    }, [props.valueYrs])
    return (
        <Grid>
            <Grid.Row columns={1} verticalAlign={'middle'} style={{marginBottom:'15px'}}>
                <Grid.Column width={16} textAlign={"left"} tablet={16} computer={16} widescreen={16} mobile={16} >
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column width={9} mobile={16} widescreen={9} computer={7} tablet={8} >
                                <span style={{display:'inline-flex'}}>{props.label + ' Experience'}</span>
                            </Grid.Column>
                            <Grid.Column  textAlign={"left"} width={4} mobile={8} widescreen={4} computer={6} tablet={4}>
                                <span style={{display:'inline-flex'}}>{'Number of Years ?'}</span>
                            </Grid.Column>
                            <Grid.Column width={3} tablet={3} computer={3} mobile={8} widescreen={3}>
                                <NumberTextBox
                                    id={`${props.id}_years`}
                                    disabled={props.disabled}
                                    value={props.valueYrs}
                                    onChange={props.onChangeYrs}
                                    onerror={props.onErrorYrs}
                                    onLostFocus={props.onLostFocusYrs}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} verticalAlign={'middle'}>
                <Grid.Column width={16} tablet={16} mobile={16} widescreen={16} computer={16}>
                    <MultilineTextBox
                        id={props.id}
                        disabled={props.disabled}
                        value={props.value}
                        onChange={props.onChange}
                        onerror={errorSummary}
                        onLostFocus={props.onLostFocus}
                        wordCount={true}
                        className={'other_exp'}
                        placeholder={`${props.label} Summary`}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default RelevantExpBox;


/*
* <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <NameWithMultiline id={props.id}
                                       labelText={props.label}
                                       type={'text'}
                                       wordCount={false}
                                       labelPosition={Position.Left}
                                       required={false}
                                       value={props.value}
                                       onError={props.onError}
                                       onChange={props.onChange}
                                       onLostFocus={props.onLostFocus}
                                       disabled={props.disabled}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column mobile={16} width={8} widescreen={8}>
                    {''}
                </Grid.Column>
                <Grid.Column mobile={16} width={8} widescreen={8}>
                    <NameWithNumber id={`${props.id}_years`}
                                    labelText={'Number of Years ?'}
                                    type={'text'}
                                    wordCount={true}
                                    fluid={false}
                                    labelPosition={Position.Left}
                                    required={false}
                                    value={props.valueYrs}
                                    onError={props.onErrorYrs}
                                    onChange={props.onChangeYrs}
                                    onLostFocus={props.onLostFocusYrs}
                                    disabled={props.disabled}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>*/