import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import moment from "moment";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd/es/pagination";
import tags from "../../../../models/common/tag";
import { deleteNotificationFiles, fetchAllFiles, uploadNotificationFiles } from "./NotificationController";
import { CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";
import FileUpload from "../../../../framework/components/FileUpload";
import { anyFileValidation, resumeValidation, sizeValidation } from "../../../../framework/validators/fileValidator";
import { FileUploadValidator, calculateStorage } from "../../../../framework/common/SupportMobules";
import FileProps from "../../../../framework/types/FileProps";

const CoursesContainer = () =>{
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
    const sublink = process.env.REACT_APP_API_SUBLINK;
    const version = process.env.REACT_APP_API_VERSION;

    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [file, setFile] = useState<FileProps>({pictureAsFile: {} as File, picturePreview: ''})
    const [uploadFile, setUploadFile] = React.useState<any>();
    const [text, SetText] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "File No"
        },
        {
            text: "File Name"
        },
        {
            text: ""
        }
        ,
        {
            text: ""
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        loadFile()
    },[])


    const SetValues = (data:string[]) => {
        const TableRows: object[] = [];
        let i = 1;
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: i,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Delete",
                    icon: "delete",
                    onclick:() => {
                        DeleteFile(r)
                    }
                },
                {
                    tag: "icon-fa",
                    text: "View Course",
                    icon: "view",
                    onclick:() => {
                        window.open(
                            `${baseUrl2 ? baseUrl2 : baseUrl}/Documents/${r}`,
                            '_blank' // <- This is what makes it open in a new window.
                          );
                    }
                }])
                i++;
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        
        setCurrent(page);
    };

    const DeleteFile = (fileName:string) => {
        const result = deleteNotificationFiles(fileName)
        Promise.resolve(result).then((value) => {
            if (value.length > 0) {
                loadFile();
            }
        })
    }

    const fileUpliadingOnTime = (e:any) => {
        SetText('Please wait ...')
        const uplaodResult = uploadNotificationFiles(file.pictureAsFile.name, e)
        Promise.resolve(uplaodResult).then((value) => {
            if (value.length > 0) {
                loadFile();
                setFile({pictureAsFile: {} as File, picturePreview: ''})
                SetText('')
            }
        })
    }

    const loadFile = () => {
        const fetchFiles = fetchAllFiles()
        Promise.resolve(fetchFiles).then((value) => {
            setTotalRecords(value.length)
            if(value){
                SetValues(value)
            }
        });
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Notification Centre"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>                       
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column >
                                    <b>Upload File :</b> {text}
                                </Grid.Column>
                                <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>
                                    <FileUpload
                                        color={'blue'}
                                        onChange={(e: any) => {
                                            if (anyFileValidation(e) && sizeValidation(FileUploadValidator(e).pictureAsFile.size,30)) {
                                                setFile(FileUploadValidator(e));
                                                setUploadFile(e.target)
                                                fileUpliadingOnTime(e.target)
                                            }
                                        }}
                                        disabled={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {file.pictureAsFile.size > 0 ? (
                            <Grid.Row columns={2}>
                                <Grid.Column>File name - {file.pictureAsFile.name} </Grid.Column>
                                <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>File size
                                    - {calculateStorage(file.pictureAsFile.size)} </Grid.Column>
                            </Grid.Row>
                        ) : null}
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <RSATable
                            head={rsaCourseHead}
                            body={rsaCourseDet}
                            current={current}
                            pageCount={totalRecords/totalRecords}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default CoursesContainer
