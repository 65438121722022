
import SubMenu from "../framework/components/SubMenu";

const publicMenu = [
    {as: "a", content: "Search", key: "search" , href: "/search", },
    {as: "a", content: "Register", key: "register", href: "/registration"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"},
];


//admin menu/sub menu declaration
const adminSearchMenu = [
    {as: "a", content: "Auditors", key: "Auditors", href: "/search"},
    {as: "a", content: "Audits", key: "Audits", href: "/audits"}
];

const adminMISMenu = [
    {as: "a", content: "Number of Auditors/Audits", key: "NoOfAuditors", href: "/mis/transactionreport"},
    {as: "a", content: "Auditor Profile Log", key: "AuditsProfileLog", href: "/mis/profilelog"},
    {as: "a", content: "Auditor Contact Log", key: "AuditsContactLog", href: "/mis/contactlog"}
];

const superAdminMISMenu = [
    {as: "a", content: "Number of Auditors/Audits", key: "NoOfAuditors", href: "/mis/transactionreport"},
    {as: "a", content: "Auditor Profile Log", key: "AuditsProfileLog", href: "/mis/profilelog"},
    {as: "a", content: "Auditor Contact Log", key: "AuditsContactLog", href: "/mis/contactlog"},
    {as: "a", content: "Export Auditors", key: "ExportAuditors", href: "/mis/exportauditors"},
    {as: "a", content: "Process Report", key: "ProcessReport", href: "/mis/processreport"},
    {as: "a", content: "Login Report", key: "LoginReport", href: "/mis/loginactivityreport"},
    {as: "a", content: "Tally Report", key: "TallyReport", href: "/mis/tallyreport"},
    {as: "a", content: "Region Report", key: "RegionReport", href: "/mis/regionreport"},
    {as: "a", content: "Visits on Search Page", key: "VisitsonSearchPage", href: "/mis/visitonsearch"},
    {as: "a", content: "Auditor Message Count", key: "AuditorMessageCount", href: "/mis/messagecount"},
    {as: "a", content: "Accreditation Reports", key: "AccreditationReports", href: "/mis/accreditationreports"},
    {as: "a", content: "Activity Log", key: "ActivityLog", href: "/mis/activitylog"},
    {as: "a", content: "Assessment Stats", key: "AssessmentStats", href: "/mis/assesmentstatus"},
    {as: "a", content: "Assessor Activity Report", key: "AssessorActivityReport", href: "/mis/assessoractivity"},
    // {as: "a", content: "Audits Report", key: "AuditsReport", href: "#"},
];

const assessorChairMISMenu = [
    {as: "a", content: "Assessor Activity Report", key: "AssessorActivityReport", href: "#"},
];

const adminMISCMenu = [
    {as: "a", content: "Courses", key: "Courses", href: "/misc/coursemanagement"},
    {as: "a", content: "Change Login email", key: "ChangeLoginEmail", href: "#"},
    {as: "a", content: "Audit Stages", key: "AuditStages", href: "#"}
];

const superAdminMISCMenu = [
    {as: "a", content: "Courses", key: "Courses", href: "/misc/coursemanagement"},
    {as: "a", content: "Notification", key: "Notification", href: "/misc/notifications"},
    {as: "a", content: "Audit Stages", key: "AuditStages", href: "#"},
    {as: "a", content: "Edit Organisation", key: "EditOrganisation", href: "#"},
    {as: "a", content: "Edit Page Content", key: "EditPageContent", href: "#"},
    {as: "a", content: "Edit FAQs", key: "EditFAQs", href: "#"},
    {as: "a", content: "Unlock IP address", key: "UnlockIPaddress", href: "#"}
];

const adminProfileMenu = [
    {as: "a", content: "Edit Assessor", key: "EditAssessor", href: "#"}
];

const superAdminProfileMenu = [
    {as: "a", content: "Assessor Chair", key: "AssessorChair", href: "#"},
    {as: "a", content: "Edit Assessor", key: "EditAssessor", href: "#"},
    {as: "a", content: "Assessors", key: "Assessors", href: "#"},
    {as: "a", content: "Admins", key: "Admins", href: "#"},
    {as: "a", content: "Edit Custodian", key: "EditCustodian", href: "#"},
    {as: "a", content: "Change Login email", key: "ChangeLoginEmail", href: "/profile/userloginnamechange"},

];



const adminMenu = [
    {as: "a", content: "Welcome", key: "welcome", href: "/dashboard"},
    {as: "a", content: SubMenu(adminSearchMenu,'Search'), key: "search" },
    {as: "a", content: SubMenu(adminMISMenu,'MIS Reports'), key: "misreports"},
    {as: "a", content: SubMenu(adminMISCMenu,'MISC'), key: "misc"},
    {as: "a", content: SubMenu(adminProfileMenu,'Profile'), key: "profile"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"},
];



// auditor menu declaration
const auditorMenu = [
    {as: "a", content: "Profile", key: "profile",href: "/profile"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"}
];

const auditorActiveMenu = [
    {as: "a", content: "Dashboard", key: "welcome", href: "/welcome"},
    {as: "a", content: "Accreditation", key: "accriditation", href: "/accriditation"},
    {as: "a", content: "Audits", key: "audits",href: "/audits"},
    {as: "a", content: "Profile", key: "profile",href: "/profile"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"}
];

// assessor menu declaration
const assessorMenu = [
    {as: "a", content: "Welcome", key: "welcome"},
    {as: "a", content: SubMenu(adminSearchMenu,'Search'), key: "search" },
    {as: "a", content: "Profile", key: "profile",href: "/profile"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"}
];

//assessor chair menu declaration
const assessorChairMenu = [
    {as: "a", content: "Welcome", key: "welcome"},
    {as: "a", content: SubMenu(adminSearchMenu,'Search'), key: "search" },
    {as: "a", content: SubMenu(assessorChairMISMenu,'MIS Reports'), key: "misreports"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"},
];

//super admin menu declaration
const superAdminMenu = [
    {as: "a", content: "Welcome", key: "welcome", href: "/dashboard"},
    {as: "a", content: SubMenu(adminSearchMenu,'Search'), key: "search" },
    {as: "a", content: SubMenu(superAdminMISMenu,'MIS Reports'), key: "misreports"},
    {as: "a", content: SubMenu(superAdminMISCMenu,'MISC'), key: "misc"},
    {as: "a", content: SubMenu(superAdminProfileMenu,'Profile'), key: "profile"},
    {as: "a", content: "FAQs", key: "faqs", href:"/faqs"},//added by ShaneW
    {as: "a", content: "About Us", key: "aboutUs", href: "/aboutus"},
    {as: "a", content: "Contact Us", key: "contactUs", href: "/contactus"},
];

export {publicMenu, adminMenu, auditorMenu, auditorActiveMenu, assessorMenu, assessorChairMenu, superAdminMenu};