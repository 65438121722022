//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";
import {AuditorByCondition} from "../../../../models/apiResponce/Auditor/AuditorByCondition";
import moment from "moment";
import { CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchAllCourses = async (courseId:number, providerId:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Courses/coursemanagement?courseID=${courseId}&providerID=${providerId}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCoursesRes: CourseManagementRes[] = result.courses; //store the result in userTitles array
    return objCoursesRes;//return the array
}


export {fetchAllCourses}