import React, {useState} from 'react' ;
import {Container, Grid} from "semantic-ui-react";
import Buttons from "../../framework/components/Buttons";


const HomeSlider = () => {
    const [leftMsg, setLeftMsg] = useState('Search for a recognised road safety auditor to participate in a road safety audit for a road/traffic project.')
    const [rightMsg, setRightMsg] = useState('To be eligible for road safety auditor accreditation and be listed on this Register, specific criteria must be met.')
    const onMouseEnter = (e:any) =>{
        const element = document.getElementById(e.target.id+'3')
        // if(element)
            // element.className = 'home-trapezoid-button_hover'
            // setTimeout(()=>{
            //     if(e.target.id == 'leftT')
            //         setLeftMsg('Search for a recognised road safety auditor to participate in a road safety audit for a road/traffic project.')
            //     if(e.target.id == 'rightT')
            //         setRightMsg('To be eligible for road safety auditor accreditation and be listed on this Register, specific criteria must be met.')
            // },300)
    }

    const onMouseExit = (e:any) =>{
        const element = document.getElementById(e.target.id+'3')

    }

    return(
      <React.Fragment>
            <Container fluid={true} className='hero-banner-container' style={{paddingTop:'135px'}}>
                <div className='hero-banner'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} widescreen={8} computer={8}>
                                <p className='top-title-home'>Search for Road Safety Auditors or get accredited by <span style={{color:'#db2828',fontWeight:'bolder'}} className='top-title-home-red'>Transport for NSW</span></p>
                            </Grid.Column>
                            <Grid.Column  mobile={16} tablet={16} widescreen={8} computer={8}>
                                <div className='home-trapezoid'>
                                   <Grid centered={true}>
                                       <Grid.Row columns={2}>
                                           <Grid.Column>
                                               <div className='left-trapezoid' id={'leftT'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
                                                   <p className='home-trapezoid-text-title' id={'leftT0'} >I’m a company</p>
                                                   <span className='home-trapezoid-text' id={'leftT1'} >looking for an auditor</span>
                                                   <p className='home-trapezoid-text-Details' id={'leftT2'}>{leftMsg}</p>
                                                   <div className='home-trapezoid-button' id={'leftT31'}>
                                                       <Buttons id={'btnSearch'} onClick={()=>{window.location.href = '/search'}} color='red' text={'Search'}
                                                                style={{width: '200px', height:'50px', textTransform:'uppercase', fontSize:'20px'}} className={"background-transaparent-red"}/>
                                                   </div>
                                               </div>

                                           </Grid.Column>
                                           <Grid.Column >
                                               <div className='right-trapezoid' id={'rightT'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
                                                   <p className='home-trapezoid-text-title-R' id={'rightT0'} >I’m an auditor</p>
                                                   <span className='home-trapezoid-text-R' id={'rightT1'}  >looking for accreditation</span>
                                                   <p className='home-trapezoid-text-Details-R' id={'rightT2'}>{rightMsg}</p>
                                                   <div className='home-trapezoid-button-R' id={'rightT3'}>
                                                       <Buttons id={'btnSearch'} onClick={()=>{window.location.href = '/registration'}} color='red' text={'Register'}
                                                                style={{width: '200px', height:'50px', textTransform:'uppercase', fontSize:'20px'}} className={"background-transaparent-red"}/>
                                                   </div>
                                               </div>
                                           </Grid.Column>
                                       </Grid.Row>
                                   </Grid>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </Container>
          <Container textAlign={"center"} id={'HomeText'}>
              <h4 style={{fontSize:'31px'}}> This Register of Road Safety Auditors was established in 2002 for use in New South Wales.</h4>
              <p style={{fontSize:'25px'}}>It recognises a practitioner’s skills and experience in road safety auditing and promotes their professional development in road safety auditing. It also offers access to a listing of the recognised road safety auditors, their accreditation level and accreditation expiry date</p>
          </Container>
          <br/>
      </React.Fragment>
    );
}

export default HomeSlider;