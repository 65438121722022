

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";
import { AdminLogRes, LevelChangelogRes, StatusChangeLogRes, AssesmentLogsRes,UploadLogsRes } from "../../../../models/apiResponce/mis/LogsRes";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const fetchAdmintLogs = async (auditorId?:number,pageIndex?:number) => {
    const auditorID = auditorId == undefined ? '' : `auditorId=${auditorId}`
    const page = pageIndex == undefined ? '' : `pageIndex=${pageIndex}`
    const params = auditorID.length > 1 && page.length > 1 ? `?${auditorID}&${page}`:auditorID.length > 1 ? `?${auditorID}`:page.length > 1 ? `?${page}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/adminlogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultAdmin = result; //store the result in userTitles array
    return objresult;//return the array
}


interface resultAdmin {
    totalRecords: number,
    result: AdminLogRes[]
}

//to get the list of all the contact logs.
const fetchStatustLogs = async (auditorId?:number,pageIndex?:number) => {
    const auditorID = auditorId == undefined ? '' : `auditorId=${auditorId}`
    const page = pageIndex  == undefined ? '' : `pageIndex=${pageIndex}`
    const params = auditorID.length > 1 && page.length > 1 ? `?${auditorID}&${page}`:auditorID.length > 1 ? `?${auditorID}`:page.length > 1 ? `?${page}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/statusupdatelogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultStatusLog = result; //store the result in userTitles array
    return objresult;//return the array
}


interface resultStatusLog {
    totalRecords: number,
    result: StatusChangeLogRes[]
}


//to get the list of all the contact logs.
const fetchLevelChangeLogs = async (auditorId?:number,pageIndex?:number) => {
    const auditorID = auditorId == undefined ? '' : `auditorId=${auditorId}`
    const page = pageIndex == undefined ? '' : `pageIndex=${pageIndex}`
    const params = auditorID.length > 1 && page.length > 1 ? `?${auditorID}&${page}`:auditorID.length > 1 ? `?${auditorID}`:page.length > 1 ? `?${page}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/levelchangelogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultLevelChange = result; //store the result in userTitles array
    return objresult;//return the array
}


interface resultLevelChange {
    totalRecords: number,
    result: LevelChangelogRes[]
}


//to get the list of all the contact logs.
const fetchAssesmentLogs = async (auditorId?:number,pageIndex?:number) => {
    const auditorID = auditorId == undefined ? '' : `auditorId=${auditorId}`
    const page = pageIndex == undefined ? '' : `pageIndex=${pageIndex}`
    const params = auditorID.length > 1 && page.length > 1 ? `?${auditorID}&${page}`:auditorID.length > 1 ? `?${auditorID}`:page.length > 1 ? `?${page}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assesmentlogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultAssesmentLogs = result; //store the result in userTitles array
    return objresult;//return the array
}


interface resultAssesmentLogs {
    totalRecords: number,
    result: AssesmentLogsRes[]
}


//to get the list of all the contact logs.
const fetchUploadsLogs = async (auditorId?:number,pageIndex?:number) => {
    const auditorID = auditorId == undefined ? '' : `auditorId=${auditorId}`
    const page = pageIndex == undefined ? '' : `pageIndex=${pageIndex}`
    const params = auditorID.length > 1 && page.length > 1 ? `?${auditorID}&${page}`:auditorID.length > 1 ? `?${auditorID}`:page.length > 1 ? `?${page}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/uploadlogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultUploads = result; //store the result in userTitles array
    return objresult;//return the array
}

const fetchDownloadLogs = async (type:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/activitylogdownload?ReportType=${type}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}


interface resultUploads {
    totalRecords: number,
    result: UploadLogsRes[]
}

export {fetchAdmintLogs, fetchStatustLogs, fetchLevelChangeLogs, fetchAssesmentLogs, fetchUploadsLogs, fetchDownloadLogs};