//to get the list of all the titles.
import ApiCall from "../../../framework/common/apiCall";
import APICallMethods from "../../../framework/types/APICallMethods";
import UserTitles from "../../../models/apiResponce/Common/userTitles";
import CourseProviders from "../../../models/apiResponce/Common/CourseProviders";
import Countries from "../../../models/apiResponce/Common/Countries";
import Courses from "../../../models/apiResponce/Common/Courses";
import {
    auditor7YrsExpException,
    AuditorProfile,
    AuditorRegionValueProps,
    BusinessDetailsValueProps,
    LeaveExtend, lstAuditParticipantsReq,
    PersonalDetailsValueProps,
    QualificationValueProps,
    RoadSafetyAuditProps,
    RoadSafetyCoursesProps
} from "./DataModal";
import AddCourseReq from "../../../models/apiRequest/Course/AddCourseReq";
import AddAuditReq from "../../../models/apiRequest/Audit/AddAuditReq";
import States from "../../../models/apiResponce/Common/States";
import AuditStages from "../../../models/apiResponce/Audits/AuditStages";
import Audits from "../../../models/apiResponce/Audits/Audits";
import ContactDetailsReq from "../../../models/apiRequest/Auditor/ContactDetailsReq";
import BusinessDetailsReq from "../../../models/apiRequest/Auditor/BusinessDetailsReq";
import {AuditorRes ,AuditorsDet} from "../../../models/apiResponce/Auditor/AuditorRes";
import CoursesRes from "../../../models/apiResponce/Courses/CoursesRes";
import moment from "moment/moment";
import SingleCourseRes from "../../../models/apiResponce/Courses/SingleCourseRes";
import {ExperianceRes, ExperticeRes} from "../../../models/apiResponce/Auditor/QualificationRes";
import {AuthRegiRes} from "../../../models/apiResponce/Auditor/AuthRegiRes";
import {getSessionCookie, setSessionCookie} from "../../../framework/common/SessionHandler";
import ContactMethods from "../../../models/apiResponce/Common/ContactMethods";
import Companies from "../../../models/apiResponce/Common/Companies";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import ApiCallAxio from "../../../framework/common/apiCallAxio";
import {AuditorPersonalDetailsRes} from "../../../models/apiResponce/Auditor/AuditorPersonalDetailsRes";
import {AuditorBusinessDetailsRes} from "../../../models/apiResponce/Auditor/AuditorBusinessDetailsRes";
import {SelectedRegions} from "../../../models/apiResponce/Auditor/SelectedRegions";
import {AuditorCourseRess} from "../../../models/apiResponce/Auditor/AuditorCourseRess";
import {AuditorQualificationRes} from "../../../models/apiResponce/Auditor/AuditorQualificationRes";
import {AuditorAuditRess} from "../../../models/apiResponce/Auditor/AuditorAuditRess";
import AddQulification from "../../../models/apiRequest/Audit/AddQulification";
import {LoadAuditor, SignIn} from "../../../framework/common/Sign";
import {loginRequest} from "../../../models/common/login";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import SingleCourseResWithMappingID from "../../../models/apiResponce/Courses/SingleCourseResWithMappingID";
import AuditorCourseReq from "../../../models/apiRequest/Course/AuditorCourseReq";
import {InfoToggleToAuditorRess, lstInfoToggleToAuditorRess} from "../../../models/apiResponce/Auditor/InforToggle";
import successToast from "../../../framework/components/Toast/SuccessToast";
import {fetchFiles} from "../../../framework/components/FileUpload/FileUploadController";
import SuccessToast from "../../../framework/components/Toast/SuccessToast";
import Assessors from "../../../models/apiResponce/Common/Assessors";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {
    AdminAddCommenttoAssessorReq, AdminAddedCommenttoAssessorRes,
    AssessorAssignReq,
    AssignAssessors
} from "../../../models/apiResponce/Auditor/AssignAssessors";
import AuditorsStatus from "../../../models/apiResponce/Common/AuditorsStatus";
import {IAdminNotifications, IAuditLogs} from "../../../models/apiResponce/Notification/GetNotifications";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

// Get Data

const fetchAuthRegi = async (code: number, code2: string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorRegistration/AuthRegiUser`; //complete url
    let authRegi: PersonalDetailsValueProps = {} as PersonalDetailsValueProps; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const data = JSON.stringify({
        code: code,
        vcd: code2
    })
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    //eslint-disable-next-line
   // debugger
    const objAuthRegi: AuthRegiRes = result.authForRegiRes; //store the result in userTitles array
    if (result.authForRegiRes.auditorId == null) {
        authRegi = {
            txtAuditorId: 0,
            txtTitle: objAuthRegi.titleId.toString(),
            txtFirstName: objAuthRegi.firstName,
            txtMiddleName: objAuthRegi.middleName,
            txtLastName: objAuthRegi.lastName,
            txtEmail: objAuthRegi.email,
            txtApplyLevel: "",
            txtPassword: "",
        }
        setSessionCookie({
            "userName": objAuthRegi.email,
            "userDisplayName": `${objAuthRegi.firstName} ${objAuthRegi.lastName}`,
            "userRoleId": 3,
            "userId": 0,
            "auditorId": 0,
            "lastLogin": "",
            "status": 8
        })
        const objAuditorProfile: AuditorProfile = {
            personalDetails: authRegi,
            businessDetails: {} as BusinessDetailsValueProps,
            auditorRegion: {} as AuditorRegionValueProps,
            roadSafetyCourses: [] as RoadSafetyCoursesProps[],
            roadSafetyAudit: [] as RoadSafetyAuditProps[],
            qualification: {} as QualificationValueProps,
            Editable: true,
        }
        return objAuditorProfile;//return the array
    } else {
        window.location.href = '/'
    }

}

const fetchAuditorAudit = async (auditorId: number, pageNumber: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/AuditorAuditsWithPagination`; //complete url
    const data = JSON.stringify({
        auditorId: auditorId,
       // countryStateId: 1,
        pageSize: 10,
        pageNumStartingZero: pageNumber
    })
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    //eslint-disable-next-line
    const objAudits: AuditorAuditRess[] = result.auditorAuditRess.lstVwAuditorAuditRes; //store the result in userTitles array
    return objAudits

}

const fetchAuditorAuditAll = async (auditorId: number, pageNumber: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/GetAuditorAllAudits`; //complete url
    const data = JSON.stringify({
        auditorId: auditorId,
        countryStateId: 1,
        pageSize: 10,
        pageNumStartingZero: pageNumber
    })
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    //eslint-disable-next-line
    const objAudits: AuditorAuditRess[] = result.auditorAuditRess.lstVwAuditorAuditRes; //store the result in userTitles array
    return objAudits

}

const fetchAuditor = async (AuditorId: number, pageNumber: number) => {
    let objAuditorPersonalDetails: PersonalDetailsValueProps = {} as PersonalDetailsValueProps;
    let objAuditorBusinessDetails: BusinessDetailsValueProps = {} as BusinessDetailsValueProps;
    const objAuditorRoadSafetyAudit: object[] = [];
    const objAuditorRoadSafetyCourses: RoadSafetyCoursesProps[] = [];
    let objAuditorRegion: AuditorRegionValueProps = {} as AuditorRegionValueProps;
    let objQualification: QualificationValueProps = {} as QualificationValueProps;
    let AD: MsgFromAdmin = {} as MsgFromAdmin;
    let BD: MsgFromAdmin = {} as MsgFromAdmin;
    let RSA: MsgFromAdmin = {} as MsgFromAdmin;
    let RSC: MsgFromAdmin = {} as MsgFromAdmin;
    let AVA: MsgFromAdmin = {} as MsgFromAdmin;
    let QE: MsgFromAdmin = {} as MsgFromAdmin;

    //const url = `${baseUrl}/${sublink}/${version}/Auditor/${AuditorId}`; //complete url

    if(AuditorId === 0){
        return {} as AuditorProfile;
    }
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorProfile`; //complete url
    const data = JSON.stringify({
        auditorId: AuditorId,
        countryStateId: 1,
        pageSize: 10,
        pageNumStartingZero: pageNumber
    })
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const auditList = await fetchAuditorAudit(AuditorId, pageNumber);
    //eslint-disable-next-line
    //debugger;
    const objAuditorPre: AuditorPersonalDetailsRes = result.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes; //store the result in auditorPersonalDetailsRes object
    const objAuditorBusinessPre: AuditorBusinessDetailsRes = result.auditorProfileForAuditorRoleRes.auditorBusinessDetailsRes; //store the result in auditorBusinessDetailsRes object
    const objRegionPre: SelectedRegions[] = result.auditorProfileForAuditorRoleRes.auditorRegionsAvailableRes.selectedRegions;
    const objExtendLeave: LeaveExtend[] = result.auditorProfileForAuditorRoleRes.auditorRegionsAvailableRes.auditorLeavesRes;
    const objCoursesPre: AuditorCourseRess[] = result.auditorProfileForAuditorRoleRes.auditorCourseRess;
    const objQulificationPre: AuditorQualificationRes = result.auditorProfileForAuditorRoleRes.auditorQualificationRes;
    const objInfoToggleToAuditorRess: InfoToggleToAuditorRess = result.auditorProfileForAuditorRoleRes.infoToggleToAuditorRess
    const objAuditor: AuditorRes = {
        auditorId: objAuditorPre.auditorId,
        titleId: objAuditorPre.titleId,
        firstName: objAuditorPre.firstName,
        middleName: objAuditorPre.middleName,
        lastName: objAuditorPre.lastName,
        email: objAuditorPre.email,
        newAuditorId: objAuditorPre.newAuditorId,
        auditorOldId: objAuditorPre.auditorId,
        applyingLevel: objAuditorPre.applyingLevel,
        b12MonthsMailReminder: false,
        bOverwriteExpiryDate: false,
        createdDate: "",
        dt14DaysMailReminder: "1900-01-01T00:00:00",
        dt3MonthsMailReminder: "1900-01-01T00:00:00",
        editable: objAuditorPre.isRegistrationInProgress == true || objAuditorPre.auditorsStateRes.auditorStatus == 3 ? true : false,
        fiveYearsExp: false,
        gracePeriod: "",
        gracePeriodYn: "",
        inactiveDate: "1900-01-01T00:00:00",
        lastUpdated: "",
        oldUsername: "",
        orgAddress: objAuditorBusinessPre.orgAddress,
        orgBranch: objAuditorBusinessPre.orgBranch,
        orgCountryId: objAuditorBusinessPre.orgCountryId,
        orgName: objAuditorBusinessPre.orgName,
        orgOther: objAuditorBusinessPre.orgOther,
        orgPosition: objAuditorBusinessPre.orgPosition,
        orgPostcode: objAuditorBusinessPre.orgPostcode,
        orgStateId: objAuditorBusinessPre.orgStateId,
        orgSuburb: objAuditorBusinessPre.orgSuburb,
        previousAuditorLevel: objAuditorPre.auditorsStateRes != null ? objAuditorPre.auditorsStateRes.auditorLevel : 0,
        sevenYearsExp: false,
        uniqueGuid: "",
        isRegistration: objAuditorPre.isRegistrationInProgress
    }

    if (objAuditorPre.isRegistrationInProgress) {
        const session = getSessionCookie();

        setSessionCookie({
            userName: objAuditorPre.email,
            userDisplayName: `${objAuditorPre.firstName} ${objAuditorPre.lastName}`,
            userRoleId: session.userRoleId,
            userId: session.userId,
            auditorId: session.auditorId,
            lastLogin: session.lastLogin,
            status: 8,
            statusName: "Save as Draft",
        })
    }
    //eslint-disable-next-line
    let objRegion = {
        greateSyndey: false,
        north: false,
        south: false,
        west: false,
        act: false,
        vic: false,
        qld: false,
        sa: false,
        wa: false,
        tas: false,
        nt: false,
        overseas: false
    };

    objRegionPre.forEach((r: SelectedRegions) => {
        switch (r.regionId) {
            case 1:
                objRegion.greateSyndey = true;
                break;
            case 2:
                objRegion.north = true;
                break;
            case 3:
                objRegion.south = true;
                break;
            case 4:
                objRegion.west = true;
                break;
            case 5:
                objRegion.vic = true;
                break;
            case 6:
                objRegion.wa = true;
                break;
            case 7:
                objRegion.act = true;
                break;
            case 8:
                objRegion.qld = true;
                break;
            case 9:
                objRegion.sa = true;
                break;
            case 10:
                objRegion.tas = true;
                break;
            case 11:
                objRegion.nt = true;
                break;
            case 12:
                objRegion.overseas = true;
                break;
        }
    })

    const objContactDetails = {
        mobile: objAuditorBusinessPre.mobile,
        homePhone: objAuditorBusinessPre.homePhone,
        skype: objAuditorBusinessPre.skype,
        workEmail: objAuditorBusinessPre.workEmail,
        personalEmail: objAuditorBusinessPre.personalEmail,
        workPhone: objAuditorBusinessPre.workPhone,
        preferContactMethod: objAuditorBusinessPre.preferContactMethod
    };

    let objMsgToAuditorFromAdmin: lstInfoToggleToAuditorRess[] = []//MsgFromAdmin[] = []//result.msgfromAdmin;
    if (objInfoToggleToAuditorRess != null) {
        objMsgToAuditorFromAdmin = objInfoToggleToAuditorRess.lstInfoToggleToAuditorRess
    }

    const objCourses: object[] = [];
    objCoursesPre.forEach((x) => {
        const course = {
            id: x.mappingAuditorCourseId,
            auditorId: x.auditorId,
            courseProvider: x.courseProviderName,
            courseName: x.courseName,
            courseDescription: "",
            courseLocation: x.location,
            dateStart: x.courseDate,
            dateComplete: x.courseDate,
            elapsedDate: x.courseElapseDate,
            statusId: 1,
            isVerified: x.isVerified,
            createdDate: x.courseDate,
            courseProviderEmail: x.courseProviderEmail,
            courseCertNum: x.certificateNumber,
            courseCertMailCnt: null,
            courseCertMailDate: null,
            courseId: x.courseId,
            courseTentativeClassification: x.courseTentativeClassification,
            adminEmailAskedNewCourse: null,
            dateByAdminAskedNewCourse: null,
            dateAssesssedBySuperadmin: null,
            resultAssesssedBySuperadmin: null
        }
        objCourses.push(course)
    })
    //eslint-disable-next-line
    const ads: Audits[] = [];
    auditList.forEach((adr) => {
        ads.push({
            auditId: adr.auditId,
            auditorId: AuditorId,
            auditStatusId: adr.auditStatusId,
            auditFinalSignoffDate: adr.auditFinalSignoffDate,
            auditTitle: adr.auditTitle,
            auditLocationDescription: adr.auditLocationDescription,
            auditLocationStateId: adr.countryStateId,
            auditClientCompany: "",
            auditContactPerson: "",
            auditContactPhone: "",
            auditContactMobile: "",
            auditContactEmail: "",
            stageId: adr.stageId,
            typeId: adr.typeId,
            elapseDate: moment(adr.auditFinalSignoffDate, "DD-MM-YYYY").add(0, 'years').toString(),
            addedDate: adr.auditFinalSignoffDate,
            modifyDate: adr.auditFinalSignoffDate,
            isTeamLeader: adr.isTeamLeader,
            teamLeaderName: adr.teamLeaderName,
            isPubliclyVisible: adr.isTfNswProject,
            projectDesc: adr.auditTitle,
            auditPurposeScope: "",
            emailSentCounter: '',
            emailSentDate: '',
            isTfNswProject: adr.isTfNswProject,
            countryStateId: adr.countryStateId,
            countryState: null,
            auditLogs: "",
            auditorAudits: "",
            reports: "",
            isVerify: adr.isConfirmedByAdmin,
            linked: adr.isConfirmedByAdmin,
            auditStatusName: adr.auditStatusName,
            auditDisplayName: adr.auditDisplayName,
            auditPhaseId: adr.auditPhaseId,
            auditPhaseName: adr.auditPhaseName,
            auditStageName: adr.auditStageName,
            auditStatusIspublic: adr.auditStatusIspublic

        })
    })


    const objAudits: Audits[] = ads != null ? ads : []//result.audits;

    //eslint-disable-next-line
    let objExpert: ExperticeRes = {
        constructionProject: false,
        crashInvestigation: false,
        designConstructionProjects: false,
        developmentPlanning: false,
        intersectionProjects: false,
        localAreaTrafficManagementProjects: false,
        reconstructionProjects: false,
        temporaryTrafficManagement: false,
        trafficSignalProjects: false,
        vulnerableRoadUser: false
    };
    if (objQulificationPre.selectedAuditorExpertiseIds != null) {
        objQulificationPre.selectedAuditorExpertiseIds.forEach((y) => {
            switch (y) {
                case 1:
                    objExpert.constructionProject = true;
                    break;
                case 2:
                    objExpert.reconstructionProjects = true;
                    break;
                case 3:
                    objExpert.intersectionProjects = true;
                    break;
                case 4:
                    objExpert.localAreaTrafficManagementProjects = true;
                    break;
                case 5:
                    objExpert.vulnerableRoadUser = true;
                    break;
                case 6:
                    objExpert.trafficSignalProjects = true;
                    break;
                case 7:
                    objExpert.temporaryTrafficManagement = true;
                    break;
                case 8:
                    objExpert.developmentPlanning = true;
                    break;
                case 9:
                    objExpert.designConstructionProjects = true;
                    break;
                case 10:
                    objExpert.crashInvestigation = true;
                    break;
            }
        })
    }

    const objExperience: ExperianceRes = {
        bsSummary: objQulificationPre.bsSummary,
        behaviouralScience: objQulificationPre.behaviouralScience ? objQulificationPre.behaviouralScience.toString():'0',
        eligibilityCriteria: objQulificationPre.eligibilityCriteriaExperienceId.toString(),
        experienceSkillsSummary: objQulificationPre.experienceSkillsSummary,
        mitigation: objQulificationPre.mitigation,
        otherRelevantExperience: objQulificationPre.otherRelevantExperience,
        qualifications: objQulificationPre.qualifications,
        roadDesign: objQulificationPre.roadDesign ? objQulificationPre.roadDesign.toString() : '0',
        rdSummary: objQulificationPre.rdSummary,
        roadSafetyEngineering: objQulificationPre.roadSafetyEngineering ? objQulificationPre.roadSafetyEngineering.toString() : '0',
        totalExperience: objQulificationPre.totalYearsExperience ? objQulificationPre.totalYearsExperience.toString() : '0',
        trSummary: objQulificationPre.trSummary,
        trafficEngineering: objQulificationPre.trafficEngineering ? objQulificationPre.trafficEngineering.toString() : '0',
        ttmSummary: objQulificationPre.ttmSummary,
        trafficTransportManagement: objQulificationPre.trafficTransportManagement ? objQulificationPre.trafficTransportManagement.toString() : '0',
        workExperienceSummary: objQulificationPre.workExperienceSummary != null ? objQulificationPre.workExperienceSummary.toString() : ""
    };


    objAuditorPersonalDetails = {
        txtAuditorId: objAuditor.auditorId,
        txtTitle: objAuditor.titleId.toString(),
        txtFirstName: objAuditor.firstName,
        txtMiddleName: objAuditor.middleName,
        txtLastName: objAuditor.lastName,
        txtEmail: objAuditor.email,
        txtApplyLevel: objAuditor.applyingLevel == null ? objAuditor.previousAuditorLevel.toString() : objAuditor.applyingLevel.toString(),
        txtPassword: "password",
        txtNewAuditorId:objAuditor.newAuditorId,
        txtCurrentLevel:objAuditorPre.auditorsStateRes.auditorLevel ? objAuditorPre.auditorsStateRes.auditorLevel.toString() : '0',
        AuditorsStateRes:objAuditorPre.auditorsStateRes
    }

    objAuditorBusinessDetails = {
        txtAuditorId: objAuditor.auditorId,
        txtCompanyName: objAuditor.orgName,
        txtBranchName: objAuditor.orgBranch,
        txtDesignation: objAuditor.orgPosition,
        txtOfficePhone: objContactDetails.workPhone,
        txtMobilePhone: objContactDetails.mobile,
        txtHomePhone: objContactDetails.homePhone,
        txtSkypeId: objContactDetails.skype,
        txtWorkEmail: objContactDetails.workEmail,
        txtPersonalEmail: objContactDetails.personalEmail,
        txtPreferredContact: objContactDetails.preferContactMethod ? objContactDetails.preferContactMethod.map(function (e) {
            return e.toString()
        }) : [],
        txtOfficeAddress: objAuditor.orgAddress,
        txtSuburb: objAuditor.orgSuburb,
        txtState: objAuditor.orgStateId ? objAuditor.orgStateId.toString() : "",
        txtPostcode: objAuditor.orgPostcode,
        txtCountry: objAuditor.orgCountryId ? objAuditor.orgCountryId.toString() : "",
    }
    objAuditorRegion = objRegion
    objAuditorRegion.leaveExtend = objExtendLeave;
    objAuditorRegion.txtAuditorId = objAuditor.auditorId;
    const getfile = await fetchFiles(objAuditorPre.email, 'CERT')

    objCourses.map((item: CoursesRes) => {
        objAuditorRoadSafetyCourses.push({
            id: item.id,
            courseId: item.courseId ? item.courseId : 0,
            courseProvider: item.courseProvider,
            courseName: item.courseName,
            courseDate: moment(item.dateComplete).format("DD-MM-YYYY"),
            location: item.courseLocation,
            certificateNumber: item.courseCertNum,
            providerEmail: item.courseProviderEmail,
            courseTentativeClassification: item.courseTentativeClassification,
            isVerified: item.isVerified,
            iconName: getfile.length > 0 && getfile.some(x => x.refId2 == item.id) ?  'file' : 'attach'
        })
    });

    objMsgToAuditorFromAdmin.map((r: lstInfoToggleToAuditorRess) => {
        switch (r.sectionName) {
            case 'AD':
                AD = AD.id ? {
                        auditorId: AD.auditorId,
                        isConfirmedByAuditor: AD.isConfirmedByAuditor,
                        id: AD.id,
                        isActivated: true,
                        sectionName: AD.sectionName,
                        msgFromAdmin: AD.msgFromAdmin,
                        Type: AD.Type,
                        isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                        id2: r.requestedMsgId,
                        msgFromAdmin2: r.msgToAuditor,
                        update:AD.update,
                        update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        createDate:AD.createDate,
                        createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        ResponceDate: AD.ResponceDate,
                        ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        isConfirmedByAdminOnDate:AD.isConfirmedByAdminOnDate
                    } as MsgFromAdmin
                    : {
                        auditorId: r.auditorId,
                        isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                        id: r.requestedMsgId,
                        isActivated: true,
                        sectionName: r.sectionName,
                        msgFromAdmin: r.msgToAuditor,
                        update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        Type: r.infoToggleType,
                        createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                    } as MsgFromAdmin;
                break;
            case 'BD':
                BD = BD.id ? {
                        auditorId: BD.auditorId,
                        isConfirmedByAuditor: BD.isConfirmedByAuditor,
                        id: BD.id,
                        isActivated: true,
                        sectionName: BD.sectionName,
                        msgFromAdmin: BD.msgFromAdmin,
                        Type: BD.Type,
                        isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                        id2: r.requestedMsgId,
                        msgFromAdmin2: r.msgToAuditor,
                        update:BD.update,
                        update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        createDate:BD.createDate,
                        createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        ResponceDate: BD.ResponceDate,
                        ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        isConfirmedByAdminOnDate:BD.isConfirmedByAdminOnDate
                    } as MsgFromAdmin
                    : {
                        auditorId: r.auditorId,
                        isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                        id: r.requestedMsgId,
                        isActivated: true,
                        sectionName: r.sectionName,
                        msgFromAdmin: r.msgToAuditor,
                        Type: r.infoToggleType,
                        update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                        isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                    } as MsgFromAdmin;
                break;
            case 'AVA':
                AVA = AVA.id ? {
                    auditorId: AVA.auditorId,
                    isConfirmedByAuditor: AVA.isConfirmedByAuditor,
                    id: AVA.id,
                    isActivated: true,
                    sectionName: AVA.sectionName,
                    msgFromAdmin: AVA.msgFromAdmin,
                    Type: AVA.Type,
                    isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                    id2: r.requestedMsgId,
                    msgFromAdmin2: r.msgToAuditor,
                    update:AVA.update,
                    update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:AVA.createDate,
                    createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: AVA.ResponceDate,
                    ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:AVA.isConfirmedByAdminOnDate
                } as MsgFromAdmin : {
                    auditorId: r.auditorId,
                    isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                    id: r.requestedMsgId,
                    isActivated: true,
                    sectionName: r.sectionName,
                    msgFromAdmin: r.msgToAuditor,
                    Type: r.infoToggleType,
                    update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                } as MsgFromAdmin;
                break;
            case 'RSA':
                RSA = RSA.id ? {
                    auditorId: RSA.auditorId,
                    isConfirmedByAuditor: RSA.isConfirmedByAuditor,
                    id: RSA.id,
                    isActivated: true,
                    sectionName: RSA.sectionName,
                    msgFromAdmin: RSA.msgFromAdmin,
                    Type: RSA.Type,
                    isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                    id2: r.requestedMsgId,
                    msgFromAdmin2: r.msgToAuditor,
                    update:RSA.update,
                    update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:RSA.createDate,
                    createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: RSA.ResponceDate,
                    ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:RSA.isConfirmedByAdminOnDate
                } as MsgFromAdmin : {
                    auditorId: r.auditorId,
                    isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                    id: r.requestedMsgId,
                    isActivated: true,
                    sectionName: r.sectionName,
                    msgFromAdmin: r.msgToAuditor,
                    Type: r.infoToggleType,
                    update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                } as MsgFromAdmin;
                break;
            case 'RSC':
                RSC = RSC.id ? {
                    auditorId: RSC.auditorId,
                    isConfirmedByAuditor: RSC.isConfirmedByAuditor,
                    id: RSC.id,
                    isActivated: true,
                    sectionName: RSC.sectionName,
                    msgFromAdmin: RSC.msgFromAdmin,
                    Type: RSC.Type,
                    isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                    id2: r.requestedMsgId,
                    msgFromAdmin2: r.msgToAuditor,
                    update:RSC.update,
                    update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:RSC.createDate,
                    createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: RSC.ResponceDate,
                    ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:RSC.isConfirmedByAdminOnDate
                } as MsgFromAdmin : {
                    auditorId: r.auditorId,
                    isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                    id: r.requestedMsgId,
                    isActivated: true,
                    sectionName: r.sectionName,
                    msgFromAdmin: r.msgToAuditor,
                    Type: r.infoToggleType,
                    update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                } as MsgFromAdmin;
                break;
            case 'QE':
                QE = QE.id ? {
                    auditorId: QE.auditorId,
                    isConfirmedByAuditor: QE.isConfirmedByAuditor,
                    id: QE.id,
                    isActivated: true,
                    sectionName: QE.sectionName,
                    msgFromAdmin: QE.msgFromAdmin,
                    Type: QE.Type,
                    isConfirmedByAuditor2: r.auditorResponse_IsConfirmedByAuditor,
                    id2: r.requestedMsgId,
                    msgFromAdmin2: r.msgToAuditor,
                    update:QE.update,
                    update2:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:QE.createDate,
                    createDate2:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: QE.ResponceDate,
                    ResponceDate2: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:QE.isConfirmedByAdminOnDate
                } as MsgFromAdmin : {
                    auditorId: r.auditorId,
                    isConfirmedByAuditor: r.auditorResponse_IsConfirmedByAuditor,
                    id: r.requestedMsgId,
                    isActivated: true,
                    sectionName: r.sectionName,
                    msgFromAdmin: r.msgToAuditor,
                    Type: r.infoToggleType,
                    update:moment(r.updatedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    createDate:moment(r.createdOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    ResponceDate: moment(r.auditorResponse_ConfirmOrUnconfirmedChangedOnDate).format("DD-MM-YYYY hh:mm:ss A"),
                    isConfirmedByAdminOnDate:moment(r.isConfirmedByAdminOnDate).format("DD-MM-YYYY hh:mm:ss A")
                } as MsgFromAdmin;
                break;
        }
    })

    objAudits.map((item: Audits) => {
        objAuditorRoadSafetyAudit.push({
            auditId: item.auditId,
            titleOfAudit: item.auditTitle == "" ? "-" : item.auditTitle,
            finalSignoffDate: moment(item.auditFinalSignoffDate).format("DD-MM-YYYY") == "01-01-1900" ? "-" : moment(item.auditFinalSignoffDate).format("DD-MM-YYYY"),
            teamLeaderName: item.teamLeaderName == "" ? "-" : item.teamLeaderName,
            clientCompany: item.auditClientCompany,
            auditStatusId: item.auditStatusId.toString(),
            isVerify: item.linked,
            elapseDate: moment(item.auditFinalSignoffDate).add(3, 'years').format("DD-MM-YYYY"),
            isTfNSW: item.isTfNswProject,
            stageOfAudit: item.stageId
        })
    })

    objQualification = {
        auditorId: objAuditor.auditorId,
        totalExperience: objExperience.totalExperience,
        rdSummary: objQulificationPre.rdSummary,
        trSummary: objQulificationPre.trSummary,
        ttmSummary: objQulificationPre.ttmSummary,
        rseSummary: objQulificationPre.rseSummary,
        bsSummary: objQulificationPre.bsSummary,
        roadDesign: objExperience.roadDesign,
        trafficEngineering: objExperience.trafficEngineering,
        trafficTransportManagement: objExperience.trafficTransportManagement,
        roadSafetyEngineering: objExperience.roadSafetyEngineering,
        behaviouralScience: objExperience.behaviouralScience,
        workExperienceSummary: objExperience.workExperienceSummary,
        otherRelevantExperience: objExperience.otherRelevantExperience,
        qualifications: objExperience.qualifications,
        experienceSkillsSummary: objExperience.experienceSkillsSummary,
        mitigation: objExperience.mitigation,
        constructionProject: objExpert.constructionProject,
        reconstructionProjects: objExpert.reconstructionProjects,
        intersectionProjects: objExpert.intersectionProjects,
        localAreaTrafficManagementProjects: objExpert.localAreaTrafficManagementProjects,
        vulnerableRoadUser: objExpert.vulnerableRoadUser,
        trafficSignalProjects: objExpert.trafficSignalProjects,
        temporaryTrafficManagement: objExpert.temporaryTrafficManagement,
        developmentPlanning: objExpert.developmentPlanning,
        designConstructionProjects: objExpert.designConstructionProjects,
        crashInvestigation: objExpert.crashInvestigation,
        eligibilityCriteria: objExperience.eligibilityCriteria,
    }
    const session = getSessionCookie();

    const objAuditorProfile: AuditorProfile = {
        personalDetails: objAuditorPersonalDetails,
        businessDetails: objAuditorBusinessDetails,
        auditorRegion: objAuditorRegion,
        roadSafetyCourses: objAuditorRoadSafetyCourses,
        roadSafetyAudit: objAuditorRoadSafetyAudit,
        qualification: objQualification,
        Editable: session.status == 1 || session.status == 3 || session.status == 6 || session.status == 5 || session.status == 8 ? true : false, //objAuditor.editable,
        AD: AD,
        BD: BD,
        AVA: AVA,
        QE: QE,
        RSA: RSA,
        RSC: RSC,
        AtotalPages: result.auditorProfileForAuditorRoleRes.vwAuditorAuditRess.totalPages
    }
    //console.log(objAuditorProfile)
    return objAuditorProfile;//return the object
}

const fetchTitle = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/usertitles`; //complete url
    const titlesArray: { key: string, value: string, text: string }[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objUserTitle: UserTitles[] = result.userTitles; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objUserTitle.map((item: UserTitles) => {
        titlesArray.push({key: item.titleId.toString(), value: item.titleId.toString(), text: item.titleName})
    })
    return titlesArray;//return the array
}

const fetchAssessors = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/Assessors`; //complete url
    const titlesArray: DropDownOptions[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAssessors: Assessors[] = result.assessors; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAssessors.map((item: Assessors) => {
        titlesArray.push({key: parseInt(item.Id), value: item.Id, text: item.Name})
    })
    return titlesArray;//return the array
}

const fetchAuditor7YrsExpException = async (auditorId:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/Auditor7YrsExpException?auditorId=${auditorId}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditor7YrsExpException: auditor7YrsExpException = result.auditor7YrsExpException; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format

    return objAuditor7YrsExpException;//return the array
}

const fetchAssignAssessors = async (auditorId:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorsAssignAssessors?AuditorId=${auditorId}&countryStateId=${1}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAssessors: AssignAssessors[] = result.assessors; //store the result in userTitles array
    return objAssessors;//return the array
}

const fetchAdminAddComment = async (auditorId:number, pageNo:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/GetAdminAddCommentto?AuditorId=${auditorId}&PageNumber=${pageNo}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAssessors: AdminAddedCommenttoAssessorRes[] = result.assessors; //store the result in userTitles array
    const totalRecords = result.totalRecords;
    return {totalRecords,objAssessors};//return the array
}

const fetchAuditorStatus = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/Status`; //complete url
    const titlesArray: DropDownOptions[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditorsStatus: AuditorsStatus[] = result.status; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditorsStatus.map((item: AuditorsStatus) => {
        titlesArray.push({key: item.statusId, value: item.statusId.toString(), text: item.statusName})
    })
    return titlesArray;//return the array
}

const GetAdminNotifications = async (AuditorId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/AllAdminNotificationsForAuditor`; //complete url
        const data: object = {
            auditorId: AuditorId,
            countryStateId: 1
        }; //body data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data ,
        }); //call the api using ApiCall method

        const AdminNotifications:IAdminNotifications[] = result.adminNotifications
        return AdminNotifications;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const GetAuditLogs = async (AuditId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAuditLogsById?auditId=${AuditId}`; //complete urly data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.GET
        }); //call the api using ApiCall method

        const AuditLogs:IAuditLogs[] = result.auditRes
        return AuditLogs;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const fetchContactMethod = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/contactmethods`; //complete url
    const contactMethodsArray: object[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCall({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objContactMethod: ContactMethods[] = result.contactMethods; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objContactMethod.map((item: ContactMethods) => {
        contactMethodsArray.push({
            key: item.contactMethodName.toString(),
            value: item.contactMethodId.toString(),
            text: item.contactMethodName
        })
    })
    return contactMethodsArray;//return the array
}

const fetchCourseProviders = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/courses/courseproviders`; //complete url
    const providerArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCourseProviders: CourseProviders[] = result.courseProviders; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objCourseProviders.map((item: CourseProviders) => {
        providerArray.push({
            key: item.providerId.toString(),
            value: item.providerId.toString(),
            text: item.providerName
        })
    })
    return providerArray;//return the array
}

const fetchCourses = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/courses/all`; //complete url
    const coursesArray: object[] = []; //array to store the course list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCourses: Courses[] = result.courses; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    coursesArray.push({key: -100, value: -100, text: "---Add New Course---"})
    objCourses.map((item: Courses) => {
        coursesArray.push({key: item.courseId.toString(), value: item.courseId.toString(), text: item.courseName})
    })
    return coursesArray;//return the array
}

const fetchCoursesWithProviders = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/courses/all`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCourses: Courses[] = result.courses; //store the result in userTitles array
    return objCourses;//return the array
}

const fetchCountries = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/countries`; //complete url
    const countriesArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCountries: Countries[] = result.countries; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objCountries.map((item: Countries) => {
        countriesArray.push({key: item.countryId.toString(), value: item.countryId.toString(), text: item.countryName})
    })
    return countriesArray;//return the array
}

const fetchCompanies = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/companies`; //complete url
    const companiesArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCall({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCountries: Companies[] = result.companies; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objCountries.map((item: Companies) => {
        companiesArray.push({
            key: item.companyId.toString(),
            value: item.companyName.toString(),
            text: item.companyName
        })
    })
    return companiesArray;//return the array
}

const fetchStates = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/states`; //complete url
    const statesArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objStates: States[] = result.states; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objStates.map((item: States) => {
        statesArray.push({key: item.stateName.toString(), value: item.stateId.toString(), text: item.stateLongName})
    })
    return statesArray;//return the array
}

const fetchAuditStages = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/auditstages`; //complete url
    const auditStagesArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditStages: AuditStages[] = result.auditStages; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: AuditStages) => {
        auditStagesArray.push({
            key: item.auditStageId.toString(),
            value: item.auditStageId.toString(),
            text: item.auditDisplayName
        })
    })
    return auditStagesArray;//return the array
}

const fetchAudits = async (signOffDate: string, stageID: number, LocationStateID: number) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAudits`; //complete url
    const data = JSON.stringify({
        countryStateId: LocationStateID,
        auditFinalSignoffDate: signOffDate,
        stageId: stageID,
        pageSize: 10,
        pageNumStartingZero: 0,
        orderByFieldsIncAscOrDesc: ""
    })
    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes"
        })
    })
    return auditsArray;//return the array
}

const fetchAllActiveAudits = async (type:string, pageNumber:number) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAllAudits?type=${type}&currentPage=${pageNumber}`; //complete url

    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    const objTotalItem: number = result.auditRess.totalItemsCount; //store the result in userTitles array
    const objTotalPage: number = result.auditRess.totalPages;
    const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes"
        })
    })
    return {auditsArray,objTotalItem,objTotalPage,objCurrentPage};//return the array
}

const fetchSingleAudit = async (AuditID: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/Audit?auditid=${AuditID}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAudit: Audits = result.auditRes; //store the result in userTitles array
    const objAuditors:AuditorRes[] = result.auditors_Details;
    const objAuditorsDet:AuditorsDet[] = result.auditors;
    //eslint-disable-next-line
    let auditorList:lstAuditParticipantsReq[] = []
    objAuditors.map((r)=>{
        auditorList.push({
            auditorId:r.auditorId,
            auditorFullName: `${r.firstName} ${r.lastName}`,
            isConfirmed:false,
            isLeadAuditor:false
        })
    })
    //eslint-disable-next-line
    let auditorFullList:lstAuditParticipantsReq[] = []
    auditorList.forEach((r)=> {
        objAuditorsDet.forEach((x)=>{
            if(r.auditorId == x.auditorId){
                auditorFullList.push({
                    auditorId:r.auditorId,
                    auditorFullName: r.auditorFullName,
                    isConfirmed:x.linked,
                    isLeadAuditor:x.isLeader
                })
            }
        })
    })

    const auditRecord: RoadSafetyAuditProps = {
        auditId: objAudit.auditId,
        auditorId: objAudit.auditorId,
        finalSignoffDate: objAudit.auditFinalSignoffDate,
        stageOfAudit: objAudit.stageId.toString(),
        stateAuditPerformed: objAudit.auditLocationStateId.toString(),
        titleOfAudit: objAudit.auditTitle,
        locationOfAudit: objAudit.auditLocationDescription,
        isTfNSW: objAudit.isTfNswProject,
        projectDescription: objAudit.projectDesc,
        purposeOfAudit: objAudit.auditPurposeScope,
        clientCompany: objAudit.auditClientCompany,
        clientContactPerson: objAudit.auditContactPerson,
        clientContactNumber: objAudit.auditContactPhone,
        clientMobileNumber: objAudit.auditContactMobile,
        clientContactEmail: objAudit.auditContactEmail,
        teamLeaderName: objAudit.teamLeaderName,
        addedDate:moment(objAudit.addedDate).format("DD/MM/YYYY"),
        modifyDate:moment(objAudit.modifyDate).format("DD/MM/YYYY"),
        lstAuditParticipantsReq:auditorFullList,
        auditStatusId:objAudit.auditStatusId,
    }

    return auditRecord;//return the array
}

const fetchSingleCourse = async (CourseID: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Courses/${CourseID}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCourse: SingleCourseRes = result.course; //store the result in userTitles array
    const courseRecord: RoadSafetyCoursesProps = {
        courseProvider: objCourse.courseProviderId.toString(),
        courseName: objCourse.courseName,
        courseId: objCourse.courseId ? objCourse.courseId : 0,
        courseDate: objCourse.courseDate,
        location: objCourse.courseLocation,
        courseTentativeClassification: objCourse.flag,
        providerEmail: "",
        certificateNumber: "",
        supportDocuments: "",
    }

    return courseRecord;//return the array
}

const fetchSingleCourseUsingMID = async (CourseID: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/AuditorCourse?mappingAuditorCourseId=${CourseID}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCourse: SingleCourseResWithMappingID = result.auditorCourseRes; //store the result in userTitles array
    const courseRecord: RoadSafetyCoursesProps = {
        courseProvider: objCourse.courseProviderId != null ? objCourse.courseProviderId.toString() : "",
        courseName: objCourse.courseName,
        courseId: objCourse.courseId,
        courseDate: objCourse.courseDate,
        location: objCourse.location,
        courseTentativeClassification: "",
        providerEmail: objCourse.courseProviderEmail,
        certificateNumber: objCourse.certificateNumber,
        supportDocuments: "",
        courseProviderName: objCourse.courseProviderName
    }

    return courseRecord;//return the array
}

const fetchAuditorsByName = async (AuditorName: string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorSimpleSearch`; //complete url
    const data = {
        countryStateId: 1,
        auditorsWhoStartWith: AuditorName,
        pageSize: 100,
        pageNumStartingZero: 0,
        orderByFieldsIncAscOrDesc: ""
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const objAuditors: AuditorRes[] = result.auditorSimpleRess.lstAuditorSimpleRess; //store the result in userTitles array
    return objAuditors;//return the array
}

// Post Data
const AddOrUpdateAuditorDetails = async (data: PersonalDetailsValueProps) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/UpdateAuditorPersonalDetails`; //complete url
        const url2 = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorRegistration/RegisterAuditor`; //complete url
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const contactDetails: ContactDetailsReq = {
            auditorId: sessionStorage.getItem('tempUser') ? undefined : data.txtAuditorId,
            titleId: parseInt(data.txtTitle ? data.txtTitle : "0"),
            firstName: data.txtFirstName ? data.txtFirstName : "",
            lastName: data.txtLastName ? data.txtLastName : "",
            email: data.txtEmail ? data.txtEmail : "",
            middleName: data.txtMiddleName ? data.txtMiddleName : "",
            applyingLevel: parseInt(data.txtApplyLevel ? data.txtApplyLevel : ""),
            password: data.txtPassword ? data.txtPassword : "",
            countryStateId: 1
        }; //body data

        let splitted: any = [];
        const tempUser = sessionStorage.getItem('tempUser');
        //alert(tempUser);
        if (tempUser && tempUser.includes('#')) {
            splitted = tempUser.split('#');
        }

        const dataBody: object = {
            auditorPersonalDetailsReq: contactDetails,
            auditorBusinessDetailsReq: null,
            auditorQualificationReq: null,
            auditorRegionsAvailableReq: null,
            auditorCourseReq: null,
            countryStateId: 1,
            isRegistrationCompletedWithValidations: false,
            vcdFromEmailLink: splitted[1],
            codeFromEmailLink: splitted[0]
        }
        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url2}`,
            method: APICallMethods.POST,
            data: dataBody,
        }); //call the api using ApiCall method
        if (sessionStorage.getItem('tempUser')) {
            const loginRequest: loginRequest = {
                email: data.txtEmail ? data.txtEmail : "",
                password: data.txtPassword ? data.txtPassword : "",
            }
            const user = await SignIn(loginRequest)
            Promise.resolve(user).then(async (y) => {
                //eslint-disable-next-line
                //debugger
                if (y.user == undefined) {
                    ErrorToast(y.response.data.error.detail);
                } else {
                    const Auditor = await LoadAuditor(y)
                    Promise.resolve(Auditor).then((x) => {
                        setSessionCookie({...y.user, ...x, status:8})
                    })
                }
            })
            const session = getSessionCookie();
            console.log(result);
            if (result.auditorPersonalDetailsRes.auditorId > 0) {
                setSessionCookie({
                    "userName": session.userName,
                    "userDisplayName": session.userDisplayName,
                    "userRoleId": 3,
                    "userId": session.userId,
                    "auditorId": result.auditorPersonalDetailsRes.auditorId,
                    "lastLogin": Date.now().toString(),
                    status:8
                });
            }
        }
        return result.auditorPersonalDetailsRes.auditorId;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const AddOrUpdateAuditorProfile = async (data: PersonalDetailsValueProps, bData: BusinessDetailsValueProps, qData: QualificationValueProps, rData: AuditorRegionValueProps, isRegistration?: boolean, cData?: RoadSafetyCoursesProps[]) => {
    try {
        //Url
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorRegistration/RegisterAuditor`; //complete url

        const session = getSessionCookie();

        //Auditor Personal Details
        //region
        const personalDetails: ContactDetailsReq = {
            auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId,
            titleId: parseInt(data.txtTitle ? data.txtTitle : "0"),
            firstName: data.txtFirstName ? data.txtFirstName : "",
            lastName: data.txtLastName ? data.txtLastName : "",
            email: data.txtEmail ? data.txtEmail : "",
            middleName: data.txtMiddleName ? data.txtMiddleName : "",
            applyingLevel: parseInt(data.txtApplyLevel ? data.txtApplyLevel : ""),
            password: data.txtPassword ? data.txtPassword : "",
            countryStateId: 1
        }; //body data
        //endregion

        //Auditor Business Details
        //region
        const businessDetails: BusinessDetailsReq = {
            homePhone: bData.txtHomePhone ? bData.txtHomePhone : "",
            mobile: bData.txtMobilePhone ? bData.txtMobilePhone : "",
            orgAddress: bData.txtOfficeAddress ? bData.txtOfficeAddress : "",
            orgBranch: bData.txtBranchName ? bData.txtBranchName : "",
            orgCountryId: parseInt(bData.txtCountry ? bData.txtCountry : "1"),
            orgName: bData.txtCompanyName ? bData.txtCompanyName : "",
            orgOther: "",
            orgPosition: bData.txtDesignation ? bData.txtDesignation : "",
            orgPostcode: bData.txtPostcode ? bData.txtPostcode : "",
            orgStateId: parseInt(bData.txtState ? bData.txtState : "0"),
            orgSuburb: bData.txtSuburb ? bData.txtSuburb : "",
            personalEmail: bData.txtPersonalEmail ? bData.txtPersonalEmail : "",
            preferContactMethod: bData.txtPreferredContact ? bData.txtPreferredContact : [],
            skype: bData.txtSkypeId ? bData.txtSkypeId : "",
            workEmail: bData.txtWorkEmail ? bData.txtWorkEmail : "",
            workPhone: bData.txtOfficePhone ? bData.txtOfficePhone : "",
            auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId
        };
        //endregion

        //Auditor Qualification Data
        //region

        //eslint-disable-next-line
        let selectedExpertise: number[] = []
        if (qData.constructionProject) {
            selectedExpertise.push(1)
        }
        if (qData.reconstructionProjects) {
            selectedExpertise.push(2)
        }
        if (qData.intersectionProjects) {
            selectedExpertise.push(3)
        }
        if (qData.localAreaTrafficManagementProjects) {
            selectedExpertise.push(4)
        }
        if (qData.vulnerableRoadUser) {
            selectedExpertise.push(5)
        }
        if (qData.trafficSignalProjects) {
            selectedExpertise.push(6)
        }
        if (qData.temporaryTrafficManagement) {
            selectedExpertise.push(7)
        }
        if (qData.developmentPlanning) {
            selectedExpertise.push(8)
        }
        if (qData.designConstructionProjects) {
            selectedExpertise.push(9)
        }
        if (qData.crashInvestigation) {
            selectedExpertise.push(10)
        }
        const expData: AddQulification = {
            bsSummary: qData.bsSummary ? qData.bsSummary : "",
            rdSummary: qData.rdSummary ? qData.rdSummary : "",
            rseSummary: qData.rseSummary ? qData.rseSummary : "",
            trSummary: qData.trSummary ? qData.trSummary : "",
            ttmSummary: qData.ttmSummary ? qData.ttmSummary : "",
            auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId,
            totalYearsExperience: parseInt(qData.totalExperience ? qData.totalExperience : "0"),
            roadDesign: parseInt(qData.roadDesign ? qData.roadDesign : '0'),
            trafficEngineering: parseInt(qData.trafficEngineering ? qData.trafficEngineering : '0'),
            trafficTransportManagement: parseInt(qData.trafficTransportManagement ? qData.trafficTransportManagement : '0'),
            roadSafetyEngineering: parseInt(qData.roadSafetyEngineering ? qData.roadSafetyEngineering : '0'),
            behaviouralScience: parseInt(qData.behaviouralScience ? qData.behaviouralScience : '0'),
            workExperienceSummary: qData.workExperienceSummary ? qData.workExperienceSummary : '',
            otherRelevantExperience: qData.otherRelevantExperience ? qData.otherRelevantExperience : '',
            qualifications: qData.qualifications ? qData.qualifications : '',
            experienceSkillsSummary: qData.experienceSkillsSummary ? qData.experienceSkillsSummary : '',
            mitigation: qData.mitigation ? qData.mitigation : '',
            eligibilityCriteriaExperienceId: qData.eligibilityCriteria ? parseInt(qData.eligibilityCriteria) : 0,
            selectedAuditorExpertiseIds: selectedExpertise
        };
        //endregion

        //Auditor Region Data
        //region
        //eslint-disable-next-line
        let selectedRegion: number[] = [];

        if (rData.greateSyndey == true) {
            selectedRegion.push(1);
        }
        if (rData.north == true) {
            selectedRegion.push(2);
        }
        if (rData.south == true) {
            selectedRegion.push(3);
        }
        if (rData.west == true) {
            selectedRegion.push(4);
        }
        if (rData.vic == true) {
            selectedRegion.push(5);
        }
        if (rData.wa == true) {
            selectedRegion.push(6);
        }
        if (rData.act == true) {
            selectedRegion.push(7);
        }
        if (rData.qld == true) {
            selectedRegion.push(8);
        }
        if (rData.sa == true) {
            selectedRegion.push(9);
        }
        if (rData.tas == true) {
            selectedRegion.push(10);
        }
        if (rData.nt == true) {
            selectedRegion.push(11);
        }
        if (rData.overseas == true) {
            selectedRegion.push(12);
        }

        //eslint-disable-next-line
        let leaveExtendReq: {
            auditorId: number;
            leaveStateDate: string; //new Date(r.leaveStateDate ? r.leaveStateDate : '1900/01/01'),
            leaveEndDate: string;
        }[] = []
        rData.leaveExtend?.map(r => {
            leaveExtendReq.push({
                auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId,
                leaveStateDate: r.leaveStateDate ? moment(r.leaveStateDate).format('YYYY-MM-DD') : "1900-01-01",//new Date(r.leaveStateDate ? r.leaveStateDate : '1900/01/01'),
                leaveEndDate: r.leaveEndDate ? moment(r.leaveStateDate).format('YYYY-MM-DD') : "1900-01-01",
            })
        })

        const regionData: any = {
            auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId,
            selectedRegions: selectedRegion,
            auditorLeavesRes: leaveExtendReq

        };
        //endregion


        if (session.status != 8) {
            const personal = data.txtEmail ? await AddOrUpdateAuditorPersonalDetails(personalDetails) : null;
            const business = bData.txtAuditorId ?  await AddOrUpdateAuditorBusinessDetails(businessDetails) : null;
            const regions = rData.txtAuditorId ? await AddOrUpdateAuditorAvailability(regionData) : null;
            const expSection = qData.auditorId ?  await AddOrUpdateAuditorQualification(qData) : null;
            return 1
        } else {
            if (isRegistration == true) {
                //const auditor = await fetchAuditor(data.txtAuditorId ? data.txtAuditorId : session.auditorId)
                let courseReq = null
                if (cData) {
                    const courses = cData
                    for (let i = 0; i < courses.length; i++) {
                        if (courses[i].courseId > 0) {
                            const auditorCourseReq: AuditorCourseReq = {
                                auditorId: data.txtAuditorId ? data.txtAuditorId : session.auditorId,
                                courseId: courses[i].courseId,
                                certificateNumber: courses[i].certificateNumber ? courses[i].certificateNumber : "",
                                countryStateId: 1,
                                courseDate: courses[i].courseDate ? moment(courses[i].courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
                                courseName: courses[i].courseName ? courses[i].courseName : "",
                                coursePrividerEmail: courses[i].providerEmail ? courses[i].providerEmail : "",
                                courseProviderName: courses[i].courseProviderName ? courses[i].courseProviderName : "",
                                mappingAuditorCourseId: courses[i].id ? courses[i].id : 0,
                                location: courses[i].location ? courses[i].location : "",
                            }
                            courseReq = auditorCourseReq
                        }
                    }
                }

                if(courseReq == null || courseReq == undefined){
                    //ErrorToast('Please add exsisting course for submit registration !');
                    return;
                }

                const dataBody: object = {
                    auditorPersonalDetailsReq: personalDetails,
                    auditorBusinessDetailsReq: businessDetails,
                    auditorQualificationReq: expData,
                    auditorRegionsAvailableReq: regionData,
                    auditorCourseReq: courseReq,
                    countryStateId: 1,
                    isRegistrationCompletedWithValidations: isRegistration ? isRegistration : false,
                }
                //eslint-disable-next-line
                //debugger
                const result = await ApiCallAxio({
                    url: `${url}`,
                    method: APICallMethods.POST,
                    data: dataBody,
                }); //call the api using ApiCall method
                //const session = getSessionCookie();
                console.log(result);
                if (result.auditorPersonalDetailsRes.auditorId  > 0) {
                    const y = {
                        user:{
                            auditorId: result.auditorPersonalDetailsRes.auditorId
                        }
                    }
                    const Auditor = await LoadAuditor(y)
                    Promise.resolve(Auditor).then((x) => {
                        setSessionCookie({...session, ...x})
                    })
                    /*setSessionCookie({
                        "userName": session.userName,
                        "userDisplayName": session.userDisplayName,
                        "userRoleId": 3,
                        "userId": session.userId,
                        "auditorId": result.auditorPersonalDetailsRes.auditorId,
                        "lastLogin": '',
                        status:1,
                        statusName:"New Application"
                    });*/
                    window.location.href = '/welcome'
                }
                //return result.auditorPersonalDetailsRes.auditorId ? result.auditorPersonalDetailsRes.auditorId : 0;//return the array
            } else {
                const dataBody: object = {
                    auditorPersonalDetailsReq: personalDetails,
                    auditorBusinessDetailsReq: businessDetails,
                    auditorQualificationReq: expData,
                    auditorRegionsAvailableReq: regionData,
                    auditorCourseReq: null,
                    countryStateId: 1,
                    isRegistrationCompletedWithValidations: isRegistration ? isRegistration : false,
                }
                //eslint-disable-next-line
                //debugger
                const result = await ApiCallAxio({
                    url: `${url}`,
                    method: APICallMethods.POST,
                    data: dataBody,
                }); //call the api using ApiCall method
                return result.auditorPersonalDetailsRes.auditorId ? result.auditorPersonalDetailsRes.auditorId : 0;//return the array

            }
        }

    } catch (e) {
        console.log(e);
        throw e;
    }

}

const AddOrUpdateAuditorPersonalDetails = async (data: ContactDetailsReq ) => {
    try {
        //const url = `${baseUrl}/${sublink}/${version}/Auditor/businessdetails`; //complete url
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/UpdateAuditorPersonalDetails`; //complete url

        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data,
        }); //call the api using ApiCall method
        return result.auditorId;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AddOrUpdateAssessorToAuditor = async (data: AssessorAssignReq ) => {
    try {
        //const url = `${baseUrl}/${sublink}/${version}/Auditor/businessdetails`; //complete url
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AssignAssessors`; //complete url

        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data,
        }); //call the api using ApiCall method
        return result.assessors;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AddCommentToAssessior = async (data: AdminAddCommenttoAssessorReq ) => {
    try {
        //const url = `${baseUrl}/${sublink}/${version}/Auditor/businessdetails`; //complete url
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AdminAddCommenttoAssessor`; //complete url

        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data,
        }); //call the api using ApiCall method
        return result.assessors;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AddLogfromAdmin = async ( auditorId:number, logDescription:string ) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/AddLogfromAdmin`; //complete url

        const data = {
            auditorId: auditorId,
            logDescription: logDescription
        }

        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data,
        }); //call the api using ApiCall method
        return result.assessors;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AddOrUpdateAuditorBusinessDetails = async (data: BusinessDetailsReq) => {
    try {
        //const url = `${baseUrl}/${sublink}/${version}/Auditor/businessdetails`; //complete url
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/UpdateAuditorBusinessDetails`; //complete url

        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data,
        }); //call the api using ApiCall method
        return result.auditorBusinessDetailsRes.auditorId;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AddOrUpdateAuditorAvailability = async (data: any) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/CreateOrUpdateAuditorRegionsAvailable`; //complete url Auditor/regions

    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: data
    }); //call the api using ApiCall method
    return result;//return the array
}

const AddOrUpdateAuditorQualification = async (data: QualificationValueProps) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/CreateOrUpdateAuditorExperiencesAndExpertises`; //complete url Auditor/qualification
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const session = getSessionCookie();
    //eslint-disable-next-line
    let selectedExpertise: number[] = []
    if (data.constructionProject) {
        selectedExpertise.push(1)
    }
    if (data.reconstructionProjects) {
        selectedExpertise.push(2)
    }
    if (data.intersectionProjects) {
        selectedExpertise.push(3)
    }
    if (data.localAreaTrafficManagementProjects) {
        selectedExpertise.push(4)
    }
    if (data.vulnerableRoadUser) {
        selectedExpertise.push(5)
    }
    if (data.trafficSignalProjects) {
        selectedExpertise.push(6)
    }
    if (data.temporaryTrafficManagement) {
        selectedExpertise.push(7)
    }
    if (data.developmentPlanning) {
        selectedExpertise.push(8)
    }
    if (data.designConstructionProjects) {
        selectedExpertise.push(9)
    }
    if (data.crashInvestigation) {
        selectedExpertise.push(10)
    }
    const expData: AddQulification = {
        bsSummary: data.bsSummary ? data.bsSummary : "",
        rdSummary: data.rdSummary ? data.rdSummary : "",
        rseSummary: data.rseSummary ? data.rseSummary : "",
        trSummary: data.trSummary ? data.trSummary : "",
        ttmSummary: data.ttmSummary ? data.ttmSummary : "",
        auditorId: data.auditorId ? data.auditorId : session.auditorId,
        totalYearsExperience: parseInt(data.totalExperience ? data.totalExperience : "0"),
        roadDesign: parseInt(data.roadDesign ? data.roadDesign : '0'),
        trafficEngineering: parseInt(data.trafficEngineering ? data.trafficEngineering : '0'),
        trafficTransportManagement: parseInt(data.trafficTransportManagement ? data.trafficTransportManagement : '0'),
        roadSafetyEngineering: parseInt(data.roadSafetyEngineering ? data.roadSafetyEngineering : '0'),
        behaviouralScience: parseInt(data.behaviouralScience ? data.behaviouralScience : '0'),
        workExperienceSummary: data.workExperienceSummary ? data.workExperienceSummary : '',
        otherRelevantExperience: data.otherRelevantExperience ? data.otherRelevantExperience : '',
        qualifications: data.qualifications ? data.qualifications : '',
        experienceSkillsSummary: data.experienceSkillsSummary ? data.experienceSkillsSummary : '',
        mitigation: data.mitigation ? data.mitigation : '',
        eligibilityCriteriaExperienceId: data.eligibilityCriteria ? parseInt(data.eligibilityCriteria) : 0,
        selectedAuditorExpertiseIds: selectedExpertise
    }; //body data
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: expData,
    }); //call the api using ApiCall method
    return result;//return the array
}

const AddNewCoursetoDB = async (data: RoadSafetyCoursesProps) => {
    //const url = `${baseUrl}/${sublink}/${version}/Courses`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/CreateOrUpdateAuditorCourse`; //complete url
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const session = getSessionCookie();
    const courseDate: AddCourseReq = {
        auditorId: data.auditorId == 0 ? session.auditorId : data.auditorId,
        courseId: data.courseId,
        courseName: data.courseName ? data.courseName : "",
        courseProviderId: data.courseProvider ? parseInt(data.courseProvider) : 0,
        courseLocation: data.location ? data.location : "",
        courseDate: data.courseDate ? moment(data.courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
        isActivated: false,
        flag: "",
        courseReferenceId: data.certificateNumber ? data.certificateNumber : "",
        courseStartDate: data.courseDate ? moment(data.courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
        courseEndDate: data.courseDate ? moment(data.courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
        courseGroup: 0,
        certificateNumber: data.certificateNumber ? data.certificateNumber : "",
        providerEmail: data.providerEmail ? data.providerEmail : "",
        courseProviderName: data.courseProviderName ? data.courseProviderName : "",
    }; //body data

    const CourseData = {
        mappingAuditorCourseId: 0,
        courseId: data.courseId,
        auditorId: data.auditorId == 0 ? session.auditorId : data.auditorId,
        countryStateId: 1,
        courseProviderName: data.courseProviderName ? data.courseProviderName : "",
        courseName: data.courseName ? data.courseName : "",
        location: data.location ? data.location : "",
        coursePrividerEmail: data.providerEmail ? data.providerEmail : "",
        certificateNumber: data.certificateNumber ? data.certificateNumber : "",
        courseDate: data.courseDate ? moment(data.courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
    }
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: CourseData,
    }); //call the api using ApiCall method
    return result;//return the array
}

const AddNewAudittoDB = async (data: RoadSafetyAuditProps) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/CreateOrUpdateAuditWithParticipants`; //complete url
    const session = getSessionCookie();
    const auditData: AddAuditReq = {
        auditorId: data.auditorId ? data.auditorId : session.auditorId,
        auditStatusId: data.auditStatusId ? data.auditStatusId : 1,
        auditFinalSignoffDate: data.finalSignoffDate ? moment(data.finalSignoffDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "1900-01-01",
        auditTitle: data.titleOfAudit ? data.titleOfAudit : "",
        auditLocationDescription: data.locationOfAudit ? data.locationOfAudit : "",
        auditLocationStateId: data.stateAuditPerformed ? parseInt(data.stateAuditPerformed) : 1,
        auditClientCompany: data.clientCompany ? data.clientCompany : "",
        auditContactPerson: data.clientContactPerson ? data.clientContactPerson : "",
        auditContactPhone: data.clientContactNumber ? data.clientContactNumber : "",
        auditContactMobile: data.clientMobileNumber ? data.clientMobileNumber : "",
        auditContactEmail: data.clientContactEmail ? data.clientContactEmail : "",
        stageId: data.stageOfAudit ? parseInt(data.stageOfAudit) : 0,
        typeId: 0,
        isTeamLeader: false,
        teamLeaderName: "",
        isPubliclyVisible: false,
        projectDesc: data.projectDescription ? data.projectDescription : "",
        auditPurposeScope: data.purposeOfAudit ? data.purposeOfAudit : "",
        isTfNswProject: data.isTfNSW ? data.isTfNSW : false,
        countryStateId: data.stateAuditPerformed ? parseInt(data.stateAuditPerformed) : 1,
        lstAuditParticipantsReq: data.lstAuditParticipantsReq ? data.lstAuditParticipantsReq : []
    }; //body data
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(auditData)
    }); //call the api using ApiCall method
    return result;//return the array
}

//eslint-disable-next-line
const AddMetoAudit = async (auditorId: any, auditId: any) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/addme`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/CreateOrUpdateAuditorAudit`; //complete url
    const session = getSessionCookie();
    const auditData: object = {
        auditId: auditId,
        lstAuditParticipantsReq: [
            {
                auditorId: auditorId != undefined && auditorId > 0 ? auditorId : session.auditorId,
                auditorFullName: session.userDisplayName,
                isConfirmed: false,
                isLeadAuditor: false
            }
        ]
    }
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: auditData,
    }); //call the api using ApiCall method
    return result;//return the array
}

//eslint-disable-next-line
const RemoveMefromAudit = async (auditorId: any, auditId: any) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/removeme`; //complete url
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const session = getSessionCookie();
    const auditData: object = {
        "auditorId": auditorId != undefined && auditorId > 0 ? auditorId : session.auditorId,
        "auditId": auditId
    }; //body data
    const result = JSON.parse(await ApiCall({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(auditData),
        headers: headers
    })); //call the api using ApiCall method
    return result;//return the array
}

//eslint-disable-next-line
const RemoveMefromCourse = async (mapID: any) => {
    //eslint-disable-next-line
    //debugger
    //const url = `${baseUrl}/${sublink}/${version}/courses/delete/${mapID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/DeleteAuditorCourse?mappingAuditorCourseId=${mapID}`; //complete url
    const auditData: object = {
        "MapId": mapID
    }; //body data
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        //data: JSON.stringify(auditData),
    }); //call the api using ApiCall method
    return result;//return the array
}

const VerifyCourse = async (mapID: any, isVerify:boolean) => {
    //eslint-disable-next-line
    //debugger
    //const url = `${baseUrl}/${sublink}/${version}/courses/delete/${mapID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/VerifyAuditorCourse?mappingAuditorCourseId=${mapID}&isVerify=${isVerify}`; //complete url

    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        //data: JSON.stringify(auditData),
    }); //call the api using ApiCall method
    return result;//return the array
}

//eslint-disable-next-line
const SubmitAudit = async (auditorId: any, countryStateId: any, auditorLevel: any, auditorStatus: any) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorRegistration/submit`; //complete url
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const session = getSessionCookie();
    const auditData: object = {
        "auditorId": auditorId != undefined && auditorId > 0 ? auditorId : session.auditorId,
        "countryStateId": countryStateId,
        "auditorLevel": auditorLevel,
        "auditorStatus": auditorStatus
    }; //body data
    const result = JSON.parse(await ApiCall({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(auditData),
        headers: headers
    })); //call the api using ApiCall method
    return result;//return the array
}

const UpdateMsgFromAdmin = async (Id: number, IsConfirm: boolean, sectionName: string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/InfoToggles/CreateOrUpdateAuditorResponsesToActivatedMsgs`; //complete url
    // const url = `${baseUrl}/${sublink}/${version}/Auditor/msgfromadmin`; //complete url
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')

    const Data: object = {
        auditorId: encid ? parseInt(atob(encid)) : session.auditorId,
        countryStateId: 1,
        lstInfoToggleSections: [
            {
                requestedMsgId: Id,
                sectionName: sectionName,
                isConfirmedByAuditor: IsConfirm
            }
        ],
        isSaveAsDraft: false
    }; //body data
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: Data,
    }); //call the api using ApiCall method
    if (result.success) {
        successToast(result.warning.length > 0 ? result.warning : "Update Success !")
    }
    return result;//return the array
}

const CreateOrUpdateMsgFromAdmin = async ( auditorId: number, sectionName: string,isActivated:boolean, msgFromAdmin:string, Id?: number ) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/InfoToggles/CreateOrUpdateAdminInfoToggle`; //complete url
    const session = getSessionCookie();

    const Data: object = {
        id: Id,
        auditorId: auditorId,
        sectionName: sectionName,
        isActivated: isActivated,
        userId: parseInt(session.userId),
        msgFromAdmin: msgFromAdmin
    }; //body data
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: Data,
    }); //call the api using ApiCall method
    return result;//return the array
}

const AdminOpenNewApplication = async (auditorId: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AdminOpenNewApplication?AuditorId=${auditorId}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const intStatue: number = result.auditor; //store the result in userTitles array

    return intStatue;//return the array
}

const AdminAuditorStatusChange = async (auditorId: number, StatusId:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AdminAuditorStatusChange?AuditorId=${auditorId}&Status=${StatusId}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const intStatue: number = result.auditor; //store the result in userTitles array

    return intStatue;//return the array
}

const Auditor7YrsExpExceptionSave = async (auditorId: number, updatorId:number, Note?:string, sevenYearsExp?: boolean) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/Auditor7YrsExpException`; //complete url
    const data = {
        "auditorId": auditorId,
        "note": Note,
        "updatorId": updatorId,
        "sevenYearsExp": sevenYearsExp
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:data}); //call the api using ApiCall method
    const intStatue:auditor7YrsExpException = result.auditor7YrsExpException; //store the result in userTitles array

    return intStatue;//return the array
}

const AddAuditLogs = async (AuditId:number, Desc:string) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAuditLogsById`; //complete urly data

        const data = {
            "auditId": AuditId,
            "description": Desc
        }
        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data:data
        }); //call the api using ApiCall method

        const AuditLogs:IAuditLogs[] = result.auditRes
        return AuditLogs;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const SendEmailToAuditor = async (AuditId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/InfoToggles/SaveAdminMsgToAuditorAndMailToAuditor?auditorId=${AuditId}&countryStateId=${1}`; //complete urly data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
        }); //call the api using ApiCall method

        const AuditLogs:object= result.msgToAdmin
        return AuditLogs;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}


export {
    fetchAuditor
    , fetchAuditorsByName
    , fetchAuditorAudit
    , fetchAuditorAuditAll
    , fetchAuthRegi
    , fetchTitle
    , fetchAdminAddComment
    , fetchAssessors
    , GetAdminNotifications
    , GetAuditLogs
    , fetchAuditorStatus
    , fetchAssignAssessors
    , fetchContactMethod
    , fetchCourseProviders
    , fetchCountries
    , fetchCompanies
    , fetchCourses
    , fetchCoursesWithProviders
    , fetchStates
    , fetchAuditStages
    , fetchAudits
    , fetchAllActiveAudits
    , fetchSingleAudit
    , fetchSingleCourse
    , fetchAuditor7YrsExpException
    , AddNewCoursetoDB
    , AddLogfromAdmin
    , AddNewAudittoDB
    , AddOrUpdateAuditorDetails
    , AddOrUpdateAuditorProfile
    , AddOrUpdateAssessorToAuditor
    , AddOrUpdateAuditorBusinessDetails
    , AddOrUpdateAuditorAvailability
    , AddOrUpdateAuditorQualification
    , AddMetoAudit
    , RemoveMefromAudit
    , RemoveMefromCourse
    , VerifyCourse
    , SubmitAudit
    , UpdateMsgFromAdmin
    , fetchSingleCourseUsingMID
    , CreateOrUpdateMsgFromAdmin
    , AddCommentToAssessior
    , AdminOpenNewApplication
    , AdminAuditorStatusChange
    , Auditor7YrsExpExceptionSave
    , AddAuditLogs
    , SendEmailToAuditor
};