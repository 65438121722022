

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";
import {AuditorByCondition} from "../../../../models/apiResponce/Auditor/AuditorByCondition";
import moment from "moment";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchAuditors = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `fromDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `toDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorInfoByDateRange${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCount: string = result.auditorInfoByDateRange; //store the result in userTitles array
    return objCount;//return the array
}

//to get the list of all the titles.
const fetchAuditorsByCondition = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/GetAuditorsByCondition`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCount: AuditorByCondition = result; //store the result in userTitles array
    return objCount;//return the array
}

//to get the list of all the titles.
const fetchAudits = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `fromDate=${moment(fromDate).format("DD-MM-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `toDate=${moment(toDate).format("DD-MM-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/auditsbydaterange${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCount: string = result.auditsByDateRange; //store the result in userTitles array
    return objCount;//return the array
}

export {fetchAuditors, fetchAuditorsByCondition, fetchAudits};