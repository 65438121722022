import React, {useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import moment from "moment";
import { fetchMessageCountReport } from "./MessageCountController";

const MessageCountContainer = () =>{
    const session = getSessionCookie();
    const [dateRange, setDateRange] = useState('')

    const handleVisitOnSearchPageReport = async() => {
    
        const dates = dateRange.split('-')
        const startDate = moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
        const endDate = moment(moment(dates[1], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
        const report = await fetchMessageCountReport(startDate, endDate)
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Auditor Message Count - (${moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }


    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Messages from Visitors"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p>Please specify date ranges and click on &apos;Export&apos; to get the list of message count for each auditors within the date range.</p>
                        <p>Please leave &apos;From&apos; date and &apos;To&apos; date blank and click on &apos;Export&apos;, to get the list of message count for each auditors in the registry.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        <DateRangeBox
                                            id={'txtFromDate'}
                                            fluid={true}
                                            placeholder={'From Date - To Date'}
                                            onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                            value={dateRange}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '200px', height:'36px'}} onClick={(e:any) => {
                                            handleVisitOnSearchPageReport()
                                    }} color={'blue'} text={'Export'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default MessageCountContainer
