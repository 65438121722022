import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import Position from "../../../../framework/types/Position";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import {Pagination, PaginationProps} from "antd";
import {ProfileLogRes} from "../../../../models/apiResponce/Auditor/ProfileLogRes";
import {fetchAuditorsLog} from "./ProfileLogController";

const ProfileLogContainer = () =>{
    const session = getSessionCookie();
    const AuditorId = new URLSearchParams(location.search).get('AuditorId');
    const name = new URLSearchParams(location.search).get('name');
    const[txtName, setTxtName] = useState('')
    const [current, setCurrent] = useState(1);
    const [data, setData] = useState<any>([]);
    const [recoord, setRecord] = useState(0)

    useEffect(()=>{
        if(name){
            setTxtName(name)
        }
        if(AuditorId){
            const searchData = fetchAuditorsLog(parseInt(AuditorId ? AuditorId : '0'), 1)
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    SetDataTable(value.objProfileLogRes)
                    setRecord(value.totalRecords)
                    setCurrent(1)
                }
            })
        }
    },[])

    const SetDataTable = (rawData: ProfileLogRes[]) => {
        //eslint-disable-next-line
        let temSearch: TableRowProps[] = []
        rawData.map((r,index) => {
            temSearch.push({
                key: index,
                date:r.date,
                oldLevel: r.oldLevel ? r.oldLevel : '',
                newLevel: r.newLevel ,
                oldStatus:r.oldStatus,
                newStatus:r.newStatus,
                description:r.description,
                by:r.by ? r.by : ''
            })
        })
        setData(temSearch);
    }

    const OnPageChange: PaginationProps['onChange'] = (Page) => {
        const searchData = fetchAuditorsLog(parseInt(AuditorId ? AuditorId : '0'), Page)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                SetDataTable(value.objProfileLogRes)
                setRecord(value.totalRecords)
                setCurrent(Page)
            }
        })
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Auditor's Profile Alteration Transaction"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Please provide Auditor&apos;s name in textbox below to retrieve his/her profile alteration log.</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        <NameWithDefault
                                            labelText={'Auditor Name'}
                                            fluid={true}
                                            labelPosition={Position.Left}
                                            id={'txtAuditorName'}
                                            type={'text'}
                                            required={false}
                                            placeholder={'Enter Auditor Name'}
                                            value={txtName}
                                            //onError={clientContactPersonError}
                                            //eslint-disable-next-line
                                            onChange={(e: any) => {
                                                e.preventDefault();
                                                setTxtName(e.target.value)
                                            }}
                                            // disabled={isEditCourse}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                        <OutLineButton id={'btnSearch'} className={"background-transaparent"}
                                                       style={{width: '150px', height:'36px'}} onClick={(e:any) => {
                                            window.location.href = `/search?profilelog=true&name=${txtName}`
                                        }} color={'blue'} text={'Search'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        {data.length > 0 ?(<Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>Old level</Table.HeaderCell>
                                    <Table.HeaderCell>New level</Table.HeaderCell>
                                    <Table.HeaderCell>Old status</Table.HeaderCell>
                                    <Table.HeaderCell>New status</Table.HeaderCell>
                                    <Table.HeaderCell>Description</Table.HeaderCell>
                                    <Table.HeaderCell>By</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data.map((item: TableRowProps, index: number) => (
                                    <TableRow
                                        key={index}
                                       date={item.date}
                                        by={item.by}
                                        newLevel={item.newLevel}
                                        description={item.description}
                                        newStatus={item.newStatus}
                                        oldLevel={item.oldLevel}
                                        oldStatus={item.oldStatus}
                                    />
                                ))}
                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell colSpan='2' textAlign={'right'}>
                                        <Pagination current={current} onChange={OnPageChange} total={recoord - 1}
                                                    pageSize={10} showSizeChanger={false}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>) : null }
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default ProfileLogContainer


interface TableRowProps {
    key:number,
    date:string,
    oldLevel:string,
    newLevel:string,
    oldStatus:string,
    newStatus:string,
    description:string,
    by:string
}

const TableRow = (props: TableRowProps) => {
    return (
        <Table.Row key={props.key}>
            <Table.Cell>{props.date}</Table.Cell>
            <Table.Cell>{props.oldLevel}</Table.Cell>
            <Table.Cell>{props.newLevel}</Table.Cell>
            <Table.Cell>{props.oldStatus}</Table.Cell>
            <Table.Cell>{props.newStatus}</Table.Cell>
            <Table.Cell>{props.description}</Table.Cell>
            <Table.Cell>{props.by}</Table.Cell>
        </Table.Row>
    );
}