import React, {useEffect, useState} from "react";

import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import ContactDetails from "./ContactDetails";
import QualificationsAndExperience from "./QualificationsAndExperience";
import RoadSafetyCourses from "./RoadSafetyCourses";
import RoadSafetyAudits from "./RoadSafetyAudits";
import Disclaimer from "./Disclaimer";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import {
    AuditorRegionValueProps,
    BusinessDetailsErrorProps,
    BusinessDetailsValueProps,
    PersonalDetailsErrorProps,
    PersonalDetailsValueProps,
    QualificationErrorProps,
    QualificationValueProps,
    RoadSafetyAuditProps,
    RoadSafetyCoursesProps
} from "./DataModal";
import {

    AddOrUpdateAuditorDetails,
    AddOrUpdateAuditorProfile, AdminAuditorStatusChange, AdminOpenNewApplication,
    CreateOrUpdateMsgFromAdmin,
    fetchAuditor, SendEmailToAuditor,
} from "./ProfileController";
import Modals from "../../../framework/components/Modals";
import InfoToast from "../../../framework/components/Toast/InfoToast";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import Validations from "../../../framework/common/Validations";
import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../../../framework/types/ValidationProps";
import ValidationRuleType from "../../../framework/types/ValidationRuleType";
import userRoleSelector from "../../../framework/common/UserSelector";
import errorToast from "../../../framework/components/Toast/ErrorToast";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import SuccessToast from "../../../framework/components/Toast/SuccessToast";

import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import AdminLog from "./AdminLog";
import SuperAdminNotes from "./SuperAdminNotes";

interface Props {
    //eslint-disable-next-line
    title: any;
    //eslint-disable-next-line
    provider: any;
    //eslint-disable-next-line
    country: any;
    //eslint-disable-next-line
    company: any;
    //eslint-disable-next-line
    course: any;
    //eslint-disable-next-line
    session: any;
    //eslint-disable-next-line
    states: any;
    //eslint-disable-next-line
    contactMethods: any;
    //eslint-disable-next-line
    auditStages: any;
    //eslint-disable-next-line
    auditor: any;
    //eslint-disable-next-line
    setAuditor: any;
}

const ProfileContainer = (props: Props) => {
    const session = getSessionCookie();
    setTimeout(() => {

        if (session == undefined) {
            window.location.href = '/'
        }
    }, 2000);


    useEffect(() => {
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            if (value.personalDetails.txtPassword != "") {
                setProfile(true)
            }

            setStateCourse(value.roadSafetyCourses)
            setStateAuditor(value.personalDetails)
            setStateBusiness(value.businessDetails)
            setStateRegion(value.auditorRegion)
            setStateExperience(value.qualification)
        })
    }, [props.auditor])

    const [loading, setLoading] = useState(false);
    const [errorPop, setErrorPop] = useState(false);

    const [focusMode, setFocusMode] = useState('pending');

    const [stateAuditor, setStateAuditor] = React.useState<PersonalDetailsValueProps>();
    const [stateBusiness, setStateBusiness] = React.useState<BusinessDetailsValueProps>();
    const [stateRegion, setStateRegion] = React.useState<AuditorRegionValueProps>();
    const [stateExperience, setStateExperience] = React.useState<QualificationValueProps>();
    const [stateCourse, setStateCourse] = React.useState<RoadSafetyCoursesProps[]>();
    const [stateAudits, setStateAudits] = React.useState<RoadSafetyAuditProps[]>();
    const [rsc, setRSC] = useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [rsa, setRSA] = useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [qe, setQE] = React.useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [bd, setBD] = useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [ad, setAD] = useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [ava, setAVA] = useState<MsgFromAdmin>({} as MsgFromAdmin)

    const [confirmPw, setConfirmPW] = React.useState('')
    const [profile, setProfile] = React.useState(false)
    let saveDraftClick = false
    const [PersonalError, setError] = React.useState({} as PersonalDetailsErrorProps)
    const [BusinessError, setBError] = React.useState({} as BusinessDetailsErrorProps)
    const [QualificationError, setQError] = React.useState({} as QualificationErrorProps)
    const [Status, setStatus] = React.useState(0)
    //eslint-disable-next-line
    let failedResult: ValidationResponseProps[] = []
    // eslint-disable-next-line
    let errorPersonalDetails: PersonalDetailsErrorProps = {} as PersonalDetailsErrorProps
    // eslint-disable-next-line
    let errorBusinessDetails: BusinessDetailsErrorProps = {} as BusinessDetailsErrorProps
    // eslint-disable-next-line
    let errorQualificationDetails: QualificationErrorProps = {} as QualificationErrorProps
    const [errorList, setErrorList] = React.useState<ValidationResponseProps[]>([{}] as ValidationResponseProps[])
    const [chkAgree, setAgree] = React.useState(false)
    let saveOngoing = false
    const [statChange, setStatChange] = React.useState(false)
    const pathName = window.location.pathname;
    const encid = new URLSearchParams(location.search).get('get')
    const errorPopUrl = new URLSearchParams(location.search).get('error')
    const stage = new URLSearchParams(location.search).get('stage')

    // eslint-disable-line
    const TimeBase = window.config;
    // const Autos-aveTime: number = TimeBase.AutoSaveTime * 1000
    // //console.log(TimeBase.TimeBaseAutoSave)
    useEffect(() => {
        try {
            //eslint-disable-next-line
            //debugger;
            if (TimeBase.TimeBaseAutoSave == true) {
                return
            }
            if (focusMode != "pending") {
                DynamicSave();

            }
        } catch (e) {
            console.log(e);
        }

    }, [focusMode])

    useEffect(() => {
        console.log('personal Details', stateAuditor)
    }, [stateAuditor])

    // useEffect(()=>{
    //     if(errorPopUrl && errorPopUrl == '1' && stateExperience != undefined){
    //         setQError({} as QualificationErrorProps)
    //         if (!ValidateDataforSubmit()) {
    //             //setErrorPop(true)
    //             return;
    //         }
    //     }
    // },[stateExperiance])

    useEffect(() => {
        if(stateExperience != undefined && stateAuditor != undefined && stateBusiness != undefined && stateRegion != undefined && stateCourse != undefined){
            if (ValidateDataforSubmit()) {
                if(props.session.userRoleId == 1 || props.session.userRoleId == 4 ){
                    AdminOpenNewApplication(parseInt(atob(encid ? encid : '0')))
                }

            }
        }
    }, [stateAuditor,stateBusiness,stateRegion,stateExperience, stateCourse]);

    useEffect(() => {
        if (TimeBase.TimeBaseAutoSave == false) {
            return
        }
        if (saveOngoing) {
            return
        }
        if (statChange) {
            return
        }
        if (focusMode == "audit" || focusMode == "course") {
            return;
        }
        DynamicSave();
        setStatus(stateAuditor?.AuditorsStateRes?.auditorStatusId ? stateAuditor?.AuditorsStateRes?.auditorStatusId : 0)
    }, [stateAuditor, stateBusiness, stateRegion, stateExperience]);

    const DynamicSave = () => {
        if (stateAuditor?.txtPassword != undefined && stateAuditor?.txtPassword.length > 0 && stateAuditor?.txtApplyLevel != undefined && stateAuditor?.txtApplyLevel.length > 0) {
            if (sessionStorage.getItem('tempUser')) {
                const personalDetails = AddOrUpdateAuditorDetails(stateAuditor ? stateAuditor : {} as PersonalDetailsValueProps);
                saveOngoing = true
                Promise.resolve(personalDetails).then((AuditorId) => {
                    //eslint-disable-next-line
                    if (sessionStorage.getItem('tempUser')) {
                        if (AuditorId) {
                            setProfile(true)
                            setStatChange(false)
                            TimeBase.DeveloperMode == true ? InfoToast("your profile was created") : null;
                        }
                        sessionStorage.clear();
                        saveOngoing = false
                    }
                });
            } else {
                ProfileSave();
            }
        } else if (stateAuditor?.txtPassword == "password") {
            ProfileSave();
        }
    }

    const ProfileSave = () => {
        const personal = stateAuditor ? stateAuditor : {} as PersonalDetailsValueProps
        const business = stateBusiness ? stateBusiness : {} as BusinessDetailsValueProps
        const region = stateRegion ? stateRegion : {} as AuditorRegionValueProps

        const exp = stateExperience ? stateExperience : {} as QualificationValueProps
        const SaveAuditor = AddOrUpdateAuditorProfile(personal, business, exp, region, false);
        saveOngoing = true
        Promise.resolve(SaveAuditor).then(() => {
            //eslint-disable-next-line
            setProfile(true)
            setStatChange(false)
            TimeBase.DeveloperMode == true ? InfoToast("Successfully updated.") : null;
            saveOngoing = false
            setLoading(false);

            if (saveDraftClick == true) {
                SuccessToast("Saved successfully as a Draft")
                saveDraftClick = false
            }
        });
    }

    const ValidateDataforSubmit = () => {
        let result = true;
        let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;
        errorQualificationDetails = {} as QualificationErrorProps
        //personal section

        if (pathName == '/profile') {
            if (stateAuditor != undefined) {
                //Title Validation
                if (stateAuditor.txtTitle != undefined) {
                    ValidateProps = {
                        value: stateAuditor.txtTitle,
                        fieldType: 'dropdown',
                        fieldName: 'Title',
                        required: true,
                        fieldId: 'txtTitle'
                    } as ValidationRequestProps
                    const titleVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!titleVal.Valid) {
                        result = false;
                        failedResult.push(titleVal)
                        setErrorList(failedResult)
                        errorPersonalDetails.txtTitle = titleVal.Msg
                        setError(errorPersonalDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Title is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtTitle = res.Msg
                    setError(errorPersonalDetails)
                }

                //First Name Validation
                if (stateAuditor.txtFirstName != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '30',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateAuditor.txtFirstName,
                        fieldType: 'text',
                        fieldName: 'First Name',
                        required: true,
                        rules: rule,
                        fieldId: 'txtFname'
                    } as ValidationRequestProps
                    const fNameVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!fNameVal.Valid) {
                        result = false;
                        failedResult.push(fNameVal)
                        setErrorList(failedResult)
                        errorPersonalDetails.txtFirstName = fNameVal.Msg
                        setError(errorPersonalDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'First Name is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtFirstName = res.Msg
                    setError(errorPersonalDetails)
                }

                //Last Name Validation
                if (stateAuditor.txtLastName != undefined) {

                    ValidateProps = {
                        value: stateAuditor.txtLastName,
                        fieldType: 'text',
                        fieldName: 'Last Name',
                        required: true,
                        fieldId: 'txtLname'
                    } as ValidationRequestProps
                    const lNameVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!lNameVal.Valid) {
                        result = false;
                        failedResult.push(lNameVal)
                        setErrorList(failedResult)
                        errorPersonalDetails.txtLastName = lNameVal.Msg
                        setError(errorPersonalDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Last Name is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtLastName = res.Msg
                    setError(errorPersonalDetails)
                }

                //Email Validation
                if (stateAuditor.txtEmail != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateAuditor.txtEmail,
                        rules: rule,
                        fieldType: 'email',
                        fieldName: 'Email (Username)',
                        required: true,
                        fieldId: 'txtEmail'
                    } as ValidationRequestProps
                    const emailVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!emailVal.Valid) {
                        result = false;
                        failedResult.push(emailVal)
                        setErrorList(failedResult)
                        errorPersonalDetails.txtEmail = emailVal.Msg
                        setError(errorPersonalDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Email (Username) is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtEmail = res.Msg
                    setError(errorPersonalDetails)
                }

                //Password Validation
                if (stateAuditor.txtPassword != undefined) {
                    const rule: ValidationRuleProps[] = [
                        {
                            Rule: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
                            Type: ValidationRuleType.REGEX,
                        },
                        {
                            Rule: `${stateAuditor.txtPassword.length} >= 8`,
                            Type: ValidationRuleType.CUSTOM,
                        }
                    ]
                    ValidateProps = {
                        value: stateAuditor.txtPassword,
                        rules: rule,
                        fieldType: 'password',
                        fieldName: 'Password',
                        required: true,
                        fieldId: 'txtPassword'
                    } as ValidationRequestProps
                    if (stateAuditor.txtPassword != 'password') {
                        const passwordVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!passwordVal.Valid) {
                            result = false;
                            failedResult.push(passwordVal)
                            setErrorList(failedResult)
                            errorPersonalDetails.txtPassword = passwordVal.Msg
                            setError(errorPersonalDetails)
                        }
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Password is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtPassword = res.Msg
                    setError(errorPersonalDetails)
                }

                //Confirm Password Validation
                if (stateAuditor.txtPassword != undefined) {
                    const rule: ValidationRuleProps[] = [
                        {
                            Rule: `${stateAuditor.txtPassword}`,
                            Type: ValidationRuleType.COMPARE,
                        }
                    ]
                    ValidateProps = {
                        value: confirmPw,
                        rules: rule,
                        fieldType: 'password',
                        fieldName: 'Confirm Password',
                        required: true,
                        fieldId: 'txtCPassword'
                    } as ValidationRequestProps
                    if (stateAuditor.txtPassword != 'password') {
                        const cpasswordVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!cpasswordVal.Valid) {
                            result = false;
                            failedResult.push(cpasswordVal)
                            setErrorList(failedResult)
                            errorPersonalDetails.txtConfirmPW = cpasswordVal.Msg
                            setError(errorPersonalDetails)
                        }
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Confirm Password is Required and need to match with Password'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtConfirmPW = res.Msg
                    setError(errorPersonalDetails)
                }

                //Apply Level Validation
                if (stateAuditor.txtApplyLevel != undefined) {
                    ValidateProps = {
                        value: stateAuditor.txtApplyLevel,
                        fieldType: 'dropdown',
                        fieldName: 'Apply Level',
                        required: true,
                        fieldId: 'txtApplyLevel'
                    } as ValidationRequestProps
                    const applyVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!applyVal.Valid) {
                        result = false;
                        failedResult.push(applyVal)
                        setErrorList(failedResult)
                        errorPersonalDetails.txtApplyLevel = applyVal.Msg
                        setError(errorPersonalDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Apply Level is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtApplyLevel = res.Msg
                    setError(errorPersonalDetails)
                }

            } else {
                if (pathName == '/profile') {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Please fill required fields in personal details'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorPersonalDetails.txtTitle = "Title is Required"
                    errorPersonalDetails.txtFirstName = "First Name is Required"
                    errorPersonalDetails.txtLastName = "Last Name is Required"
                    errorPersonalDetails.txtEmail = "Email (Username) is Required"
                    errorPersonalDetails.txtPassword = "Password is Required"
                    errorPersonalDetails.txtConfirmPW = "Confirm Password is Required and need to match with Password"
                    errorPersonalDetails.txtApplyLevel = "Apply Level is Required"
                    setError(errorPersonalDetails)
                }

            }

            //business details
            if (stateBusiness != undefined) {
                //Company Validation
                if (stateBusiness.txtCompanyName != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '50',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtCompanyName,
                        fieldType: 'dropdown',
                        rules: rule,
                        fieldName: 'Company Name',
                        required: true,
                        fieldId: 'txtCompanyName'
                    } as ValidationRequestProps
                    const txtCompanyNameVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtCompanyNameVal.Valid) {
                        result = false;
                        failedResult.push(txtCompanyNameVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtCompanyName = txtCompanyNameVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Company Name is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtCompanyName = res.Msg
                    setBError(errorBusinessDetails)
                }

                if (stateBusiness.txtDesignation != undefined) {

                    ValidateProps = {
                        value: stateBusiness.txtDesignation,
                        fieldType: 'text',
                        fieldName: 'Position',
                        required: true,
                        fieldId: 'txtDesignation'
                    } as ValidationRequestProps
                    const txtDesignationVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtDesignationVal.Valid) {
                        result = false;
                        failedResult.push(txtDesignationVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtDesignation = txtDesignationVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Position is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtDesignation = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Work Email Validation
                if (stateBusiness.txtWorkEmail != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtWorkEmail,
                        fieldType: 'email',
                        rules: rule,
                        fieldName: 'Work Email',
                        required: true,
                        fieldId: 'txtWorkEmail'
                    } as ValidationRequestProps
                    const txtWorkEmailVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtWorkEmailVal.Valid) {
                        result = false;
                        failedResult.push(txtWorkEmailVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtWorkEmail = txtWorkEmailVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Work Email is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtWorkEmail = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Prefer Contact Validation
                if (stateBusiness.txtPreferredContact != undefined && stateBusiness.txtPreferredContact != '') {

                    ValidateProps = {
                        value: stateBusiness.txtPreferredContact,
                        fieldType: 'dropdown',
                        fieldName: 'Preferred Contact Method',
                        required: true,
                        fieldId: 'txtPreferredContact'
                    } as ValidationRequestProps
                    const txtPreferredContactVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtPreferredContactVal.Valid) {
                        result = false;
                        failedResult.push(txtPreferredContactVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtPreferredContact = txtPreferredContactVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Preffered Contact is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtPreferredContact = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Personal Email Validation
                if (stateBusiness.txtPersonalEmail != undefined && stateBusiness.txtPersonalEmail != '') {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtPersonalEmail,
                        fieldType: 'email',
                        rules: rule,
                        fieldName: 'Personal Email',
                        required: true,
                        fieldId: 'txtPersonalEmail'
                    } as ValidationRequestProps
                    const txtPersonalEmailVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtPersonalEmailVal.Valid) {
                        result = false;
                        failedResult.push(txtPersonalEmailVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtPersonalEmail = txtPersonalEmailVal.Msg
                        setBError(errorBusinessDetails)
                    }
                }

                //Work Address Validation
                if (stateBusiness.txtOfficeAddress != undefined) {

                    ValidateProps = {
                        value: stateBusiness.txtOfficeAddress,
                        fieldType: 'text',
                        fieldName: 'Work Address',
                        required: true,
                        fieldId: 'txtOfficeAddress'
                    } as ValidationRequestProps
                    const txtOfficeAddressVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtOfficeAddressVal.Valid) {
                        result = false;
                        failedResult.push(txtOfficeAddressVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtOfficeAddress = txtOfficeAddressVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Work Address is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtOfficeAddress = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Suburb Validation
                if (stateBusiness.txtSuburb != undefined) {

                    ValidateProps = {
                        value: stateBusiness.txtSuburb,
                        fieldType: 'text',
                        fieldName: 'Suburb',
                        required: true,
                        fieldId: 'txtSuburb'
                    } as ValidationRequestProps
                    const txtSuburbVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtSuburbVal.Valid) {
                        result = false;
                        failedResult.push(txtSuburbVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtSuburb = txtSuburbVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Suburb is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtSuburb = res.Msg
                    setBError(errorBusinessDetails)
                }

                //State Validation
                if (stateBusiness.txtState != undefined) {

                    ValidateProps = {
                        value: stateBusiness.txtState,
                        fieldType: 'dropdown',
                        fieldName: 'State',
                        required: true,
                        fieldId: 'txtState'
                    } as ValidationRequestProps
                    const txtStateVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtStateVal.Valid) {
                        result = false;
                        failedResult.push(txtStateVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtState = txtStateVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'State is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtState = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Postcode Validation
                if (stateBusiness.txtPostcode != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '^\\d+$',
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtPostcode,
                        fieldType: 'email',
                        rules: rule,
                        fieldName: 'Postcode',
                        required: true,
                        fieldId: 'txtPostcode'
                    } as ValidationRequestProps
                    const txtPostcodeVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtPostcodeVal.Valid) {
                        result = false;
                        failedResult.push(txtPostcodeVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtPostcode = txtPostcodeVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Postcode is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtPostcode = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Office Phone Validation
                if (stateBusiness.txtOfficePhone != undefined && stateBusiness.txtOfficePhone != '') {
                    const regx = "^\\({0,1}((0|1|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{2,3}$"
                    const rule: ValidationRuleProps[] = [{
                        Rule: regx,
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtOfficePhone,
                        fieldType: 'phone',
                        rules: rule,
                        fieldName: 'Office Phone',
                        required: true,
                        fieldId: 'txtOfficePhone'
                    } as ValidationRequestProps
                    const txtOfficePhoneVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtOfficePhoneVal.Valid) {
                        result = false;
                        failedResult.push(txtOfficePhoneVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtOfficePhone = txtOfficePhoneVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Office Phone is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtOfficePhone = res.Msg
                    setBError(errorBusinessDetails)
                }

                //Home Phone Validation
                if (stateBusiness.txtHomePhone != undefined && stateBusiness.txtHomePhone != '') {
                    const regx = "^\\({0,1}((0|1|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{2,3}$"
                    //const regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
                    const rule: ValidationRuleProps[] = [{
                        Rule: regx,
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtHomePhone,
                        fieldType: 'phone',
                        rules: rule,
                        fieldName: 'Home Phone',
                        required: true,
                        fieldId: 'txtHomePhone'
                    } as ValidationRequestProps
                    const txtHomePhoneVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtHomePhoneVal.Valid) {
                        result = false;
                        failedResult.push(txtHomePhoneVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtHomePhone = txtHomePhoneVal.Msg
                        setBError(errorBusinessDetails)
                    }
                }
                // else {
                //     const res: ValidationResponseProps = {
                //         Valid: false,
                //         Msg: 'Home Phone is Required'
                //     } as ValidationResponseProps
                //     failedResult.push(res)
                //     result = false;
                //     setErrorList(failedResult)
                //     errorBusinessDetails.txtHomePhone = res.Msg
                //     setBError(errorBusinessDetails)
                // }

                //Mobile Phone Validation
                if (stateBusiness.txtMobilePhone != undefined) {
                    //const regx = "^(\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}|1300(\\ |-){0,1}[0-9]{3}(\\ |-){0,1}[0-9]{2})$"
                    const regx = "^\\({0,1}((0|1|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{2,3}$"
                    const rule: ValidationRuleProps[] = [{
                        Rule: regx,
                        Type: ValidationRuleType.REGEX,
                    }]
                    ValidateProps = {
                        value: stateBusiness.txtMobilePhone,
                        fieldType: 'phone',
                        rules: rule,
                        fieldName: 'Mobile Phone',
                        required: true,
                        fieldId: 'txtMobilePhone'
                    } as ValidationRequestProps
                    const txtMobilePhoneVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtMobilePhoneVal.Valid) {
                        result = false;
                        failedResult.push(txtMobilePhoneVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtMobilePhone = txtMobilePhoneVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    if (errorBusinessDetails.txtPreferredContact?.includes('1')) {
                        const res: ValidationResponseProps = {
                            Valid: false,
                            Msg: 'Mobile Phone is Required'
                        } as ValidationResponseProps
                        failedResult.push(res)
                        result = false;
                        setErrorList(failedResult)
                        errorBusinessDetails.txtMobilePhone = res.Msg
                        setBError(errorBusinessDetails)
                    }
                }

                //Country Validation
                if (stateBusiness.txtCountry != undefined) {

                    ValidateProps = {
                        value: stateBusiness.txtCountry,
                        fieldType: 'dropdown',
                        fieldName: 'State',
                        required: true,
                        fieldId: 'txtCountry'
                    } as ValidationRequestProps
                    const txtCountryVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtCountryVal.Valid) {
                        result = false;
                        failedResult.push(txtCountryVal)
                        setErrorList(failedResult)
                        errorBusinessDetails.txtCountry = txtCountryVal.Msg
                        setBError(errorBusinessDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Country is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtCountry = res.Msg
                    setBError(errorBusinessDetails)
                }

            } else {
                if (pathName == '/profile') {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Please fill required fields in business details'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorBusinessDetails.txtCompanyName = 'Company Name is Required'
                    errorBusinessDetails.txtWorkEmail = 'Work Email is Required'
                    errorBusinessDetails.txtPreferredContact = 'Preffered Contact is Required'
                    errorBusinessDetails.txtOfficeAddress = 'Work Address is Required '
                    errorBusinessDetails.txtSuburb = 'Suburb is Required '
                    errorBusinessDetails.txtState = 'State is Required '
                    errorBusinessDetails.txtPostcode = 'Postcode is Required '
                    errorBusinessDetails.txtCountry = 'Country is Required '
                    errorBusinessDetails.txtDesignation = 'Position is Required '
                    errorBusinessDetails.txtOfficePhone = 'Office Phone is Required '
                    //errorBusinessDetails.txtMobilePhone = 'Mobile Phone is Required '
                    errorBusinessDetails.txtHomePhone = 'Home Phone is Required '
                    setBError(errorBusinessDetails)
                }
            }
        }

        if (pathName == '/profile' && !(props.session.status == 3 || props.session.status == 4) || pathName == '/accriditation') {
            //qualification details
            if (stateExperience != undefined) {
                //Qualification Validation
                if (stateExperience.qualifications != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '100',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateExperience.qualifications,
                        fieldType: 'text',
                        fieldName: 'Qualifications',
                        required: true,
                        fieldId: 'qualifications',
                        rules: rule
                    } as ValidationRequestProps
                    const qualificationsVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!qualificationsVal.Valid) {
                        result = false;
                        failedResult.push(qualificationsVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.qualifications = qualificationsVal.Msg
                        setQError(errorQualificationDetails)
                    }
                }

                //work experience details
                if (stateExperience.workExperienceSummary != undefined && stateExperience.workExperienceSummary != '') {
                    console.log('WES', stateExperience.workExperienceSummary)
                    const rule: ValidationRuleProps[] = [{
                        Rule: '2000',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateExperience.workExperienceSummary,
                        fieldType: 'text',
                        fieldName: 'Work Experience Summary',
                        required: true,
                        fieldId: 'workExperienceSummary',
                        rules: rule
                    } as ValidationRequestProps
                    const workExperienceSummaryVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!workExperienceSummaryVal.Valid) {
                        result = false;
                        failedResult.push(workExperienceSummaryVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.workExperienceSummary = workExperienceSummaryVal.Msg
                        setQError(errorQualificationDetails)

                    }
                } else {
                    //console.log('WES', stateAuditor?.txtApplyLevel)
                    if (stateAuditor != undefined && (stateAuditor.txtApplyLevel == '1' || stateAuditor.txtApplyLevel == '2' || stateAuditor.txtApplyLevel == '3' || stage == '2' || stage == '3')) {
                        const res: ValidationResponseProps = {
                            Valid: false,
                            Msg: 'Work Experience Summary is Required for selected auditor level '
                        } as ValidationResponseProps
                        failedResult.push(res)
                        result = false;
                        setErrorList(failedResult)
                        errorQualificationDetails.workExperienceSummary = res.Msg
                        setQError(errorQualificationDetails)
                    }
                }

                //total experience Validation
                if (stateExperience.totalExperience != undefined && parseInt(stateExperience.totalExperience) > 0) {
                    const maxExp = Math.max(parseInt(stateExperience.roadDesign != undefined ? stateExperience.roadDesign : '0'),
                        parseInt(stateExperience.trafficEngineering != undefined ? stateExperience.trafficEngineering : '0'),
                        parseInt(stateExperience.trafficTransportManagement != undefined ? stateExperience.trafficTransportManagement : '0'),
                        parseInt(stateExperience.roadSafetyEngineering != undefined ? stateExperience.roadSafetyEngineering : '0'),
                        parseInt(stateExperience.behaviouralScience != undefined ? stateExperience.behaviouralScience : '0'));

                    const sumExp = parseInt(stateExperience.roadDesign != undefined ? stateExperience.roadDesign : '0')
                        + parseInt(stateExperience.trafficEngineering != undefined ? stateExperience.trafficEngineering : '0')
                        + parseInt(stateExperience.trafficTransportManagement != undefined ? stateExperience.trafficTransportManagement : '0')
                        + parseInt(stateExperience.roadSafetyEngineering != undefined ? stateExperience.roadSafetyEngineering : '0')
                        + parseInt(stateExperience.behaviouralScience != undefined ? stateExperience.behaviouralScience : '0');

                    const rule: ValidationRuleProps[] = [
                        {
                            Rule: '^\\d+$',
                            Type: ValidationRuleType.REGEX,
                        },
                        {
                            Rule: `${stateExperience.totalExperience} <= 80`,
                            Type: ValidationRuleType.CUSTOM,
                        },
                        {
                            Rule: `${maxExp} <= ${stateExperience.totalExperience} && ${stateExperience.totalExperience} <= ${sumExp}`,
                            Type: ValidationRuleType.CUSTOM
                        }
                    ]
                    ValidateProps = {
                        value: stateExperience.totalExperience,
                        fieldType: 'text',
                        rules: rule,
                        fieldName: 'Total Experience',
                        required: true,
                        fieldId: 'totalExperience'
                    } as ValidationRequestProps
                    const txtPostcodeVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!txtPostcodeVal.Valid) {
                        result = false;
                        failedResult.push(txtPostcodeVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.totalExperience = txtPostcodeVal.Msg
                        setQError(errorQualificationDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Total Experience is Required '
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorQualificationDetails.totalExperience = res.Msg
                    setQError(errorQualificationDetails)
                }

                //work experience and skills summery details
                if (stateExperience.experienceSkillsSummary != undefined && stateExperience.experienceSkillsSummary != '') {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '2000',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateExperience.experienceSkillsSummary,
                        fieldType: 'text',
                        fieldName: 'Experience Skills Summary',
                        required: true,
                        fieldId: 'experienceSkillsSummary',
                        rules: rule
                    } as ValidationRequestProps
                    const experienceSkillsSummaryVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!experienceSkillsSummaryVal.Valid) {
                        result = false;
                        failedResult.push(experienceSkillsSummaryVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.experienceSkillsSummary = experienceSkillsSummaryVal.Msg
                        setQError(errorQualificationDetails)
                    }
                } else {
                    if (stateAuditor != undefined && (stateAuditor.txtApplyLevel == '2' || stateAuditor.txtApplyLevel == '3' || stage == '2' || stage == '3')) {
                        const res: ValidationResponseProps = {
                            Valid: false,
                            Msg: 'Work Experience / Skill Summary is Required for selected auditor level '
                        } as ValidationResponseProps
                        failedResult.push(res)
                        result = false;
                        setErrorList(failedResult)
                        errorQualificationDetails.experienceSkillsSummary = res.Msg
                        setQError(errorQualificationDetails)
                    }
                }

                //Mitigation details
                if (stateExperience.mitigation != undefined && stateExperience.mitigation != '') {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '2000',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateExperience.mitigation,
                        fieldType: 'text',
                        fieldName: 'Road Safety identification and mitigation',
                        required: true,
                        fieldId: 'mitigation',
                        rules: rule
                    } as ValidationRequestProps
                    const mitigationVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!mitigationVal.Valid) {
                        result = false;
                        failedResult.push(mitigationVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.mitigation = mitigationVal.Msg
                        setQError(errorQualificationDetails)
                    }
                } else {
                    if (stateAuditor != undefined && (stateAuditor.txtApplyLevel == '2' || stateAuditor.txtApplyLevel == '3' || stage == '2' || stage == '3')) {
                        const res: ValidationResponseProps = {
                            Valid: false,
                            Msg: 'Road Safety identification and mitigation is Required for selected auditor level '
                        } as ValidationResponseProps
                        failedResult.push(res)
                        result = false;
                        setErrorList(failedResult)
                        errorQualificationDetails.mitigation = res.Msg
                        setQError(errorQualificationDetails)
                    }
                }

                //Relevant Experiences Validation
                if (stateExperience.roadDesign != undefined &&
                    parseInt(stateExperience.roadDesign) > 0 &&
                    (stateExperience.rdSummary == undefined || stateExperience.rdSummary.length <= 0)
                ) {
                    ValidateProps = {
                        value: stateExperience.rdSummary,
                        fieldType: 'text',
                        fieldName: 'Road Design Summary',
                        required: true,
                        fieldId: 'roadDesignSummary'
                    } as ValidationRequestProps
                    const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                    result = false;
                    failedResult.push(roadDesignVal)
                    setErrorList(failedResult)
                    errorQualificationDetails.rdSummary = roadDesignVal.Msg
                    setQError(errorQualificationDetails)
                }
                if (stateExperience.trafficEngineering != undefined &&
                    parseInt(stateExperience.trafficEngineering) > 0 &&
                    (stateExperience.trSummary == undefined || stateExperience.trSummary.length <= 0)
                ) {
                    ValidateProps = {
                        value: stateExperience.trSummary,
                        fieldType: 'text',
                        fieldName: 'Traffic Engineering Summary',
                        required: true,
                        fieldId: 'trSummary'
                    } as ValidationRequestProps
                    const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                    result = false;
                    failedResult.push(roadDesignVal)
                    setErrorList(failedResult)
                    errorQualificationDetails.trSummary = roadDesignVal.Msg
                    setQError(errorQualificationDetails)
                }
                if (stateExperience.trafficTransportManagement != undefined &&
                    parseInt(stateExperience.trafficTransportManagement) > 0 &&
                    (stateExperience.ttmSummary == undefined || stateExperience.ttmSummary.length <= 0)
                ) {
                    ValidateProps = {
                        value: stateExperience.ttmSummary,
                        fieldType: 'text',
                        fieldName: 'Traffic Transport Management Summary',
                        required: true,
                        fieldId: 'ttmSummary'
                    } as ValidationRequestProps
                    const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                    result = false;
                    failedResult.push(roadDesignVal)
                    setErrorList(failedResult)
                    errorQualificationDetails.ttmSummary = roadDesignVal.Msg
                    setQError(errorQualificationDetails)
                }
                if (stateExperience.roadSafetyEngineering != undefined &&
                    parseInt(stateExperience.roadSafetyEngineering) > 0 &&
                    (stateExperience.rseSummary == undefined || stateExperience.rseSummary.length <= 0)
                ) {
                    ValidateProps = {
                        value: stateExperience.rseSummary,
                        fieldType: 'text',
                        fieldName: 'Road Safety Engineering Summary',
                        required: true,
                        fieldId: 'rseSummary'
                    } as ValidationRequestProps
                    const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                    result = false;
                    failedResult.push(roadDesignVal)
                    setErrorList(failedResult)
                    errorQualificationDetails.rseSummary = roadDesignVal.Msg
                    setQError(errorQualificationDetails)
                }
                if (stateExperience.behaviouralScience != undefined &&
                    parseInt(stateExperience.behaviouralScience) > 0 &&
                    (stateExperience.bsSummary == undefined || stateExperience.bsSummary.length <= 0)
                ) {
                    ValidateProps = {
                        value: stateExperience.bsSummary,
                        fieldType: 'text',
                        fieldName: 'behavioural Science Summary',
                        required: true,
                        fieldId: 'bsSummary'
                    } as ValidationRequestProps
                    const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                    result = false;
                    failedResult.push(roadDesignVal)
                    setErrorList(failedResult)
                    errorQualificationDetails.bsSummary = roadDesignVal.Msg
                    setQError(errorQualificationDetails)
                }
                if (
                    stateExperience.roadDesign != undefined &&
                    parseInt(stateExperience.roadDesign) > 0 &&
                    stateExperience.trafficEngineering != undefined &&
                    parseInt(stateExperience.trafficEngineering) > 0 &&
                    stateExperience.trafficTransportManagement != undefined &&
                    parseInt(stateExperience.trafficTransportManagement) > 0 &&
                    stateExperience.roadSafetyEngineering != undefined &&
                    parseInt(stateExperience.roadSafetyEngineering) > 0 &&
                    stateExperience.behaviouralScience != undefined &&
                    parseInt(stateExperience.behaviouralScience) > 0
                ) {
                    if (stateExperience.roadDesign != '0') {
                        const rule: ValidationRuleProps[] = [
                            {
                                Rule: '^\\d+$',
                                Type: ValidationRuleType.REGEX,
                            },
                            {
                                Rule: `${stateExperience.roadDesign} <= 80`,
                                Type: ValidationRuleType.CUSTOM
                            }
                        ]
                        ValidateProps = {
                            value: stateExperience.roadDesign,
                            fieldType: 'text',
                            rules: rule,
                            fieldName: 'Road Design',
                            required: true,
                            fieldId: 'roadDesign'
                        } as ValidationRequestProps
                        const roadDesignVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!roadDesignVal.Valid) {
                            result = false;
                            failedResult.push(roadDesignVal)
                            setErrorList(failedResult)
                            errorQualificationDetails.roadDesign = roadDesignVal.Msg
                            setQError(errorQualificationDetails)
                        }
                    }

                    if (stateExperience.trafficEngineering != '0') {
                        const rule: ValidationRuleProps[] = [
                            {
                                Rule: '^\\d+$',
                                Type: ValidationRuleType.REGEX,
                            },
                            {
                                Rule: `${stateExperience.trafficEngineering} <= 80`,
                                Type: ValidationRuleType.CUSTOM
                            }
                        ]
                        ValidateProps = {
                            value: stateExperience.trafficEngineering,
                            fieldType: 'text',
                            rules: rule,
                            fieldName: 'Traffic Engineering',
                            required: true,
                            fieldId: 'trafficEngineering'
                        } as ValidationRequestProps
                        const trafficEngineeringVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!trafficEngineeringVal.Valid) {
                            result = false;
                            failedResult.push(trafficEngineeringVal)
                            setErrorList(failedResult)
                            errorQualificationDetails.trafficEngineering = trafficEngineeringVal.Msg
                            setQError(errorQualificationDetails)
                        } else {
                            if (stateExperience.trSummary == undefined || stateExperience.trSummary.length <= 0) {
                                result = false;
                                failedResult.push(trafficEngineeringVal)
                                setErrorList(failedResult)
                                errorQualificationDetails.trafficEngineering = 'This field cannot be empty '
                                setQError(errorQualificationDetails)
                            }
                        }
                    }

                    if (stateExperience.trafficTransportManagement != '0') {
                        const rule: ValidationRuleProps[] = [
                            {
                                Rule: '^\\d+$',
                                Type: ValidationRuleType.REGEX,
                            },
                            {
                                Rule: `${stateExperience.trafficTransportManagement} <= 80`,
                                Type: ValidationRuleType.CUSTOM
                            }
                        ]
                        ValidateProps = {
                            value: stateExperience.trafficTransportManagement,
                            fieldType: 'text',
                            rules: rule,
                            fieldName: 'Traffic Transport Management',
                            required: true,
                            fieldId: 'trafficTransportManagement'
                        } as ValidationRequestProps
                        const trafficTransportManagementVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!trafficTransportManagementVal.Valid) {
                            result = false;
                            failedResult.push(trafficTransportManagementVal)
                            setErrorList(failedResult)
                            errorQualificationDetails.trafficTransportManagement = trafficTransportManagementVal.Msg
                            setQError(errorQualificationDetails)
                        } else {
                            if (stateExperience.ttmSummary == undefined || stateExperience.ttmSummary.length <= 0) {
                                result = false;
                                failedResult.push(trafficTransportManagementVal)
                                setErrorList(failedResult)
                                errorQualificationDetails.trafficTransportManagement = 'This field cannot be empty '
                                setQError(errorQualificationDetails)
                            }
                        }
                    }

                    if (stateExperience.roadSafetyEngineering != '0') {
                        const rule: ValidationRuleProps[] = [
                            {
                                Rule: '^\\d+$',
                                Type: ValidationRuleType.REGEX,
                            },
                            {
                                Rule: `${stateExperience.roadSafetyEngineering} <= 80`,
                                Type: ValidationRuleType.CUSTOM
                            }
                        ]
                        ValidateProps = {
                            value: stateExperience.roadSafetyEngineering,
                            fieldType: 'text',
                            rules: rule,
                            fieldName: 'Road Safety Engineering',
                            required: true,
                            fieldId: 'roadSafetyEngineering'
                        } as ValidationRequestProps
                        const roadSafetyEngineeringVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!roadSafetyEngineeringVal.Valid) {
                            result = false;
                            failedResult.push(roadSafetyEngineeringVal)
                            setErrorList(failedResult)
                            errorQualificationDetails.roadSafetyEngineering = roadSafetyEngineeringVal.Msg
                            setQError(errorQualificationDetails)
                        } else {
                            if (stateExperience.rseSummary == undefined || stateExperience.rseSummary.length <= 0) {
                                result = false;
                                failedResult.push(roadSafetyEngineeringVal)
                                setErrorList(failedResult)
                                errorQualificationDetails.roadSafetyEngineering = 'This field cannot be empty '
                                setQError(errorQualificationDetails)
                            }
                        }
                    }

                    if (stateExperience.behaviouralScience != '0') {
                        const rule: ValidationRuleProps[] = [
                            {
                                Rule: '^\\d+$',
                                Type: ValidationRuleType.REGEX,
                            },
                            {
                                Rule: `${stateExperience.behaviouralScience} <= 80`,
                                Type: ValidationRuleType.CUSTOM
                            }
                        ]
                        ValidateProps = {
                            value: stateExperience.behaviouralScience,
                            fieldType: 'text',
                            rules: rule,
                            fieldName: 'Behavioural Science',
                            required: true,
                            fieldId: 'behaviouralScience'
                        } as ValidationRequestProps
                        const behaviouralScienceVal: ValidationResponseProps = Validations(ValidateProps)
                        if (!behaviouralScienceVal.Valid) {
                            result = false;
                            failedResult.push(behaviouralScienceVal)
                            setErrorList(failedResult)
                            errorQualificationDetails.behaviouralScience = behaviouralScienceVal.Msg
                            setQError(errorQualificationDetails)
                        } else {
                            if (stateExperience.bsSummary == undefined || stateExperience.bsSummary.length <= 0) {
                                result = false;
                                failedResult.push(behaviouralScienceVal)
                                setErrorList(failedResult)
                                errorQualificationDetails.behaviouralScience = 'This field cannot be empty '
                                setQError(errorQualificationDetails)
                            }
                        }
                    }

                } else {
                    if ((stateExperience.roadDesign == undefined ||
                            parseInt(stateExperience.roadDesign) <= 0) &&
                        (stateExperience.trafficEngineering == undefined ||
                            parseInt(stateExperience.trafficEngineering) <= 0) &&
                        (stateExperience.trafficTransportManagement == undefined ||
                            parseInt(stateExperience.trafficTransportManagement) <= 0) &&
                        (stateExperience.roadSafetyEngineering == undefined ||
                            parseInt(stateExperience.roadSafetyEngineering) <= 0) &&
                        (stateExperience.behaviouralScience == undefined ||
                            parseInt(stateExperience.behaviouralScience) <= 0)) {

                        const res: ValidationResponseProps = {
                            Valid: false,
                            Msg: 'At least one relevant experience is Required '
                        } as ValidationResponseProps
                        failedResult.push(res)
                        result = false;
                        setErrorList(failedResult)
                        errorQualificationDetails.roadDesign = res.Msg
                        setQError(errorQualificationDetails)
                        errorQualificationDetails.trafficEngineering = res.Msg
                        setQError(errorQualificationDetails)
                        errorQualificationDetails.trafficTransportManagement = res.Msg
                        setQError(errorQualificationDetails)
                        errorQualificationDetails.roadSafetyEngineering = res.Msg
                        setQError(errorQualificationDetails)
                        errorQualificationDetails.behaviouralScience = res.Msg
                        setQError(errorQualificationDetails)

                    }

                }

                //Other relevant experience Validation
                if (stateExperience.otherRelevantExperience != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: '100',
                        Type: ValidationRuleType.MAXLENTH,
                    }]
                    ValidateProps = {
                        value: stateExperience.otherRelevantExperience,
                        fieldType: 'text',
                        fieldName: 'Other Relevant Experience',
                        required: true,
                        fieldId: 'otherRelevantExperience',
                        rules: rule
                    } as ValidationRequestProps
                    const otherRelevantExperienceVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!otherRelevantExperienceVal.Valid) {
                        result = false;
                        failedResult.push(otherRelevantExperienceVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.otherRelevantExperience = otherRelevantExperienceVal.Msg
                        setQError(errorQualificationDetails)
                    }
                }

                //Work Experience Declaration details
                if (stateExperience.eligibilityCriteria != undefined) {
                    const rule: ValidationRuleProps[] = [{
                        Rule: `(${stateExperience.totalExperience} < 5 && ${stateExperience.eligibilityCriteria} == '1') || (${stateExperience.totalExperience} <= 6 && ${stateExperience.totalExperience} >= 5 && ${stateExperience.eligibilityCriteria} == '2') || (${stateExperience.totalExperience} >= 7 && ${stateExperience.eligibilityCriteria} == '3')`,
                        Type: ValidationRuleType.CUSTOM,
                    }]
                    ValidateProps = {
                        value: stateExperience.eligibilityCriteria,
                        fieldType: 'dropdown',
                        fieldName: 'Work Experience Declaration',
                        required: true,
                        fieldId: 'eligibilityCriteria',
                        rules: rule
                    } as ValidationRequestProps
                    const eligibilityCriteriaVal: ValidationResponseProps = Validations(ValidateProps)
                    if (!eligibilityCriteriaVal.Valid) {
                        result = false;
                        failedResult.push(eligibilityCriteriaVal)
                        setErrorList(failedResult)
                        errorQualificationDetails.eligibilityCriteria = eligibilityCriteriaVal.Msg
                        setQError(errorQualificationDetails)
                    }
                } else {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Work Experience Declaration is Required'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorQualificationDetails.eligibilityCriteria = res.Msg
                    setQError(errorQualificationDetails)
                }

            } else {
                if ((pathName == '/profile' && !(props.session.status == 3 || props.session.status == 4)) || (pathName == '/accriditation')) {
                    const res: ValidationResponseProps = {
                        Valid: false,
                        Msg: 'Please fill required fields in qualification details'
                    } as ValidationResponseProps
                    failedResult.push(res)
                    result = false;
                    setErrorList(failedResult)
                    errorQualificationDetails.totalExperience = res.Msg
                    errorQualificationDetails.eligibilityCriteria = res.Msg
                    setQError(errorQualificationDetails)
                } 
            }
        }
        return result
    }

    const submitAuditor = async (isSendEmail = false) => {

        if (session.userRoleId == 1 || session.userRoleId == 4) {
            if (rsc.isActivated == true && rsc.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Road Safety Courses.');
                return;
            }
            if (rsa.isActivated == true && rsa.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Road Safety Audits.');
                return;
            }
            if (qe.isActivated == true && qe.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Qualifications And Experience.');
                return;
            }
            if (bd.isActivated == true && bd.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Business Details.');
                return;
            }
            if (ad.isActivated == true && ad.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Personal Details.');
                return;
            }
            if (ava.isActivated == true && ava.msgFromAdmin.length <= 0) {
                ErrorToast('Please enter the message with regard to Availability');
                return;
            }
        } else {
            if (!chkAgree) {
                ErrorToast('Please click I agree...!');
                return;
            }
        }
        const reload = await fetchAuditor(session.auditorId, 0)

        //let audits: RoadSafetyAuditProps[] ;
        setStateCourse(reload.roadSafetyCourses)
        //setStateAudits(reload.roadSafetyAudit)
        const audits: RoadSafetyAuditProps[] = reload.roadSafetyAudit ? reload.roadSafetyAudit : [] as RoadSafetyAuditProps[];

        if(!(session.userRoleId == 1 || session.userRoleId == 4)){
            if (!ValidateDataforSubmit()) {
                console.log(failedResult)
                setErrorPop(true)
                return;
            }
        }


        // if(props.session.status == 9 || props.session.status == 1 ){
        //     const isEligible = await eligibilityCheck( stateAuditor?.txtApplyLevel ,stateAuditor?.txtAuditorId)
        //     if (!isEligible.CourseSuccess || !isEligible.AuditSuccess || !isEligible.experianceSuccess) {
        //         ErrorToast('Eligibility not met for the requested Level, please refer to FAQs.')
        //         errorPersonalDetails.txtApplyLevel = 'Eligibility not met for the requested Level, please refer to FAQs.';
        //         setError(errorPersonalDetails)
        //         window.location.href = '/profile#ddApplyLevel'
        //         return;
        //     }
        // }

        if (stateBusiness && pathName == '/profile') {
            if (stateBusiness.txtPreferredContact.includes('1') && stateBusiness?.txtMobilePhone != undefined && stateBusiness?.txtMobilePhone.length < 5) {
                ErrorToast(`Please fill mobile number before select !`);
                return;
            }
            if (stateBusiness.txtPreferredContact.includes('2') && stateBusiness?.txtHomePhone != undefined && stateBusiness?.txtHomePhone.length < 5) {
                ErrorToast(`Please fill home phone number before select !`);
                return;
            }
            if (stateBusiness.txtPreferredContact.includes('4') && stateBusiness?.txtWorkEmail != undefined && stateBusiness?.txtWorkEmail.length < 5) {
                ErrorToast(`Please fill work email before select !`);
                return;
            }
            if (stateBusiness.txtPreferredContact.includes('5') && stateBusiness?.txtPersonalEmail != undefined && stateBusiness?.txtPersonalEmail.length < 5) {
                ErrorToast(`Please fill personal email before select !`);
                return;
            }
            if (stateBusiness.txtPreferredContact.includes('6') && stateBusiness?.txtOfficePhone != undefined && stateBusiness?.txtOfficePhone.length < 5) {
                ErrorToast(`Please fill work phone number before select !`);
                return;
            }
        }


        const personal = stateAuditor ? stateAuditor : {} as PersonalDetailsValueProps
        const busin = stateBusiness ? stateBusiness : {} as BusinessDetailsValueProps
        const region = stateRegion ? stateRegion : {} as AuditorRegionValueProps
        const exp = stateExperience ? stateExperience : {} as QualificationValueProps
        const course = stateCourse ? stateCourse : {} as RoadSafetyCoursesProps[]

        //eslint-disable-next-line
        let selectedRegion: number[] = [];

        if (stateRegion?.greateSyndey == true) {
            selectedRegion.push(1);
        }
        if (stateRegion?.north == true) {
            selectedRegion.push(2);
        }
        if (stateRegion?.south == true) {
            selectedRegion.push(3);
        }
        if (stateRegion?.west == true) {
            selectedRegion.push(4);
        }
        if (stateRegion?.vic == true) {
            selectedRegion.push(5);
        }
        if (stateRegion?.wa == true) {
            selectedRegion.push(6);
        }
        if (stateRegion?.act == true) {
            selectedRegion.push(7);
        }
        if (stateRegion?.qld == true) {
            selectedRegion.push(8);
        }
        if (stateRegion?.sa == true) {
            selectedRegion.push(9);
        }
        if (stateRegion?.tas == true) {
            selectedRegion.push(10);
        }
        if (stateRegion?.nt == true) {
            selectedRegion.push(11);
        }
        if (stateRegion?.overseas == true) {
            selectedRegion.push(12);
        }

        if (!(session.userRoleId == 1 || session.userRoleId == 4)) {
            if (selectedRegion.length <= 0) {
                ErrorToast('Please add at least one region for submit registration !');
                return;
            }

            if (!(selectedRegion.includes(1) || selectedRegion.includes(2) || selectedRegion.includes(3) || selectedRegion.includes(4))) {
                ErrorToast('Please select at least one region in NSW to submit registration !');
                return;
            }

            if (stateCourse == undefined || stateCourse.length <= 0) {
                ErrorToast('Please add exsisting course for submit registration !');
                return;
            }

            if (audits == undefined || audits.length <= 0) {
                ErrorToast('Please add at least one road safety audit for submit registration !');
                return;
            }
        }

        const SaveAuditor = AddOrUpdateAuditorProfile(personal, busin, exp, region, true, course);
        setLoading(true);
        saveOngoing = true
        Promise.resolve(SaveAuditor).then(async (x) => {
            if (session.userRoleId == 1 || session.userRoleId == 4) {
                const saveStatus = await AdminAuditorStatusChange(personal.txtAuditorId ? personal.txtAuditorId : parseInt(atob(encid ? encid : '0')),Status)
                let count = 0;
                if (rsc.msgFromAdmin) {
                    const saveInfoToggle = await CreateOrUpdateMsgFromAdmin(rsc.auditorId, "RSC", rsc.isActivated, rsc.msgFromAdmin, rsc.id)
                    Promise.resolve(saveInfoToggle).then((y) => {
                        count++
                    })
                }
                if (rsa.msgFromAdmin) {
                    const saveInfoTogglersa = await CreateOrUpdateMsgFromAdmin(rsa.auditorId, "RSA", rsa.isActivated, rsa.msgFromAdmin, rsa.id)
                    Promise.resolve(saveInfoTogglersa).then((y) => {
                        count++
                    })
                }
                if (qe.msgFromAdmin) {
                    const saveInfoToggleqe = await CreateOrUpdateMsgFromAdmin(qe.auditorId, "QE", qe.isActivated, qe.msgFromAdmin, qe.id)
                    Promise.resolve(saveInfoToggleqe).then((y) => {
                        count++
                    })
                }
                if (bd.msgFromAdmin) {
                    const saveInfoTogglebd = await CreateOrUpdateMsgFromAdmin(bd.auditorId, "BD", bd.isActivated, bd.msgFromAdmin, bd.id)
                    Promise.resolve(saveInfoTogglebd).then((y) => {
                        count++
                    })
                }
                if (ad.msgFromAdmin) {
                    const saveInfoTogglead = await CreateOrUpdateMsgFromAdmin(ad.auditorId, "AD", ad.isActivated, ad.msgFromAdmin, ad.id)
                    Promise.resolve(saveInfoTogglead).then((y) => {
                        count++
                    })
                }
                if (ava.msgFromAdmin) {
                    const saveInfoToggleava = await CreateOrUpdateMsgFromAdmin(ava.auditorId, "AVA", ava.isActivated, ava.msgFromAdmin, ava.id)
                    Promise.resolve(saveInfoToggleava).then((y) => {
                        count++
                    })
                }
                if(isSendEmail){
                    const sendEmail = await SendEmailToAuditor(parseInt(atob(encid ? encid : '0')))
                    Promise.resolve(sendEmail).then((y) => {
                        count++
                    })
                }
                //eslint-disable-next-line
                setProfile(true)
                setStatChange(false)
                //TimeBase.DeveloperMode == true ? InfoToast("Successfully updated !") : null;
                saveOngoing = false
                setLoading(false);
                SuccessToast('Successfully updated !')
                setTimeout(() => {
                    location.reload()
                }, 1500)
            } else {
                //eslint-disable-next-line
                setProfile(true)
                setStatChange(false)
                //TimeBase.DeveloperMode == true ? InfoToast("Successfully updated !") : null;
                saveOngoing = false
                setLoading(false);
                SuccessToast('Successfully updated !')
                setTimeout(() => {
                    window.location.href = pathName
                }, 1500)
            }

        });
    }

    const SaveAsDraft = () => {
        setLoading(true);
        saveDraftClick = true
        setTimeout(() => {
            ProfileSave();
        }, 1000);

    }

    return (
        <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={encid != null ? `Auditor record - ${stateAuditor?.txtFirstName} ${stateAuditor?.txtLastName}` : props.session.status == undefined || props.session.status == 8 ? 'Auditor Registration' : pathName == '/accriditation' ? 'Auditor Accreditation' : 'Auditor Profile'}
                usreName={props.session.userDisplayName}
                userRole={userRoleSelector(props.session.userRoleId.toString())}
                lastLogin={props.session.lastLogin}
                status={encid != null ? userRoleSelector(session.userRoleId.toString()) : props.session.statusName == undefined || props.session.statusName == '' ? 'Saved as Draft' : props.session.statusName}
                level={props.session.auditorLevel}
                certExpDate={props.session.certExpDate}
                newId={props.session.auditorNewId}
            />
            <Modals
                open={loading}
                basicModal={true}
                modalSize={'small'}
                imageSize={'tiny'}
                imageUrl={'https://images.emojiterra.com/google/noto-emoji/v2.034/128px/23f3.png'}
                setOpen={setLoading}
                modalDescription={true}
                descriptionHeader={'Please wait ...'}
                descriptionBody={'Your data is saving ...'}
                modalDimmer={"blurring"}
                modalAction={false}
            />
            <Modals
                open={errorPop}
                basicModal={false}
                modalSize={'small'}
                imageSize={'tiny'}
                imageUrl={'https://images.emojiterra.com/twitter/v14.0/512px/26a0.png'}
                title={'Please check below message(s) and rectify'}
                setOpen={setErrorPop}
                modalDescription={false}
                ModalBody={() => (<ErrorList
                    Error={errorList}
                />)}
                modalDimmer={"blurring"}
                modalAction={false}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? (<ControllAdminButton
                            SaveAsDraft={() => {
                                if (stateAuditor?.txtPassword != undefined && stateAuditor?.txtPassword.length > 0 && stateAuditor?.txtApplyLevel != undefined && stateAuditor?.txtApplyLevel.length > 0) {
                                    SaveAsDraft()
                                } else {
                                    errorToast('To save as a draft, it\'s necessary to complete at least the personal section.')
                                }
                            }}
                            submitAuditor={submitAuditor}
                            session={props.session}
                            chkAgree={chkAgree}
                            emailBtnEnable={rsa.isActivated || rsc.isActivated || ad.isActivated || bd.isActivated || ava.isActivated}
                        />) : null}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ContactDetails
                            title={props.title}
                            country={props.country}
                            auditor={props.auditor}
                            states={props.states}
                            contactMethods={props.contactMethods}
                            focusMode={focusMode}
                            setFocusMode={setFocusMode}
                            setStateAuditor={setStateAuditor}
                            setStateBusiness={setStateBusiness}
                            setStateRegion={setStateRegion}
                            stateAuditor={stateAuditor}
                            company={props.company}
                            profile={profile}
                            Session={props.session}
                            setConfirmPW={setConfirmPW}
                            PersoanlError={PersonalError}
                            BusinessError={BusinessError}
                            setBD={setBD}
                            bd={bd}
                            ad={ad}
                            setAD={setAD}
                            ava={ava}
                            setAVA={setAVA}
                            SetStatus={setStatus}
                            Status = {Status}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        {profile ? (props.session.status == 3 || props.session.status == 4) && pathName == '/profile' ? null : (
                            <QualificationsAndExperience
                                auditor={props.auditor}
                                focusMode={focusMode}
                                setFocusMode={setFocusMode}
                                setStateExperiance={setStateExperience}
                                Error={QualificationError}
                                Level={stateAuditor != undefined && stateAuditor.txtApplyLevel != undefined ? stateAuditor.txtApplyLevel : '0'}
                                qe={qe}
                                setQE={setQE}
                            />) : null}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        {profile ? (props.session.status == 3 || props.session.status == 4) && pathName == '/profile' ? null : (
                            <RoadSafetyCourses
                                provider={props.provider}
                                course={props.course}
                                auditor={props.auditor}
                                setAuditor={props.setAuditor}
                                focusMode={focusMode}
                                setFocusMode={setFocusMode}
                                rsc={rsc}
                                setRSC={setRSC}

                            />) : null}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        {profile ? (props.session.status == 3 || props.session.status == 4) && pathName == '/profile' ? null : (
                            <RoadSafetyAudits
                                states={props.states}
                                auditStages={props.auditStages}
                                auditor={props.auditor}
                                setAuditor={props.setAuditor}
                                focusMode={focusMode}
                                setFocusMode={setFocusMode}
                                setStatChange={setStatChange}
                                rsa={rsa}
                                setRSA={setRSA}
                            />) : null}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? null : (<Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Disclaimer setChkAgree={setAgree}/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>)}
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? (<Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AdminLog/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>) : null}
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? (<Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <SuperAdminNotes/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>) : null}
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? (
                    <Grid.Row>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            <ControllAdminButton
                                SaveAsDraft={() => {
                                    if (stateAuditor?.txtPassword != undefined && stateAuditor?.txtPassword.length > 0 && stateAuditor?.txtApplyLevel != undefined && stateAuditor?.txtApplyLevel.length > 0) {
                                        SaveAsDraft()
                                    } else {
                                        errorToast('To save as a draft, it\'s necessary to complete at least the personal section.')
                                    }
                                }}
                                submitAuditor={submitAuditor}
                                session={props.session}
                                chkAgree={chkAgree}
                                auditor={props.auditor}
                                emailBtnEnable={rsa.isActivated || rsc.isActivated || ad.isActivated || bd.isActivated || ava.isActivated}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>

                        </Grid.Column>
                    </Grid.Row>
                ) : (<Grid.Row centered={true}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} widescreen={7} largeScreen={7} computer={7}>

                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={5} widescreen={4} largeScreen={4}
                                 style={{justifyContent: 'center', display: 'flex'}}>
                        <ControllButtonReg
                            SaveAsDraft={() => {
                                if (stateAuditor?.txtPassword != undefined && stateAuditor?.txtPassword.length > 0 && stateAuditor?.txtApplyLevel != undefined && stateAuditor?.txtApplyLevel.length > 0) {
                                    SaveAsDraft()
                                } else {
                                    errorToast('To save as a draft, it\'s necessary to complete at least the personal section.')
                                }
                            }}
                            submitAuditor={submitAuditor}
                            session={props.session}
                            chkAgree={chkAgree}
                        />
                    </Grid.Column>
                </Grid.Row>)}
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <span><em style={{color: 'red'}}>* </em><em>compulsory.</em></span>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}
export default ProfileContainer;

interface ControllButtonRegProps {
    //eslint-disable-next-line
    SaveAsDraft: any;
    //eslint-disable-next-line
    submitAuditor: any;
    //eslint-disable-next-line
    session: any;
    chkAgree: boolean
    auditor?: any
    emailBtnEnable?:boolean
}

const ControllButtonReg = (props: ControllButtonRegProps) => {
    return (
        <div className={'button-flex'}>
            {props.session.status == undefined || props.session.status == 8 ? (
                <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                    props.SaveAsDraft()
                }} color={'red'} className={"background-transaparent-red"}
                               text={'Save as Draft'}/>) : props.session.status == 3 || props.session.status == 4 ? (
                <OutLineButton id={'btnSave'} style={{width: '200px'}} onClick={() => {
                    window.location.href = '/welcome'
                }} color='blue' className={"background-transaparent"} text={'Go back to Dashboard'}/>) : (
                <div style={{width: '150px'}}>{''}</div>)}
            <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.submitAuditor()
            }} color='blue' className={"background-transaparent"}
                           text={props.session.status == undefined || props.session.status == 8 ? 'Submit' : 'Save'}
                           disabled={!props.chkAgree}/>
        </div>
    )
}

const ControllAdminButton = (props: ControllButtonRegProps) => {
    return (
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <div className={'button-flex'}>
                        <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                            window.location.href = '/welcome'
                        }} color='blue' className={"background-transaparent"} text={'Unlock Login'} disabled={true}/>
                        <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                            window.location.href = '/welcome'
                        }} color='blue' className={"background-transaparent"} text={'Enable Edit'} disabled={true}/>
                    </div>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
                <Grid.Column>
                    <div className={'button-flex'}>
                        <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                            window.location.href = '/welcome'
                        }} color='blue' className={"background-transaparent"} text={'Back'}/>
                        <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                            props.submitAuditor()
                        }} color='blue' className={"background-transaparent"}
                                       text={'Save'}/>
                        <OutLineButton id={'btnSave'} style={{width: '250px'}} onClick={() => {
                            props.submitAuditor(true)
                        }} color='blue' className={"background-transaparent"}
                                       text={'Save & Email Auditor'} disabled={!props.emailBtnEnable}/>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

interface ErrorProps {
    Error: ValidationResponseProps[]
}

const ErrorList = ({Error}: ErrorProps) => {
    console.log('error', Error)
    return (
        <Container fluid={true}>
            <Grid>
                {
                    Error.map((r: ValidationResponseProps, index: number) => (
                        <Grid.Row key={index} columns={3}>
                            <Grid.Column width={1}>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                {index + 1}
                            </Grid.Column>
                            <Grid.Column width={14}>
                                {r.Msg}
                            </Grid.Column>
                        </Grid.Row>
                    ))
                }
            </Grid>
        </Container>
    );
}