import React, {useEffect, useState} from 'react'
import {Grid} from "semantic-ui-react";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../framework/types/TableHeader";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import Position from "../../../framework/types/Position";
import NameWithMultiline from "../../../framework/widgets/NameWithText/NameWithMuliLine";
import moment from "moment/moment";
import {IAdminNotifications} from "../../../models/apiResponce/Notification/GetNotifications";
import {AddLogfromAdmin, GetAdminNotifications} from "./ProfileController";


const AdminLog = () => {
    const [auditorId, setAuditorId] = useState<any>();
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')
    const [auditorStatus, SetAuditorStatus] = useState(0)
    const [HistoryDet, setHistoryDet] = useState<object[]>([])
    const [totalPages,setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [logDesc, setLogDesc] = useState('')
    const HistoryHead: TableHeader[] = [
        {
            text: "Date/Time"
        },
        {
            text: "Note"
        },
        {
            text: "Notify"
        }
    ];
    useEffect(()=>{
        fetchAdminLogs()
    },[])

    const fetchAdminLogs = (pageNo?:number) => {
        if(encid) {
            const id = atob(encid)
            const getAssesmentHistry = GetAdminNotifications(parseInt(id))
            Promise.resolve(getAssesmentHistry).then((r) => {
                SetValuesHistory(r)
            })
        }
    }

    const SetValuesHistory = (Assesment: IAdminNotifications[]) => {
        const TableRows: object[] = [];
        Assesment.map((raw: IAdminNotifications) => {
            TableRows.push([
                {
                    tag: "text",
                    text: moment(raw.createDate).format("DD-MM-YYYY hh:mm:ss A"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.logDescription,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "checkbox",
                    text: '',
                    icon: "",
                    onclick: null
                }
            ])})
        setHistoryDet(TableRows);
    }

    const SaveLogComment = () => {
        if(encid) {
            const id = atob(encid)
            const getAssesmentHistry = AddLogfromAdmin(parseInt(id), logDesc)
            Promise.resolve(getAssesmentHistry).then((r) => {
                fetchAdminLogs()
                setLogDesc('')
            })
        }
    }

    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title" id={'courses'}>
                <h1 className="ui center aligned icon header red-title">Admin Log </h1>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <NameWithMultiline id={'txtAddNotes'}
                                           labelText={'Add Notes'}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={false}
                                           labelPosition={Position.Top}
                                           required={true}
                                           fluid={false}
                                           value={logDesc}
                                           onChange={(e:any)=>{
                                               setLogDesc(e.target.value)
                                           }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <div className={'button-flex'} style={{justifyContent:'start'}}>
                            <OutLineButton id={'btnAddLog'} style={{width: '150px'}} onClick={() => {
                                SaveLogComment()
                            }} color='blue' className={"background-transaparent"} text={'Add Log'}  disabled={false}/>
                            <OutLineButton id={'btnActivityLog'} style={{width: '150px'}} onClick={() => {
                                window.location.href = '/welcome'
                            }} color='blue' className={"background-transaparent"} text={'Activity Log'}  disabled={false}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h2 style={{display: 'inline-flex'}}>Notes History</h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {HistoryDet.length > 0 ?  (<div style={{overflow: 'auto'}}>
                            <RSATable
                                head={HistoryHead}
                                body={HistoryDet}
                                //pageCount={totalPages/5}
                                //onChange={onChange}
                                //current={currentPage}
                            />
                        </div>): "No Notes"}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br/>
            <hr/>
        </React.Fragment>
    )
}

export default AdminLog
