
import Cookies from "js-cookie";

export const setSessionCookie = (session: any): void => {
    //eslint-disable-next-line
    //debugger
    Cookies.remove("session");
    console.log("session", session);
    const inFiveMinutes = new Date(new Date().getTime() + 20 * 60 * 1000);
    if (session) {
        Cookies.set("session", JSON.stringify(session), { expires: inFiveMinutes, sameSite: "strict" });
    }


};

export const getSessionCookie: any = () => {
    const sessionCookie = Cookies.get("session");

    if (sessionCookie === undefined) {
        return null;
    } else {
        return JSON.parse(sessionCookie);
    }
};

export const removeSessionCookie = (): void => {
    Cookies.remove("session");
}