import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import moment from "moment";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd/es/pagination";
import tags from "../../../../models/common/tag";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import NameWithDate from "../../../../framework/widgets/NameWithText/NameWithDate";
import NameWithRadioButton from "../../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import { fetchAllCourses } from "./CourseManagementController";
import { CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";

const CoursesClassificationContainer = () =>{
    const session = getSessionCookie();
    const CourseId = new URLSearchParams(location.search).get('id');
    const [title, setTtitle] = useState('Add Course')
    useEffect(() => {
        if(CourseId){
            setTtitle('Edit Course')
        }else{
            setTtitle('Add Course')
        }
    },[])
    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={title}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AddEditCourse/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default CoursesClassificationContainer


const AddEditCourse = () => {
    const CourseId = new URLSearchParams(location.search).get('id');
    const [state, SetState] = useState<CourseManagementRes>();
    useEffect(() => {
        if(CourseId){
            const fetchCourses = fetchAllCourses(parseInt(CourseId),-1)
            Promise.resolve(fetchCourses).then((value) => {
                if(value){
                    SetState(value.find(r => r.courseId ==  parseInt(CourseId)))
                }
            });
        }
    },[])
    return(
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course provider'} 
                        labelPosition={Position.Top}
                        id={'txtCourseprovider'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course provider'}
                        required={true}
                        value={state?.providerName}
                        onChange={(e:any)=>{
                            SetState({...state, providerName:e.target.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course reference ID'} 
                        labelPosition={Position.Top}
                        id={'txtCoursereferenceID'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course reference ID'}
                        required={true}
                        value={state?.courseReferenceId}
                        onChange={(e:any)=>{
                            SetState({...state, courseReferenceId:e.target.value})
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course name'} 
                        labelPosition={Position.Top}
                        id={'txtCoursename'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course name'}
                        required={true}
                        value={state?.courseName}
                        onChange={(e:any)=>{
                            SetState({...state, courseName:e.target.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <p><b>Classifications:</b></p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDropDown 
                        clearable={true} 
                        selection={true} 
                        labelText={'Classification'}
                        labelPosition={Position.Top} 
                        id={'ddClassification'}
                        placeholder={'Select Classification'}
                        required={true}
                        options={[]}
                        value={state?.flag}
                        onChange={(e:any,data:any)=>{
                            SetState({...state, flag:data.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDate
                        labelText={'From Date'}
                        fluid={true}
                        labelPosition={Position.Top}
                        id={'txtFDate'}
                        type={'date'}
                        required={true}
                        value={state?.courseStartDate}//moment(state?.courseStartDate).format('DD/MM/YYYY')}
                        // onError={courseDateError}
                        onChange={(e: any, data: any) => {/*e.preventDefault();*/
                            SetState({...state, courseStartDate: data.value})
                            // if(data.value.toString().length > 7 && moment(data.value,'DD/MM/YYYY').isAfter()){
                            //     setCourseDateError('Course Date Invalid !')
                            //     setState({...state, courseDate: ''})
                            //     return;
                            // }else {
                            //     setCourseDateError('')
                            //     setState({...state, courseDate: data.value})
                            // }
                        }}
                        // onLostFocus={(e: any, data: any) => {/*e.preventDefault();*/
                        //     if(moment(data.value,'DD/MM/YYYY').isAfter()){
                        //         setCourseDateError('Course Date Invalid !')
                        //         setState({...state, courseDate: ''})
                        //         return;
                        //     }else {
                        //         setCourseDateError('')
                        //         setState({...state, courseDate: data.value})
                        //     }
                        // }}
                        // disabled={!isEnable}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                <NameWithDate
                        labelText={'To Date'}
                        fluid={true}
                        labelPosition={Position.Top}
                        id={'txtTDate'}
                        type={'date'}
                        required={true}
                        value={state?.courseEndDate}//moment(state?.courseEndDate).format('DD/MM/YYYY')}
                        //value={state?.courseDate}//moment(state?.courseDate).format('DD/MM/YYYY')}
                        // onError={courseDateError}
                        onChange={(e: any, data: any) => {/*e.preventDefault();*/
                            SetState({...state, courseEndDate: data.value})
                            // if(data.value.toString().length > 7 && moment(data.value,'DD/MM/YYYY').isAfter()){
                            //     setCourseDateError('Course Date Invalid !')
                            //     setState({...state, coursEndeDate: ''})
                            //     return;
                            // }else {
                            //     setCourseDateError('')
                            //     setState({...state, courseDate: data.value})
                            // }
                        }}
                        // onLostFocus={(e: any, data: any) => {/*e.preventDefault();*/
                        //     if(moment(data.value,'DD/MM/YYYY').isAfter()){
                        //         setCourseDateError('Course Date Invalid !')
                        //         setState({...state, courseDate: ''})
                        //         return;
                        //     }else {
                        //         setCourseDateError('')
                        //         setState({...state, courseDate: data.value})
                        //     }
                        // }}
                        // disabled={!isEnable}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={16}>
                    <span style={{display: 'inline-flex'}}>Course status</span>
                </Grid.Column>
                <Grid.Column width={16}>&nbsp;</Grid.Column>
                <Grid.Column width={1} mobile={4}>
                    <NameWithRadioButton
                        id={'rbYes'}
                        label={'Active'}
                        name={'rbAudit'}
                        value={state?.isActivated}
                        onChange={() => {
                            SetState({...state, isActivated: true})
                        }}
                        // disabled={isEditCourse}
                    />
                </Grid.Column>
                <Grid.Column width={1} mobile={4}>
                    <NameWithRadioButton
                        id={'rbNo'}
                        label={'Inactive'}
                        name={'rbAudit'}
                        value={!state?.isActivated}
                        onChange={() => {
                            SetState({...state, isActivated : false})
                        }}
                        // disabled={isEditCourse}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                    <Grid.Column mobile={16} tablet={8} widescreen={12} largeScreen={12} computer={12}>

                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={5} widescreen={4} largeScreen={4}
                                 style={{justifyContent: 'center', display: 'flex'}}>
                        <ControllButtonReg
                            AddCourse={()=>{console.log('')}}
                            Back={()=>{window.location.href = '/misc/coursemanagement'}}
                        />
                    </Grid.Column>
                </Grid.Row>
        </Grid>
    );
}

interface ControllButtonRegProps {
    //eslint-disable-next-line
    AddCourse: any;
    //eslint-disable-next-line
    Back: any;
}

const ControllButtonReg = (props: ControllButtonRegProps) => {
    return (
        <div className={'button-flex'}>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
               props.Back()
            }} color={'red'} className={"background-transaparent-red"}
                            text={'Back'}/>
            <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                  props.AddCourse()
            }} color='blue' className={"background-transaparent"}
                        text={'Add Course'}
                        disabled={false}/>
        </div>
    )
}