import React, {useEffect, useState} from "react";
import {Button, Grid} from "semantic-ui-react";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import {HashLink} from "react-router-hash-link";
import {AuditorRegionValueProps} from "../profile/DataModal";

interface Props{
    myReagion?:AuditorRegionValueProps
}
const CurrentAvaiability = ({myReagion}:Props) => {

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column largeScreen={10} widescreen={12} computer={10} tablet={10} mobile={16}>
                        <h3 style={{display: 'inline-flex', color:"#1559A3"}}>Current Availability</h3>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16}>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <Button basic outline={1}  content='blue' id={'cmbmyAvailability'} style={{width:'150px'}} color='blue' className={"background-transaparent"}><HashLink to={"/accriditation#availability"} style={{width:'100px', display:'inline-block'}}>My Availability</HashLink></Button>
                        {/*<OutLineButton id={'cmbmyAvailability'} onClick={toggleModel} color='blue' className={"background-transaparent"} text={'My Availability'} />*/}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid>
                            <Grid.Row columns={3}>
                                {myReagion?.greateSyndey == true || myReagion?.west == true || myReagion?.south == true || myReagion?.north == true ? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <label>New South Wales</label>
                                    <Grid>
                                        <Grid.Row columns={1}>
                                            {myReagion?.greateSyndey? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <p>Greater Sydney</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.north? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <p>North</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.south? ( <Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <p>South</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.west? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <p>West</p>
                                            </Grid.Column>) : null}
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>) : null}
                                {myReagion?.act == true || myReagion?.sa == true || myReagion?.overseas == true || myReagion?.tas == true || myReagion?.qld == true || myReagion?.nt == true || myReagion?.wa == true || myReagion?.vic == true? (<Grid.Column width={10} widescreen={10} computer={10} tablet={16} mobile={16}>
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            {myReagion?.act? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Australia Capital Region</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.sa? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>South Australia</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.overseas? ( <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Overseas</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.tas? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Tasmania</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.qld? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Queensland</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.nt? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Northern Territory</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.wa? ( <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Western Australia</p>
                                            </Grid.Column>) : null}
                                            {myReagion?.vic? (<Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                                                <p>Victoria</p>
                                            </Grid.Column>) : null}
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>) : null}
                            </Grid.Row>

                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}

export default CurrentAvaiability