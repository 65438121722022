import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import moment from "moment";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd/es/pagination";
import tags from "../../../../models/common/tag";
import { CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";
import FileUpload from "../../../../framework/components/FileUpload";
import { anyFileValidation, resumeValidation, sizeValidation } from "../../../../framework/validators/fileValidator";
import { FileUploadValidator, calculateStorage } from "../../../../framework/common/SupportMobules";
import FileProps from "../../../../framework/types/FileProps";
import Position from "../../../../framework/types/Position";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../../../framework/widgets/NameWithText/NameWithEmail";
import DefaultTextBox from "../../../../framework/components/TextBoxes/DefaultTextBox";
import EmailInputBox from "../../../../framework/components/TextBoxes/EmailInputBox";
import { SearchReq } from "../../../../models/apiRequest/Auditor/SearchReq";
import { SearchApiCall } from "../../../search/SearchController";
import { AuditSearchResRow, AuditorSearchResRow, SearchRes } from "../../../../models/apiResponce/Auditor/SearchRes";

const UserLoginNameChangeContainer = () =>{

    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [newEmail, setnewEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [selectEmail, setSelectEmail] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Name"
        },
        {
            text: "Login email"
        },
        {
            text: "Auditor ID"
        }
        ,
        {
            text: ""
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])



    const SetValues = (data:AuditorSearchResRow[]) => {
        const TableRows: object[] = [];
        data.map((r:AuditorSearchResRow) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.fullName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.email}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.newAuditorId,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "edit",
                    onclick:() => {
                        setSelectEmail(r.email)
                    }
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const searchData = SearchApiCall({...state,pageNumStartingZero:page} as SearchReq)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows-1)
                SetValues(value.auditorSearchRess)
            }

        })
        setCurrent(page);
    };

    const onSearchClick = () => {
        const searchData = SearchApiCall(state)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows-1)
                SetValues(value.auditorSearchRess)
            }

        })
    }


    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"User Login Name Change"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>  
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2} style={{verticleAlign:'middle'}}>
                                    <span style={{display:'inline-flex'}}>Email address</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <EmailInputBox
                                            id={'txtEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter Email address'}
                                            value={state.email}
                                            onChange={(e: any) => {
                                                setState({...state,email:e.target.value})
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2}>
                                    <span style={{display:'inline-flex'}}>Name</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <DefaultTextBox
                                            id={'txtName'} 
                                            fluid={true} 
                                            placeholder={'Enter Name'}
                                            value={state.name}
                                            onChange={(e: any) => {
                                                setState({...state,name:e.target.value})
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                onSearchClick()
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Search'}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {rsaCourseDet.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <RSATable
                            head={rsaCourseHead}
                            body={rsaCourseDet}
                            current={current}
                            pageCount={totalRecords/ (state.pageSize ? state.pageSize : 10)}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectEmail.length > 0 ? (<>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2}>
                                    <b><span style={{display:'inline-flex'}}>Change Panel</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>Current email address</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span style={{display:'inline-flex'}}>{selectEmail}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>New email address</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <EmailInputBox
                                            id={'txtName'} 
                                            fluid={true} 
                                            placeholder={'Enter New email address'}
                                            value={newEmail}
                                            onChange={(e: any) => {
                                                setnewEmail(e.target.value)
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>Confirm new email address</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <EmailInputBox
                                            id={'txtName'} 
                                            fluid={true} 
                                            placeholder={'Enter Confirm new email address'}
                                            value={confirmEmail}
                                            onChange={(e: any) => {
                                               
                                                setConfirmEmail(e.target.value)
                                            }}
                                            onpaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                alert('')
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Save'}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
    );
}

export default UserLoginNameChangeContainer
