import React, {useEffect, useState} from "react";
import {Button, Grid, Popup, Radio} from "semantic-ui-react";
import IconTextOutlineButton from "../../../framework/components/Buttons/IconTextOutlineButton";
import RadialBarChart from "../../../framework/components/Chart/Radial";
import Modal from "../../../framework/components/Modals";
import AddNewAudit from "../profile/AddNew Audit";
import {fetchAuditStages, fetchStates} from "../profile/ProfileController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import {HashLink} from "react-router-hash-link";
import {AuditorAuditRess} from "../../../models/apiResponce/Auditor/AuditorAuditRess";
import {padTo2Digits} from "../../../framework/common/SupportMobules";

interface Props {
    audits?: AuditorAuditRess[]
}

const AuditSummery = ({audits}: Props) => {

    const colors = ({name}: any) => {
        switch (name) {
            case 'Strategic':
                return '#838383';
            case 'Concept':
                return '#794c4c';
            case 'Detailed':
                return '#5b4c79';
            case 'Roadworks':
                return '#f2ba52';
            case 'Pre-opening':
                return '#4ab0d9';
            case 'Finalisation':
                return '#315669';
            case 'Existing Road':
                return '#b7321a';
            case 'Thematic':
                return '#5b794c';
        }
        return '#ff4d4f';
    }

    const colors2 = ({name}: any) => {
        switch (name) {
            case 'Pre-construction':
                return '#b21231';
            case 'Construction':
                return '#215ba6';
            case 'Post-construction':
                return '#2685bf';
        }
        return '#ff4d4f';
    }

    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [auditStages, setAuditStages] = useState<any>();
    const [states, setStates] = useState<any>();
    const [tfNSWVerified, setTfNSWVerified] = useState(0)
    const [nonTfNSWVerified, setNonTfNSWVerified] = useState(0)
    const [pendingVerified, setPendingVerified] = useState(0)
    const [reviewVerified, setReviewVerified] = useState(0)

    //Chart Data
    const [preCon, setPreCon] = useState(0)
    const [con, setCon] = useState(0)
    const [postCon, setPostCon] = useState(0)

    //Chart 2
    const [strategic, setStrategic] = useState(0)
    const [concept, setConcept] = useState(0)
    const [detailed, setDetailed] = useState(0)
    const [roadworks, setRoadWork] = useState(0)
    const [preopening, setPreOpening] = useState(0)
    const [finalisation, setFinalization] = useState(0)
    const [existingRoad, setExistingRoad] = useState(0)
    const [thematic, setThermatic] = useState(0)
    // Get the current date
    const today = new Date();

    // Subtract three years from the current date
    const threeYearsAgo = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());

    const session = getSessionCookie();
    useEffect(() => {
        setStates(fetchStates());
        setAuditStages(fetchAuditStages());
    }, []);

    const toggleModel = (e: any, refresh = true) => {
        //eslint-disable-next-line
        //debugger;

        if(refresh){
            //alert('Hi')
            window.location.href = '/welcome'
        }
        setOpen(!open)
    }

    useEffect(() => {
        if (audits != undefined && audits.length > 0) {
            SetValuestoVariables(audits)
        }
    }, [audits, toggle])

    const SetValuestoVariables = (audit: AuditorAuditRess[]) => {
        let tfnsw = 0;
        let nontfnsw = 0;
        let pending = 0;
        let review = 0;

        let pre = 0;
        let c = 0;
        let post = 0;

        let strat = 0;
        let concept = 0;
        let detailed = 0;
        let roadwork = 0;
        let preOpenning = 0;
        let finalisation = 0;
        let existingRoad = 0;
        let thematic = 0;


        for (let i = 0; i < audit.length; i++) {
            if (audit[i] != undefined) {
                if (toggle == false && session.auditorLevel != '1') {
                    if (new Date(audit[i].auditFinalSignoffDate) >= threeYearsAgo) {
                        switch (audit[i].auditStatusId) {
                            case 1 :
                                pending += 1;
                                break;
                            case 2:
                                review += 1;
                                break;
                            case 3:
                                if (audit[i].isTfNswProject == true) {
                                    tfnsw += 1;
                                } else {
                                    nontfnsw += 1;
                                }
                                break;
                            case 5:
                                break;
                        }

                        if (audit[i].auditStatusId == 3) {
                            switch (audit[i].auditPhaseId) {
                                case 1:
                                    pre += 1;
                                    break;
                                case 2:
                                    c += 1;
                                    break;
                                case 3:
                                    post += 1
                                    break;
                            }

                            switch (audit[i].stageId) {
                                case 1:
                                    strat += 1
                                    break;
                                case 2:
                                    concept += 1
                                    break;
                                case 3:
                                    detailed += 1
                                    break;
                                case 4:
                                    roadwork += 1
                                    break;
                                case 5:
                                    preOpenning += 1
                                    break;
                                case 6:
                                    finalisation += 1
                                    break;
                                case 8:
                                    existingRoad += 1
                                    break;
                                case 9:
                                    thematic += 1
                                    break;
                            }
                        }
                    }

                } else {
                    switch (audit[i].auditStatusId) {
                        case 1 :
                            pending += 1;
                            break;
                        case 2:
                            review += 1;
                            break;
                        case 3:
                            if (audit[i].isTfNswProject == true) {
                                tfnsw += 1;
                            } else {
                                nontfnsw += 1;
                            }
                            break;
                        case 5:
                            break;
                    }

                    if (audit[i].auditStatusId == 3) {
                        switch (audit[i].auditPhaseId) {
                            case 1:
                                pre += 1;
                                break;
                            case 2:
                                c += 1;
                                break;
                            case 3:
                                post += 1
                                break;
                        }

                        switch (audit[i].stageId) {
                            case 1:
                                strat += 1
                                break;
                            case 2:
                                concept += 1
                                break;
                            case 3:
                                detailed += 1
                                break;
                            case 4:
                                roadwork += 1
                                break;
                            case 5:
                                preOpenning += 1
                                break;
                            case 6:
                                finalisation += 1
                                break;
                            case 8:
                                existingRoad += 1
                                break;
                            case 9:
                                thematic += 1
                                break;
                        }
                    }
                }

            }
        }
        setTfNSWVerified(tfnsw)
        setNonTfNSWVerified(nontfnsw)
        setPendingVerified(pending)
        setReviewVerified(review)
        setPreCon(pre)
        setCon(c)
        setPostCon(post)
        setStrategic(strat)
        setConcept(concept)
        setDetailed(detailed)
        setRoadWork(roadwork)
        setPreOpening(preOpenning)
        setFinalization(finalisation)
        setExistingRoad(existingRoad)
        setThermatic(thematic)
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column widescreen={10} largeScreen={7} computer={7} tablet={8} mobile={16}>
                        <h3 style={{display: 'inline-flex', color: "#1559A3"}}>{toggle ? 'All' : 'Valid'} Audits Summary
                            - &nbsp;&nbsp;
                            <Popup
                                flowing
                                hoverable
                                trigger={
                                    <Radio toggle checked={toggle} onChange={() => setToggle(!toggle)}/>
                                }
                            >
                                <Popup.Header>{/*toggle ? 'Valid Audits' : 'All Audits'*/ 'Toggle'}</Popup.Header>
                                <Popup.Content >
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p>{toggle ? session.auditorLevel == '1' ? 'Toggle off to view valid Audits ' : `Toggle off to view all valid Audits participated in the last 3 years` : 'Toggle on to view all Audits'}</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Popup.Content>
                            </Popup>
                        </h3>
                    </Grid.Column>
                    <Grid.Column widescreen={2} largeScreen={3} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <IconTextOutlineButton id={'cmbAddAudits'} style={{width: '150px'}} icon='add'
                                               onClick={(e: any) => {
                                                   toggleModel(e,false)
                                               }} color='blue' className={"background-transaparent"} text={'Add Audits'}
                                               disabled={session.status == 4}/>
                    </Grid.Column>
                    <Grid.Column widescreen={2} largeScreen={3} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <Button basic outline={1} style={{width: '150px',marginRight:'12px'}} content='blue' id={'cmbmyAudits'}
                                color='blue' className={"background-transaparent"}><HashLink style={{width:'100px', display:'inline-block'}}
                            to={"/accriditation#audits"}>My Audits</HashLink></Button>
                    </Grid.Column>
                    <Grid.Column widescreen={2} largeScreen={3} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <IconTextOutlineButton style={{width: '150px', marginLeft:'0px'}} id={'cmbSearchAudits'} icon='search'
                                               onClick={() => {
                                                   window.location.href = '/audits'
                                               }} color='blue' className={"background-transaparent"}
                                               text={'Search Audits'}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} tablet={16} computer={8} widescreen={8}>
                        <h4 style={{textAlign: 'center'}}>Project Phases (Verified)</h4>
                        {preCon == 0 && con == 0 && postCon == 0 ? (
                            <h4 style={{textAlign: 'center', color: '#757575'}}>None</h4>) : (<RadialBarChart
                            data={[
                                {
                                    name: 'Pre-construction',
                                    audits: preCon,
                                },
                                {
                                    name: 'Construction',
                                    audits: con,
                                },
                                {
                                    name: 'Post-construction',
                                    audits: postCon,
                                }
                            ]}
                            innerRadius={0.6}
                            colors={colors2}
                        />)}
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} tablet={16} computer={8} widescreen={8}>
                        <h4 style={{textAlign: 'center'}}>Audit Stages (Verified)</h4>
                        {strategic <= 0 && concept <= 0 && detailed <= 0 && roadworks <= 0 && preopening <= 0 && finalisation <= 0 && existingRoad <= 0 && thematic <= 0 ?
                            (<h4 style={{textAlign: 'center', color: '#757575'}}>None</h4>) :
                            (<RadialBarChart
                                data={[
                                    {
                                        name: 'Strategic',
                                        audits: strategic,
                                    },
                                    {
                                        name: 'Concept',
                                        audits: concept,
                                    },
                                    {
                                        name: 'Detailed',
                                        audits: detailed,
                                    },
                                    {
                                        name: 'Roadworks',
                                        audits: roadworks,
                                    },
                                    {
                                        name: 'Pre-opening',
                                        audits: preopening,
                                    },
                                    {
                                        name: 'Finalisation',
                                        audits: finalisation,
                                    },
                                    {
                                        name: 'Existing Road',
                                        audits: existingRoad,
                                    },
                                    {
                                        name: 'Thematic',
                                        audits: thematic,
                                    }
                                ]}
                                innerRadius={0.1}
                                colors={colors}
                            />)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <h4> TfNSW Audits Verified</h4>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <h4 style={{textAlign: 'right'}}>{padTo2Digits(tfNSWVerified)}</h4>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <h4> Non-TfNSW Audits Verified</h4>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <h4 style={{textAlign: 'right'}}>{padTo2Digits(nonTfNSWVerified)}</h4>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <h4> Pending to be Verified</h4>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <h4 style={{textAlign: 'right'}}>{padTo2Digits(pendingVerified)}</h4>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <h4> Pending in Review Process</h4>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <h4 style={{textAlign: 'right'}}>{padTo2Digits(reviewVerified)}</h4>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={open ? open : false} modalAction={false} setOpen={setOpen} title={"Add Audit Details"}
                   ModalBody={() => (
                       <AddNewAudit
                           states={states}
                           auditStages={auditStages}
                           auditId={null}
                           toggleModel={toggleModel}
                           isEdit={true}
                           auditorId={session.auditorId}
                           setAuditor={null}
                       />
                   )}/>
        </React.Fragment>
    )
}

export default AuditSummery;