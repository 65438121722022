import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import moment from "moment";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd/es/pagination";
import tags from "../../../../models/common/tag";
import { fetchAllCourses } from "./CourseManagementController";
import { CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";

const CoursesContainer = () =>{
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "ID"
        },
        {
            text: "Course provider"
        },
        {
            text: "Course name"
        },
        {
            text: "Status"
        }
        ,
        {
            text: ""
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchCourses = fetchAllCourses(-1,-1)
        Promise.resolve(fetchCourses).then((value) => {
            setTotalRecords(value.length)
            if(value){
                SetValues(value)
            }
        });
    },[])


    const SetValues = (data:CourseManagementRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.courseReferenceId,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.providerName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.courseName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.isActivated ? 'Active' : 'Inactive',
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "View Course",
                    icon: "view",
                    onclick:() => {
                        window.location.href = `/misc/courseclassification?id=${r.courseId}&type=${r.flag}`
                    }
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        
        setCurrent(page);
    };

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Courses"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>                       
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p><b> Search results : found {totalRecords} results </b></p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnAddCourse'} className={"background-transaparent"}
                                                   style={{width: '200px', height:'36px'}} onClick={(e:any) => {
                                                   window.location.href = '/misc/courseclassification'
                                    }} color={'blue'} text={'Add Course'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <RSATable
                            head={rsaCourseHead}
                            body={rsaCourseDet}
                            current={current}
                            pageCount={totalRecords/totalRecords}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default CoursesContainer
