import React, {useEffect, useState} from "react";
import {Container, Grid,Menu} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import TabPane from "../../../../framework/widgets/Tabs/TabPane";
import TabWithIcon from "../../../../framework/widgets/Tabs/TabWithIcon";
import {Imenu} from "../../../../framework/types/TabInterface";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd";
import { AdminLogRes, AssesmentLogsRes, LevelChangelogRes, StatusChangeLogRes, UploadLogsRes } from "../../../../models/apiResponce/mis/LogsRes";
import tags from "../../../../models/common/tag";
import { fetchAdmintLogs, fetchAssesmentLogs, fetchDownloadLogs, fetchLevelChangeLogs, fetchStatustLogs, fetchUploadsLogs } from "./ActivityLogController";
import moment from "moment";

const ActivityLogContainer = () =>{
    const session = getSessionCookie();
    const [active,setActive] = useState('admin')
    const panes = [
        {
            menuItem: (
                <Menu.Item key='admin' onClick={()=>{setActive('admin')}} style={{ width:'200px', justifyContent:'center' /*position:'unset'*/}}>
                    Admin Notes
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <AdminNotes/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='status' onClick={()=>{setActive('status')}} style={{width:'200px', justifyContent:'center' /*position:'unset'*/}}>
                    Status Update
                </Menu.Item>
            ),
            render: () => <TabPane attached={true} body={() => <StatusUpdate/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='level' onClick={()=>{setActive('level')}} style={{ width:'200px', justifyContent:'center' /*position:'unset'*/}} >
                    Level Changes
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <LevelChanges/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='Assessment' onClick={()=>{setActive('assessment')}} style={{ width:'200px', justifyContent:'center' /*position:'unset'*/}} >
                    Assessment
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <Assessment/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='uploads' onClick={()=>{setActive('uploads')}} style={{width:'200px', justifyContent:'center' /*position:'unset'*/}} >
                    Uploads
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <Uploads/>}/>,
        },
    ]
    const menu: Imenu = {
        attached: false,
        inverted: false,
        pointing: true,
        tabular: false,
        style:{
            display: "flex",
            justifyContent: "center",
            border:'none',
            boxShadow:'none'
        },
        className: "wrapped mobile-width"
    }


    const handleDownloadClick = async() => {
        let reportType:number;
        let fileName:string;
        const today = new Date();
        switch (active) {
            case 'admin':
                reportType = 1
                fileName = `ActivityLog - Admin Notes(${moment(today).format("DD-MM-YYYY")}).csv`
                DownloadReport(reportType, fileName)
                break;
            case 'status':
                reportType = 2
                fileName = `ActivityLog - Status Update(${moment(today).format("DD-MM-YYYY")}).csv`
                DownloadReport(reportType, fileName)
                break;
            case 'level':
                reportType = 3
                fileName = `ActivityLog - Level Changes(${moment(today).format("DD-MM-YYYY")}).csv`
                DownloadReport(reportType, fileName)
                break;
            case 'assessment':
                reportType = 4
                fileName = `ActivityLog - Assessment(${moment(today).format("DD-MM-YYYY")}).csv`
                DownloadReport(reportType, fileName)
                break;
            case 'uploads':
                reportType = 5
                fileName = `ActivityLog - Uploads(${moment(today).format("DD-MM-YYYY")}).csv`
                DownloadReport(reportType, fileName)
                break;
        }
        
    }

    const DownloadReport = async(repotType:number, fileName:string) => {
        const report = await fetchDownloadLogs(repotType)
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Activity Log"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <TabWithIcon panes={panes} menu={menu}/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className='button-flex'>
                        <OutLineButton 
                            id={'btnExport'} 
                            className={"background-transaparent"}
                            style={{width: '150px', height:'36px'}} 
                            onClick={handleDownloadClick} 
                            color={'blue'} 
                            text={'Export'}
                        />

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default ActivityLogContainer


const AdminNotes = () => {
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Created Date"
        },
        {
            text: "Auditor Profile"
        },
        {
            text: "Created By"
        },
        {
            text: "Notes"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchAdminLogsList = fetchAdmintLogs()
        Promise.resolve(fetchAdminLogsList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
    },[])

    const SetValues = (data:AdminLogRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: moment(r.createDate).format("DD/MM/YYYY"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorProfile,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.createdBy,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.logDescription,
                    icon: "",
                    onclick: null
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const fetchAdminLogsList = fetchAdmintLogs(undefined, page)
        Promise.resolve(fetchAdminLogsList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
        setCurrent(page);
    };

    return (
        <RSATable
            head={rsaCourseHead}
            body={rsaCourseDet}
            current={current}
            pageCount={totalRecords/10}
            onChange={onChange}
        />
    )
}

const StatusUpdate = () => {
    const session = getSessionCookie();
    const [totalRecords, setTotalRecords] = useState(0)
    const [current, setCurrent] = useState(1);
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Update Date"
        },
        {
            text: "Auditor Profile"
        },
        {
            text: "Update By"
        },
        {
            text: "Role"
        }
        ,
        {
            text: "Status Name"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchStatusLogsList = fetchStatustLogs()
        Promise.resolve(fetchStatusLogsList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
    },[])

    const SetValues = (data:StatusChangeLogRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: moment(r.updatedOn).format("DD/MM/YYYY"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorProfile,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.updatedBy,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.role,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.statusName,
                    icon: "",
                    onclick: null
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const fetchStatusLogsList = fetchStatustLogs(undefined, page)
        Promise.resolve(fetchStatusLogsList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
        setCurrent(page);
    };

    return (
        <RSATable
            head={rsaCourseHead}
            body={rsaCourseDet}
            current={current}
            pageCount={totalRecords/10}
            onChange={onChange}
        />
    )
}

const LevelChanges = () => {
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Create Date"
        },
        {
            text: "Auditor Profile"
        },
        {
            text: "Triggered By"
        },
        {
            text: "Role"
        }
        ,
        {
            text: "Description"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchLevelList = fetchLevelChangeLogs()
        Promise.resolve(fetchLevelList).then((value) => {

            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
    },[])

    const SetValues = (data:LevelChangelogRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: moment(r.createdDate).format("DD/MM/YYYY"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorProfile,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.triggeredBy,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.role,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.description,
                    icon: "",
                    onclick: null
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const fetchLevelList = fetchLevelChangeLogs(undefined, page)
        Promise.resolve(fetchLevelList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
        setCurrent(page);
    };

    return (
        <RSATable
            head={rsaCourseHead}
            body={rsaCourseDet}
            current={current}
            pageCount={totalRecords/10}
            onChange={onChange}
        />
    )
}

const Assessment = () => {
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Create Date"
        },
        {
            text: "Auditor Profile"
        },
        {
            text: "Sender"
        },
        {
            text: "Receiver"
        }
        ,
        {
            text: "Description"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchAssesmentList = fetchAssesmentLogs()
        Promise.resolve(fetchAssesmentList).then((value) => {

            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
    },[])

    const SetValues = (data:AssesmentLogsRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: moment(r.createdDate).format("DD/MM/YYYY"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorProfile,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.sender,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.receiver,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.description,
                    icon: "",
                    onclick: null
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const fetchAssesmentList = fetchAssesmentLogs(undefined, page)
        Promise.resolve(fetchAssesmentList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
        setCurrent(page);
    };

    return (
        <RSATable
            head={rsaCourseHead}
            body={rsaCourseDet}
            current={current}
            pageCount={totalRecords/10}
            onChange={onChange}
        />
    )
}

const Uploads = () => {
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Upload Date"
        },
        {
            text: "Auditor Profile"
        },
        {
            text: "Upload By"
        },
        {
            text: "Upload Type"
        }
        ,
        {
            text: "Upload File"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<object[]>([
    ])

    useEffect(() => {
        const fetchUploadList = fetchUploadsLogs()
        Promise.resolve(fetchUploadList).then((value) => {

            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
    },[])

    const SetValues = (data:UploadLogsRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: moment(r.uploadDate).format("DD/MM/YYYY"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorProfile,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.uploadedBy,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.uploadType,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.originalFilename,
                    icon: "",
                    onclick: null
                }])
        })
        setRSACourseDet(TableRows);
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        const fetchUploadList = fetchUploadsLogs(undefined, page)
        Promise.resolve(fetchUploadList).then((value) => {
            if(value){
                setTotalRecords(value.totalRecords)
                SetValues(value.result)
            }
        });
        setCurrent(page);
    };

    return (
        <RSATable
            head={rsaCourseHead}
            body={rsaCourseDet}
            current={current}
            pageCount={totalRecords/10}
            onChange={onChange}
        />
    )
}