import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import Position from "../../../../framework/types/Position";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import moment from "moment";
import {fetchAssessmentProcessStats, fetchAssessmentProcessStatsDownload, fetchAssessmentProcessStatsPerAssessor, fetchAssessmentProcessStatsPerAssessorDownload} from "./AssesmentStatusController";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { AssessmentProcessStatsRes, AssessmentProcessStatsperAssessorRes } from "../../../../models/apiRequest/mis/AssesmentProcess";
import tags from "../../../../models/common/tag";

const AssesmentStatusContainer = () =>{
    const session = getSessionCookie();
    const [recoord, setRecord] = useState<string>()
    const [dateRange, setDateRange] = useState('')
    const [tableHead, setTableHead] = useState<TableHeader[]>([])
    const AssesStatHead: TableHeader[] = [
        {
            text: "Description"
        },
        {
            text: "Shortest Time Taken"
        },
        {
            text: "Longest Time Taken"
        }
        ,
        {
            text: "Avg. Time Taken"
        }
    ];
    const AssesStatPerAssesorHead: TableHeader[] = [
        {
            text: "Assessor ID"
        },
        {
            text: "Assessor Name"
        },
        {
            text: "Shortest Time Taken"
        },
        {
            text: "Longest Time Taken"
        }
        ,
        {
            text: "Avg. Time Taken"
        }
    ];

    const [tableDet, setTableDet] = useState<object[]>([])

    const SetAssesStatusValues = (data:AssessmentProcessStatsRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.description,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.shortestTime,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.longestTime,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.avgTime,
                    icon: "",
                    onclick: null
                }])
        })
        setTableHead(AssesStatHead);
        setTableDet(TableRows);
    }

    const SetAssesStatusAssessorValues = (data:AssessmentProcessStatsperAssessorRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.assessorID,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.assessorName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.shortestTime,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.longestTime,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.avgTime,
                    icon: "",
                    onclick: null
                }])
        })
        setTableHead(AssesStatPerAssesorHead);
        setTableDet(TableRows);
    }

    const AssessmentProcessStats = async()=>{
        const dates = dateRange.split('-')
        const fetchResult = fetchAssessmentProcessStats(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString())
        Promise.resolve(fetchResult).then((value) => {
            if(value){
                SetAssesStatusValues(value.result)
            }
        });
    }

    const AssessmentProcessStatsPerAssessor = async()=>{
        const dates = dateRange.split('-')
        const fetchResult = fetchAssessmentProcessStatsPerAssessor(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString())
        Promise.resolve(fetchResult).then((value) => {
            if(value){
                SetAssesStatusAssessorValues(value.result)
            }
        });
    }

    const AssessmentProcessStatsDownload = async () =>{
        const dates = dateRange.split('-')
        const today = new Date();
        const report = await fetchAssessmentProcessStatsDownload(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString());
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Assessment Process Stats - (${moment(today).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const AssessmentProcessStatsPerAssessorDownload = async () =>{
        const dates = dateRange.split('-')
        const today = new Date();
        const report = await fetchAssessmentProcessStatsPerAssessorDownload(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString());
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Assessment Process Stats(Per Assessor) -${moment(today).format("DD-MM-YYYY")}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Statistics for Assessment Process"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p>Please specify date ranges and click on &lsquo;Download&lsquo; to get the statistics for the assessment process for the given period.</p>
                        <p>Please leave &lsquo;From&lsquo; date and &lsquo;To&lsquo; date blank and click on &lsquo;Download&lsquo;, to get the statistics for the assessment process for entire history.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        <DateRangeBox
                                            id={'txtFromDate'}
                                            fluid={true}
                                            placeholder={'From Date - To Date'}
                                            onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                            value={dateRange}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                       
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={10} mobile={16} computer={10}>
                                    Time frame for the Assessment process(by status change)
                                </Grid.Column>
                                <Grid.Column width={2} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={AssessmentProcessStats} color={'blue'} text={'Process'}/>
                                </Grid.Column>
                                <Grid.Column width={3} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadAssessmentProcessStatsReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    AssessmentProcessStatsDownload()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={10} mobile={16} computer={10}>
                                    Time frame for the Assessment process(Per Assessor)
                                </Grid.Column>
                                <Grid.Column width={2} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnAssessmentProcessStats'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={AssessmentProcessStatsPerAssessor} color={'blue'} text={'Process'}/>
                                </Grid.Column>
                                <Grid.Column width={3} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnAssessmentProcessStatsDownloadReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    AssessmentProcessStatsPerAssessorDownload()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {tableDet.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <RSATable
                            head={tableHead}
                            body={tableDet}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>) : null }
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default AssesmentStatusContainer
