import React, {useEffect, useState} from "react";
import AddNewAudit from "../auditor/profile/AddNew Audit";
import {getSessionCookie} from "../../framework/common/SessionHandler";
import {fetchAuditStages, fetchStates} from "../auditor/profile/ProfileController";
import Header from "../../framework/layouts/Header";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import AboutUsText from "../aboutus/AboutUsText";
import Footer from "../../framework/layouts/Footer";
import userRoleSelector from "../../framework/common/UserSelector";
import {Container, Grid} from "semantic-ui-react";
import SearchAudit from "./SearchAudit";
import AuditAdminLog from "./AuditAdminLog";

const PgsAudits = () => {
    const [auditStages, setAuditStages] = useState<any>();
    const [states, setStates] = useState<any>();
    const [auditID, setAuditId] = useState<string|null>(null)
    const session = getSessionCookie();

    useEffect(() => {
        setStates(fetchStates());
        setAuditStages(fetchAuditStages());
        const cUrl = window.location.href
        if (cUrl.includes('?id')) {
            const urlParams = new URLSearchParams(window.location.search);
            setAuditId(urlParams.get('id'));
        }
    }, []);

    const toggleModel =(e:any)=>{
        console.log(e)
    }

    return (
        <>
            <Header>
                <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
                    <InnerTitleBar
                        title={'Audits'}
                        usreName={''}
                        userRole={""}
                        lastLogin={""}
                        status={""}
                        level={""}
                        certExpDate={""}
                        newId={""}
                        // usreName={session.userDisplayName}
                        // userRole={userRoleSelector(session.userRoleId.toString())}
                        // lastLogin={session.lastLogin}
                        // status={session.statusName == undefined || session.statusName == '' ? 'Saved as Draft' : session.statusName}
                        // level={session.auditorLevel}
                        // certExpDate={session.certExpDate}
                        // newId={session.auditorNewId}
                    />
                    <Grid>
                        <Grid.Row></Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {auditID != null && (session.userRoleId == 1 || session.userRoleId == 4) ? (<AddNewAudit
                                    states={states}
                                    auditStages={auditStages}
                                    auditId={auditID}
                                    toggleModel={toggleModel}
                                    isEdit={true}
                                    auditorId={session.auditorId}
                                    setAuditor={null}
                                />) : (
                                    <SearchAudit
                                        states={states}
                                        auditStages={auditStages}
                                        auditId={auditID}
                                        toggleModel={toggleModel}
                                        isEdit={true}
                                        auditorId={session.auditorId}
                                        setAuditor={null}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row></Grid.Row>
                        <Grid.Row>
                            <Grid.Column>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Header>

            <Footer />

        </>
    )
}

export default PgsAudits