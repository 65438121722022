import {createMedia} from "@artsy/fresnel";
import React, {createRef, useCallback, useEffect, useState} from "react";
import {Container, Grid, Icon, Image, Label, Menu, Sidebar, Sticky} from "semantic-ui-react";
import logo from '../../assets/images/logos/logo_colorizes.png'
import TextBoxes from "../components/TextBoxes";
import Buttons from "../components/Buttons";
import {useNavigate} from "react-router-dom";

//import types
import {loginRequest} from "../../models/common/login";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
    adminMenu,
    assessorChairMenu,
    assessorMenu,
    auditorActiveMenu,
    auditorMenu,
    publicMenu,
    superAdminMenu
} from "../../common/MenuArrays";
import {ToastContainer} from "react-toastify";
import {ForgotPassword, LoadAuditor, SignIn} from "../common/Sign";
import {getSessionCookie, removeSessionCookie, setSessionCookie} from "../common/SessionHandler";
import ErrorToast from "../components/Toast/ErrorToast";
import SuccessToast from "../components/Toast/SuccessToast";
import {fetchAuditor} from "../../pages/auditor/profile/ProfileController";
import {AuditorProfile} from "../../pages/auditor/profile/DataModal";


const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});
const mediaStyles = AppMedia.createMediaStyle();
const {Media, MediaContextProvider} = AppMedia;

interface NavBarMobileProps {
    children: any,
    leftItems: any,
    onPusherClick: any,
    onToggle: any,
    rightItems: any,
    visible: boolean,
    User: loginRequest,
    setUser: any,
    handleReCaptchaVerify: any,
    logout: any,
    session: any,
    forgotPassword: any
    error: string,
    onKeyPress: any
}

const NavBarMobile = ({
                          children,
                          leftItems,
                          onPusherClick,
                          onToggle,
                          rightItems,
                          User,
                          setUser,
                          handleReCaptchaVerify,
                          visible,
                          logout,
                          session,
                          forgotPassword,
                          error,
                          onKeyPress
                      }: NavBarMobileProps) => {

    const currentURL = window.location.pathname
    return (
        <Sidebar.Pushable>
            <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={visible}
                color={'blue'}
                inverted
                direction='left'
            >
                {leftItems.map((item: any, index: number) => (
                    <Menu.Item key={index}  {...item} active={currentURL == item.href}/>
                ))}
                <hr/>
                <h2 style={{fontSize: '16px', display: 'none'}}>Login</h2>
                <div style={{margin: '10px'}}>
                    {session == null ? (<TextBoxes id={'txtUname'} type={'text'} fluid={false} placeholder={'Username'}
                                                   value={User.email}
                                                   onChange={setUser} onError={error}/>) :
                        (
                            <h3 style={{fontSize: '16px'}}>Login as:</h3>
                        )}
                </div>
                <div style={{margin: '10px'}}>
                    {session == null ? (
                        <TextBoxes id={'txtPword'} type={'password'} fluid={true} placeholder={'Password'}
                                   value={User.password} onChange={setUser} onKeydown={onKeyPress}/>) : (
                        <h4 className='gray'>{session.userName}</h4>
                    )}
                </div>
                {session == null ? (<Menu.Item key={'fgtpw'} as={'a'} onClick={forgotPassword}>
                    Forgot Password?
                </Menu.Item>) : null}
                <div className={'button-flex'}>
                    {session == null ? (<Buttons id={'btnLogin'} onClick={async () => {
                            handleReCaptchaVerify();
                        }} color='red' text={'Login'} style={{width: '150px'}}
                                                 className={"background-transaparent-red"}/>) :
                        (<Buttons id={'btnLogout'} onClick={logout} color='red' text={'Logout'} style={{width: '150px'}}
                                  className={"background-transaparent-red"}/>)}
                </div>
            </Sidebar>
            <Sidebar.Pusher
                dimmed={visible}
                onClick={onPusherClick}
                style={{minHeight: "100vh"}}
            >
                <Menu fixed="top" borderless>
                    <Menu.Item>
                        <a href='/'><Image size="tiny" src={logo}/></a>
                    </Menu.Item>
                    <Menu.Item style={{width: '150px'}}>
                        <p>Register of Road Safety Auditors </p>
                    </Menu.Item>
                    <Menu.Item onClick={onToggle} position={'right'}>
                        <Icon name="sidebar"/>
                    </Menu.Item>
                </Menu>
                {children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

interface NavBarDesktopProps {
    leftItems: any,
    rightItems: any,
    User: loginRequest,
    setUser: any
    handleReCaptchaVerify: any
    logout: any,
    session: any,
    loading: boolean,
    forgotPassword: any
    error: string,
    onKeyPress: any,
    auditor?: AuditorProfile
}


const NavBarDesktop = ({
                           leftItems,
                           rightItems,
                           User,
                           setUser,
                           handleReCaptchaVerify,
                           logout,
                           session,
                           loading,
                           forgotPassword,
                           error,
                           onKeyPress,
                           auditor
                       }: NavBarDesktopProps) => {

    const currentURL = window.location.pathname
    const [ProfileCount, setProfileCount] = useState(0);
    const [AccreditationsCount, setAccreditationsCount] = useState(0);


    useEffect(() => {
        let ProfileCount = 0;
        let AccreditationsCount = 0;
        if (auditor) {
            if (session != null && !(session.status == 4 || session.status == 3)) {
                if ((auditor.AD?.id && auditor.AD?.isConfirmedByAuditor == false) || (auditor.AD?.id2 && auditor.AD.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.BD?.id && auditor.BD?.isConfirmedByAuditor == false) || (auditor.BD?.id2 && auditor.BD.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.AVA?.id && auditor.AVA?.isConfirmedByAuditor == false) || (auditor.AVA?.id2 && auditor.AVA.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.QE?.id && auditor.QE?.isConfirmedByAuditor == false) || (auditor.QE?.id2 && auditor.QE.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.RSA?.id && auditor.RSA?.isConfirmedByAuditor == false) || (auditor.RSA?.id2 && auditor.RSA.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.RSC?.id && auditor.RSC?.isConfirmedByAuditor == false) || (auditor.RSC?.id2 && auditor.RSC.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
            } else {
                if ((auditor.AD?.id && auditor.AD?.isConfirmedByAuditor == false) || (auditor.AD?.id2 && auditor.AD.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.BD?.id && auditor.BD?.isConfirmedByAuditor == false) || (auditor.BD?.id2 && auditor.BD.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.AVA?.id && auditor.AVA?.isConfirmedByAuditor == false) || (auditor.AVA?.id2 && auditor.AVA.isConfirmedByAuditor2 == false)) {
                    ProfileCount += 1;
                }
                if ((auditor.QE?.id && auditor.QE?.isConfirmedByAuditor == false) || (auditor.QE?.id2 && auditor.QE.isConfirmedByAuditor2 == false)) {
                    AccreditationsCount += 1;
                }
                if ((auditor.RSA?.id && auditor.RSA?.isConfirmedByAuditor == false) || (auditor.RSA?.id2 && auditor.RSA.isConfirmedByAuditor2 == false)) {
                    AccreditationsCount += 1;
                }
                if ((auditor.RSC?.id && auditor.RSC?.isConfirmedByAuditor == false) || (auditor.RSC?.id2 && auditor.RSC.isConfirmedByAuditor2 == false)) {
                    AccreditationsCount += 1;
                }
            }
        }
        setProfileCount(ProfileCount);
        setAccreditationsCount(AccreditationsCount);
    }, [auditor]);

    const contextRef: React.LegacyRef<any> = createRef()

    return (
        <Container fluid={true} id={'topBar'} className="item-align-center topBar">
            <Grid>
                <Grid.Row columns={7} id={'white-top-bar'}>
                    <Grid.Column width={2}/>
                    <Grid.Column width={2}>
                        <a href='/' style={{display:'flex', justifyContent:'end'}}><Image src={logo} id={'logo'} alt={'logo'}/></a>
                    </Grid.Column>
                    <Grid.Column width={5} className={'top-title'}>
                        <p id={'logo-title'}>Register of Road Safety Auditors</p>
                    </Grid.Column>
                    {session == null ? (<Grid.Column id={'login-pnl1'} width={2}>
                            <>
                                <Grid.Row className={'login-label'}>
                                    <h3 style={{fontSize: '12px'}}>&nbsp;</h3>
                                </Grid.Row>
                                <Grid.Row className={'login-label'}>
                                    <TextBoxes id={'txtUname'} type={'text'} fluid={true} placeholder={'Username'}
                                               value={User.email} onChange={setUser} onError={error}/>
                                </Grid.Row>
                                <Grid.Row className={'login-label'}>
                                    <span style={{fontSize: '17px', textAlign: 'right', color: 'red'}}>{error}</span>
                                </Grid.Row>
                            </>
                        </Grid.Column>) : null}
                        <Grid.Column id={'login-pnl2'} width={session == null ? 2 : 4}>
                            {session == null ? (<><Grid.Row className={'login-label'}><h4
                                    style={{fontSize: '12px'}}>&nbsp;</h4></Grid.Row>
                                    <Grid.Row className={'login-label'}>
                                        <TextBoxes id={'txtPword'} type={'password'} fluid={true} placeholder={'Password'}
                                                   value={User.password} onChange={setUser} onKeydown={onKeyPress}/>
                                    </Grid.Row>
                                    <Grid.Row className={'login-label'} style={{fontSize: '17px', textAlign: 'right', marginTop:'10px'}}>
                                    <span style={{fontSize: '17px', textAlign: 'right', cursor: 'pointer'}}
                                          onClick={forgotPassword}>Forgot Password?</span>
                                    </Grid.Row>
                                </>) :
                                (<>
                                    <Grid.Row className={'login-label'}><h2 style={{fontSize: '16px'}}>Login as :</h2>
                                    </Grid.Row>
                                    <Grid.Row className={'login-label'} id={'userName'} >
                                        <h3 className='gray'>{session.userName}</h3>
                                    </Grid.Row>
                                    <Grid.Row className={'login-label'}>
                                    <span
                                        style={{fontSize: '18px', textAlign: 'right', cursor: 'pointer'}}>&nbsp;</span>
                                    </Grid.Row>
                                </>)}
                        </Grid.Column>
                        <Grid.Column id={'login-pnl3'} width={2}>
                            <Grid.Row className={'login-label'}><h2 style={{fontSize: '12px'}}>&nbsp;</h2></Grid.Row>
                                {session == null ? (<Grid.Row className={'login-label button-flex'}> <Buttons id={'btnLogin'} onClick={async () => {
                                        handleReCaptchaVerify();
                                    }} color='red' text={'Login'} disabled={loading} style={{width: '150px'}}
                                                             className={"background-transaparent-red"}/>  </Grid.Row>) :
                                    (<Grid.Row className={'button-flex'}><Buttons id={'btnLogout'} onClick={logout} color='red' text={'Logout'}
                                              style={{width: '150px'}} className={"background-transaparent-red"}/> </Grid.Row>)}

                        </Grid.Column>
                        <Grid.Column id={'login-pnl4'} width={1}/>
                </Grid.Row>

                <Grid.Row color={'blue'} columns={3} innerref={contextRef} id={'blue-menu'}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Sticky ref={contextRef}>
                            <Menu color={'blue'} inverted widths={5} >
                                {leftItems.map((item: any, index: number) => (
                                    <Menu.Item style={{fontSize: '18px'}} key={index} id={index} as={item.as}
                                               href={item.href} active={currentURL == item.href}
                                               className='item-width'>
                                        {item.content}
                                        {item.content == "Profile" && ProfileCount > 0 ? (<Label color='red' floating>
                                            {ProfileCount}
                                        </Label>) : item.content == "Accreditation" && AccreditationsCount > 0 ? (
                                            <Label color='red' floating>
                                                {AccreditationsCount}
                                            </Label>) : null}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </Sticky>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

            </Grid>
        </Container>
    );
};

const NavBarChildren = ({children}: any) => (
    <Container fluid={true} style={{marginTop: "5em"}}>{children}</Container>
);

interface NavBarProps {
    children?: any,
    leftItems: any,
    rightItems: any,
    auditor?: AuditorProfile,
}

const NavBar = ({children, leftItems, rightItems, auditor}: NavBarProps) => {
    const [session, setSession] = useState(getSessionCookie());
    const [visible, setVisible] = useState(false)
    const [leftMenu, setLeftMenu] = useState(leftItems(null))
    const [loading, setLoading] = useState(false)
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const [error, setError] = useState('')

    //Google Recaptcha
    const reCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('Login');
        console.log(token); // Will print the token
        sessionStorage.setItem('token', token);
    }, [executeRecaptcha]);

    const [User, setUser] = useState<loginRequest>({email: '', password: ''})

    const handlePusher = () => {
        if (visible) setVisible(false);
    };

    const handleToggle = () => setVisible(!visible);

    const handleReCaptchaVerify = async () => {
        sessionStorage.clear();
        // await reCaptchaVerify();
        setLoading(true);
        //const verified = await Recaptcha();
        //if (verified) {
        const result = await SignIn(User)
        Promise.resolve(result).then(async (value) => {
            //eslint-disable-next-line
            //debugger
            if (result.user == undefined) {
                setLoading(false);
                ErrorToast(result.response.data.error.detail);
            } else {
                setLoading(false);
                if(result.user.userRoleId == 3) {
                    const Auditor = await LoadAuditor(result)
                    Promise.resolve(Auditor).then((x) => {
                        //eslint-disable-next-line
                        //debugger
                        //console.log('x',x)
                        setSessionCookie({...result.user, ...Auditor})
                        setSession(getSessionCookie());
                        setLeftMenu(leftItems(session))
                        if (x.status == 3 || x.status == 4 || x.status == 1) {
                            window.location.href = '/welcome';
                        } else {
                            window.location.href = '/profile';
                        }
                    })
                }else if(result.user.userRoleId == 1 || result.user.userRoleId == 4){
                    setSessionCookie({...result.user});
                    setSession(getSessionCookie());
                    window.location.href = '/dashboard';
                }
            }
        })

        // } else {
        //     alert('not verified');
        // }
    }
    const logout = async () => {
        removeSessionCookie()
        sessionStorage.clear();
        setSession(getSessionCookie());
        setLeftMenu(leftItems(session))
        navigate('/home');
        //SignOut()
    }

    const forgotPassword = async () => {
        setError('')
        if (User.email == undefined || User.email == '') {
            setError('Please enter your login email !')
            return;
        }
        const result = await ForgotPassword(User)

        if (result.user == undefined) {
            setLoading(false);
            ErrorToast(result.response.data.error.detail);
        } else {
            setLoading(false);
            SuccessToast(result.user.msg);
        }
    }

    const onChange = (e: any) => {
        if (e.target.id == 'txtUname') {
            setUser({...User, email: e.target.value,})
        }
        if (e.target.id == 'txtPword') {
            setUser({...User, password: e.target.value,})
        }
    }

    const onKeyPress = (e: any) => {
        if (e.key == 'Enter') {
            handleReCaptchaVerify();
        }
    }

    return (
        <div>
            <ToastContainer
                hideProgressBar={true}
            />
            <Media at="mobile">
                <NavBarMobile
                    leftItems={leftMenu}
                    onPusherClick={handlePusher}
                    onToggle={handleToggle}
                    rightItems={rightItems}
                    visible={visible}
                    User={User}
                    setUser={onChange}
                    handleReCaptchaVerify={handleReCaptchaVerify}
                    logout={logout}
                    session={session}
                    error={error}
                    forgotPassword={forgotPassword}
                    onKeyPress={onKeyPress}
                >
                    <NavBarChildren>{children}</NavBarChildren>
                </NavBarMobile>
            </Media>

            <Media at="tablet">
                <NavBarMobile
                    leftItems={leftMenu}
                    onPusherClick={handlePusher}
                    onToggle={handleToggle}
                    rightItems={rightItems}
                    visible={visible}
                    User={User}
                    setUser={onChange}
                    handleReCaptchaVerify={handleReCaptchaVerify}
                    logout={logout}
                    session={session}
                    error={error}
                    forgotPassword={forgotPassword}
                    onKeyPress={onKeyPress}
                >
                    <NavBarChildren>{children}</NavBarChildren>
                </NavBarMobile>
            </Media>

            <Media greaterThan="tablet">
                <NavBarDesktop
                    leftItems={leftMenu}
                    rightItems={rightItems}
                    User={User}
                    setUser={onChange}
                    handleReCaptchaVerify={handleReCaptchaVerify}
                    logout={logout} session={session}
                    loading={loading}
                    error={error}
                    forgotPassword={forgotPassword}
                    onKeyPress={onKeyPress}
                    auditor={auditor}
                />
                {children}
            </Media>
        </div>
    );
}

const rightItems = [
    {as: "a", content: "Login", key: "login"},
    {as: "a", content: "Register", key: "register"}
];

interface HeaderProps {
    children?: any
}

const Header = ({children}: HeaderProps) => {
    //const [User, setUser] = useState('public')
    const session = getSessionCookie();
    const menuListSelection = () => {

        //console.log('menu selection', session)
        if (session != null) {
            switch (session.userRoleId.toString()) {
                case '3':
                    //console.log('menu selection', session.userRoleId)
                    return session.status == 3 || session.status == 4 ? auditorActiveMenu : auditorMenu;
                    break;
                case '1':
                    return adminMenu;
                    break;
                case '2':
                    return assessorMenu;
                    break;
                case '5':
                    return assessorChairMenu;
                    break;
                case '4':
                    return superAdminMenu;
                    break;
                default:
                    return publicMenu;
                    break;
            }
        } else {
            return publicMenu;
        }
    }
    const [auditor, SetAuditor] = useState<AuditorProfile>()

    useEffect(() => {
        if (session != null && session.userRoleId == 3) {
            const auditorPromis = fetchAuditor(session.auditorId, 0)
            Promise.resolve(auditorPromis).then(async (x) => {
                SetAuditor(x);
            })
        }


    }, [])

    return (
        <>
            <style>{mediaStyles}</style>

            <MediaContextProvider>
                <NavBar leftItems={menuListSelection} rightItems={rightItems} auditor={auditor}>
                    {children}
                </NavBar>
            </MediaContextProvider>
        </>
    )
}

export default Header