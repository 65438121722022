import ApiCallAxio from "../../framework/common/apiCallAxio";
import APICallMethods from "../../framework/types/APICallMethods";
import Audits from "../../models/apiResponce/Audits/Audits";
import moment from "moment";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const fetchAudits = async (signOffDate?: string, signOffDate2?: string, stageID?: number, LocationStateID?: number) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAuditsWithPagination`; //complete url
    const data = JSON.stringify({
        countryStateId: LocationStateID ? LocationStateID : 0,
        auditFinalSignoffDate: signOffDate ? signOffDate == "Invalid date" ? null : signOffDate : null,
        auditFinalSignoffDate2: signOffDate2 ? signOffDate2 == "Invalid date" ? null : signOffDate2 : null,
        stageId: stageID ? stageID : 0,
        pageSize: 10,
        pageNumStartingZero: 0,
        orderByFieldsIncAscOrDesc: ""
    })
    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    const objTotalItem: number = result.auditRess.totalItemsCount; //store the result in userTitles array
    const objTotalPage: number = result.auditRess.totalPages;
    const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes",
            finalSignoffDate:moment(item.auditFinalSignoffDate).format("DD-MM-YYYY")
        })
    })
    return auditsArray;//return the array
}

const fetchAllActiveAudits = async (type:string, pageNumber:number) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAllAudits?type=${type}&currentPage=${pageNumber}`; //complete url

    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    const objTotalItem: number = result.auditRess.totalItemsCount; //store the result in userTitles array
    const objTotalPage: number = result.auditRess.totalPages;
    const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes",
            finalSignoffDate:moment(item.auditFinalSignoffDate).format("DD-MM-YYYY")
        })
    })
    return {auditsArray,objTotalItem,objTotalPage,objCurrentPage};//return the array
}

export {fetchAudits,fetchAllActiveAudits}; //export the function