import React from "react";
import {Button, Grid} from "semantic-ui-react";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import {HashLink} from "react-router-hash-link";
import {QualificationValueProps} from "../profile/DataModal";
import Buttons from "../../../framework/components/Buttons";

interface Props{
    QandE?:QualificationValueProps
}
const QESummary = ({QandE}:Props) => {

    const EligibilityCriteria = (id:string) => {
        switch (id) {
            case '1' :
                return "0-4";
                break;
            case '2' :
                return "5-6";
                break;
            case '3' :
                return "7+ ";
                break;
            default:
                return "-";
                break;
        }
    }

    return(
        <React.Fragment>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column largeScreen={10} widescreen={12} computer={10} tablet={10} mobile={16}>
                        <h3 style={{display: 'inline-flex', color:"#1559A3"}}>Qualifications & Experience Summary</h3>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16}>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <Button basic outline={1} style={{width:'150px'}} content='blue' id={'cmbmyQaE'} color='blue' className={"background-transaparent"}><HashLink  to={"/accriditation#qande"}>My Q & E</HashLink></Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={16}>
                        <p style={{fontWeight:'bolder', fontSize:'18px'}}>Total years of experience</p>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={16}>
                        <p style={{fontWeight:'bolder', fontSize:'18px'}}>{`${EligibilityCriteria(QandE?.eligibilityCriteria ? QandE?.eligibilityCriteria : "" )} (${QandE?.totalExperience} years)`}</p>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={16}>
                        <p style={{fontWeight:'bolder', fontSize:'18px'}}>Specific Expertise</p>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    {QandE?.constructionProject? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Construction Projects</p>
                    </Grid.Column>) : null }
                    {QandE?.reconstructionProjects? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Reconstruction Projects</p>
                    </Grid.Column>) : null }
                    {QandE?.intersectionProjects? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Intersection Projects</p>
                    </Grid.Column>): null }
                    {QandE?.localAreaTrafficManagementProjects? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Local Area Traffic Management Projects</p>
                    </Grid.Column>): null }
                    {QandE?.trafficSignalProjects? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Traffic Signal Projects</p>
                    </Grid.Column>): null }
                    {QandE?.temporaryTrafficManagement? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Temporary Traffic Management</p>
                    </Grid.Column>): null }
                    {QandE?.developmentPlanning? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Development Planning</p>
                    </Grid.Column>): null }
                    {QandE?.designConstructionProjects? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Design & Construction Projects</p>
                    </Grid.Column>): null }
                    {QandE?.crashInvestigation? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Crash Investigation and Analyses</p>
                    </Grid.Column>): null }
                    {QandE?.vulnerableRoadUser? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                        <p>Vulnerable Road User (Pedestrians, cyclists, motorcyclists)</p>
                    </Grid.Column>): null }
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}

export default QESummary